class CTab {
  constructor(list, isTab, callback, option = {}) {
    this.list = list;
    this.isTab = isTab;
    this.callback = callback;
    this.ratio = option?.ratio ? option.ratio : 3.35;
    this.width = option?.width ? option.width : 100;
    this._style = {
      width: this.width * this.ratio,
      height: 120,
      leftWidth: 40,
      topHeight: 40,
      rightWidth: 40,
      bottomHeight: 40,
    };

    this.tabListDOM = this.createTabListDOM();
    this.updateTabStyles();
  }

  tabItemNormal(idx, arr) {
    return _v.m_spriteFrame(_v.m_getRes("base").textures[`tab-${idx == 0 ? "left" : idx == arr.length - 1 ? "right" : "center"}-normal-bg.png`], this._style);
  }

  tabItemSelect(idx, arr, type) {
    return _v.m_getRes("base").textures[`tab-${idx == 0 ? "left" : idx == arr.length - 1 ? "right" : "center"}-${type}-bg.png`];
  }

  createTabListDOM() {
    return _v.m_popDom({
      content: this.list.map((item, index) => {
        let cur = {};
        cur[`tab_item_${index}`] = _v.m_btn(
          {
            content: {
              tab_item_bg: {
                content: this.tabItemNormal(index, this.list),
                styles: {
                  width: this.width,
                  scale: 0.3,
                  height: 40,
                },
              },
              tab_item_text: {
                content: item.label,
                styles: {
                  marginTop: -3,
                  marginLeft: 6,
                  anchorX: 0.5,
                  position: "center",
                  fill: "#fff",
                  fontSize: 14,
                  fontFamily: "pFont",
                  stroke: "#11131e",
                  strokeThickness: 2,
                  lineJoin: "round",
                  miterLimit: 100,
                  dropShadow: true,
                  dropShadowAngle: 90,
                  dropShadowDistance: 1,
                },
              },
            },
            styles: { item },
          },
          () => {
            this.setTab(item.value);
          },
          {
            gasp: true,
            result: 0.9,
            init: 1,
          }
        );
        return cur;
      }),
      styles: {
        position: "center",
      },
    });
  }

  updateTabStyles() {
    let num = 0;
    this.tabListDOM?.content?.children.forEach((itm, key) => {
      const otherItemBg = itm?.content?.getByID("tab_item_bg").children[0];
      if (num === this.isTab) {
        otherItemBg.texture = this.tabItemSelect(this.isTab, this.list, "select");
      } else {
        otherItemBg.texture = this.tabItemSelect(num, this.list, "normal");
      }
      num++;
    });
  }

  setTab(newTab) {
    this.isTab = newTab;
    this.updateTabStyles();
    const selectedItem = this.list.find((item) => item.value === newTab);
    if (selectedItem) {
      this.callback(selectedItem);
    }
  }
}

export default CTab