var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-select', {
    attrs: {
      "show": _vm.popSelectShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSelectShow = $event;
      },
      "select": _vm.onSelect
    }
  }), _c('pop-prompt', {
    attrs: {
      "domName": "popFailDom",
      "isBtnClose": false,
      "btnType": "check",
      "btnText": "Back",
      "parentDomName": "pageDom",
      "show": _vm.popFailOption.show,
      "content": _vm.popFailOption.content
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.popFailOption, "show", $event);
      },
      "submit": _vm.popFailSubmit
    }
  }), _c('pop-prompt', {
    attrs: {
      "content": "Confirm Upgrade star?",
      "domName": "popStartConfirmDom",
      "show": _vm.popStartConfirmShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popStartConfirmShow = $event;
      },
      "submit": _vm.popStartConfirm
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };