var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-recruit-miner', {
    attrs: {
      "show": _vm.popRecruitMinerShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRecruitMinerShow = $event;
      }
    }
  }), _c('assets-info-r', {
    ref: "assetsInfo1"
  }), _c('pop-recruit-info', {
    attrs: {
      "show": _vm.popRecruitInfoShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRecruitInfoShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };