import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
const popWidth = _v.screenWidth * 0.9;
const popHeight = 580;
import bignumber from "bignumber.js";
const {
  progressBar,
  progressText
} = _v.c_progress({
  width: _v.screenWidth - 120,
  height: 20,
  radio: 60,
  progress: 100
});
// `${this.toPre(bignumber(100).minus(bignumber(this.gmeMintInfo.gme).div(this.gmeMintInfo.total_gme).times(100)), 2)}%`,
const pop_gme_progress_main = progressBar;
const pop_gme_progress_main_text = progressText;
export default {
  name: "popGemMint",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["gameInfo", "accountInfo"]),
    isRender() {
      return this.$parent.popGemMintShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          this.initPop();
          this.gmeMintInfo = await this.getGmeMintInfo();
          this.setProgress();
          this.loadGemMintInfo();
          this.setBtnDom();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      gmeMintInfo: null,
      isMintLoading: null
    };
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["getGmeMintInfo", "gmeMintFunc"]),
    initPop() {
      if (!this.$parent.popGemMintDom) {
        this.$parent.popGemMintDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popGemMintDom);
      }
      this.$parent.popGemMintDom.visible = true;
      this.$parent.popGemMintDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popGemMintDom.position.set(0, 0);
      this.setZindex();
      this.anim_pop(this.$parent.popGemMintDom, null, {
        y: _v.screenHeight / 2 - popHeight / 2
      });
    },
    setProgress() {
      pop_gme_progress_main.progress = Number(bignumber(100).minus(bignumber(this.gmeMintInfo.gme).div(this.gmeMintInfo.total_gme).times(100)));
      pop_gme_progress_main_text.text = `${this.toPre(bignumber(100).minus(bignumber(this.gmeMintInfo.gme).div(this.gmeMintInfo.total_gme).times(100)), 2)}%`;
    },
    setZindex() {
      const popDom = this.$parent.popGemMintDom;
      const pop_main = popDom.getChildByID("pop_main");
      const pop_gme_head = popDom.getChildByID("pop_gme_head");
      pop_main.sortableChildren = true;
      pop_gme_head.zIndex = 2;
    },
    loadGemMintInfo() {
      const popDom = this.$parent.popGemMintDom.content;
      if (popDom) {
        const pop_gme_remaining_num = popDom.getByID("pop_gme_remaining_num");
        pop_gme_remaining_num.children[0].text = `${this.toPre(bignumber(this.gmeMintInfo.total_gme).minus(this.gmeMintInfo.gme), 0)}`;
        const pop_gme_progress_info = popDom.getByID("pop_gme_progress_info");
        pop_gme_progress_info.children[0].text = `(Total Reward Pool ${this.toPre(this.gmeMintInfo.total_gme, 0)} GME)`;
        const pop_gme_mint_info_label = popDom.getByID("pop_gme_mint_info_label");
        pop_gme_mint_info_label.children[0].text = `Current Level ${this.gmeMintInfo.gme_level}`;
        const pop_gme_mint_info_item_con_num_reward = popDom.getByID("pop_gme_mint_info_item_con_num_reward");
        pop_gme_mint_info_item_con_num_reward.children[0].text = `${this.toPre(this.gmeMintInfo.gme_reward, 0)}`;
        const pop_gme_mint_info_item_con_num_gme_fee = popDom.getByID("pop_gme_mint_info_item_con_num_gme_fee");
        pop_gme_mint_info_item_con_num_gme_fee.children[0].text = `${this.toPre(this.gmeMintInfo.gme_fee, 0)}`;
        const pop_gme_mint_info_item_mainc_con_tap_amount_1 = popDom.getByID("pop_gme_mint_info_item_mainc_con_tap_amount_1");
        pop_gme_mint_info_item_mainc_con_tap_amount_1.children[0].text = `${this.toPre(this.accountInfo.tap_amount, 0)}/tap`;
        const pop_gme_mint_info_item_mainc_con_tap_amount_2 = popDom.getByID("pop_gme_mint_info_item_mainc_con_tap_amount_2");
        pop_gme_mint_info_item_mainc_con_tap_amount_2.children[0].text = `${this.toPre(this.accountInfo.next_tap_amount, 0)}/tap`;
        const pop_gme_box = popDom.getByID("pop_gme_box")?.layout;
        pop_gme_box?.updateParents;
      }
    },
    renderDom() {
      return {
        content: {
          pop_gme: {
            content: {
              pop_gme_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_gme_head: {
                    content: {
                      pop_gme_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_gme_title: {
                        content: {
                          pop_gme_title_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                          },
                          pop_gme_title_text: {
                            content: "ACCOUNT LEVEL"
                          }
                        }
                      },
                      pop_gme_close: {
                        content: this.c_btnClose(() => {
                          console.log("关闭");
                          this.$parent.popGemMintDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_gme_con: {
                    content: {
                      pop_gme_con_bg: {
                        content: this.sf_marketGoods({
                          width: this.screenWidth * 4 - 140,
                          height: popHeight * 4
                        })
                      },
                      pop_gme_box: {
                        content: {
                          pop_gme_remaining: {
                            content: {
                              pop_gme_remaining_label: {
                                content: "Remaining rewards(GME)"
                              },
                              pop_gme_remaining_num: {
                                content: "0"
                              }
                            }
                          },
                          pop_gme_progress: {
                            content: {
                              pop_gme_progress_main: {
                                content: pop_gme_progress_main
                              },
                              pop_gme_progress_info: {
                                content: `(Total Reward Pool 0 GME)`
                              }
                            }
                          },
                          pop_gme_mint_info: {
                            content: {
                              pop_gme_mint_info_label: {
                                content: `Current Level 1`
                              },
                              pop_gme_mint_info_item_1: {
                                content: {
                                  pop_gme_mint_info_item_label: {
                                    content: "Upgrade Cost"
                                  },
                                  pop_gme_mint_info_item_con: {
                                    content: {
                                      pop_gme_mint_info_item_con_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-coin.png"])
                                      },
                                      pop_gme_mint_info_item_con_num_gme_fee: {
                                        content: "0",
                                        styles: globalStyles.pop_gme_mint_info_item_con_num
                                      }
                                    }
                                  }
                                },
                                styles: globalStyles.pop_gme_mint_info_item
                              },
                              pop_gme_mint_info_item_2: {
                                content: {
                                  pop_gme_mint_info_item_label: {
                                    content: "Upgrade Reward"
                                  },
                                  pop_gme_mint_info_item_con: {
                                    content: {
                                      pop_gme_mint_info_item_con_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-gem.png"])
                                      },
                                      pop_gme_mint_info_item_con_num_reward: {
                                        content: "0",
                                        styles: globalStyles.pop_gme_mint_info_item_con_num
                                      }
                                    }
                                  }
                                },
                                styles: globalStyles.pop_gme_mint_info_item
                              },
                              pop_gme_mint_info_item_3: {
                                content: {
                                  pop_gme_mint_info_item_label: {
                                    content: "Upgrade tap rate"
                                  },
                                  pop_gme_mint_info_item_mainc: {
                                    content: {
                                      pop_gme_mint_info_item_mainc_con_tap_amount_1: {
                                        content: `0/tap`,
                                        styles: {
                                          ...globalStyles.pop_gme_mint_info_item_mainc_con,
                                          ...globalStyles.pop_gme_mint_info_item_mainc_con_1
                                        }
                                      },
                                      pop_gme_mint_info_item_mainc_con_arrow: {
                                        content: Sprite.from(this.m_getRes("element").textures["level-arrow.png"])
                                      },
                                      pop_gme_mint_info_item_mainc_con_tap_amount_2: {
                                        content: `0/tap`,
                                        styles: {
                                          ...globalStyles.pop_gme_mint_info_item_mainc_con,
                                          ...globalStyles.pop_gme_mint_info_item_mainc_con_2
                                        }
                                      }
                                    }
                                  }
                                },
                                styles: globalStyles.pop_gme_mint_info_item
                              }
                            }
                          },
                          btn_mint: this.m_btn({
                            content: {
                              btn_mint_box: {
                                content: {
                                  btn_mint_bg: {
                                    content: this.sf_btnBg({
                                      width: this.screenWidth * 4 - 400,
                                      height: 170
                                    }),
                                    styles: globalStyles.btn_mint_bg
                                  },
                                  btn_mint_main: {
                                    content: {
                                      btn_mint_text: {
                                        content: "UPGRADE",
                                        styles: globalStyles.btn_mint_text
                                      }
                                    },
                                    styles: globalStyles.btn_mint_main
                                  }
                                },
                                styles: globalStyles.btn_mint_box
                              },
                              btn_mint_load: {
                                content: this.c_btnLoading(),
                                styles: globalStyles.btn_mint_load
                              }
                            },
                            styles: globalStyles.btn_mint
                          }, () => {
                            this.gmeMint();
                          })
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setBtnLoading(bool) {
      this.c_setBtnLoading(this.$parent.popGemMintDom, "btn_mint_box", "btn_mint_load", bool);
    },
    setBtnDom() {
      if (this.gmeMintInfo.gme_level >= this.gameInfo.maxGMELevel) {
        const btn_mint_text = this.$parent.popGemMintDom.content.getByID("btn_mint_text");
        btn_mint_text.children[0].text = "Maximum level";
      }
    },
    async gmeMint() {
      if (this.gmeMintInfo.gme_level >= this.gameInfo.maxGMELevel) {
        this.$toasted.clear();
        return this.$toasted.error(this.$lang("Maximum level"));
      }
      this.setBtnLoading(true);
      const data = await this.gmeMintFunc();
      if (!data) {
        return this.setBtnLoading(false);
      }
      const info = await this.getGmeMintInfo();
      if (!info) {
        return this.setBtnLoading(false);
      }
      this.gmeMintInfo = info;
      await this.getAccountInfo(data);
      console.log("this.$parent", this.$parent.$parent);
      this.loadGemMintInfo();
      this.setBtnDom();
      this.setProgress();
      this.$parent.renderDom({
        type: "refresh",
        coin: true,
        gme: true
      });
      this.setBtnLoading(false);
    }
  }
};
const globalStyles = {
  pop_gme: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "left"
  },
  pop_gme_mask: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_main: {
    position: "center",
    width: popWidth,
    height: popHeight
    // overflow: "hidden",
  },

  pop_gme_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50
  },
  pop_gme_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_gme_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_gme_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_gme_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_gme_close: {
    position: "rightTop"
  },
  pop_gme_con: {
    position: "centerTop",
    width: popWidth,
    height: popHeight
    // overflow: "hidden",
  },

  pop_gme_con_bg: {
    position: "centerTop",
    scale: 0.25,
    height: popHeight
  },
  pop_gme_box: {
    position: "centerTop",
    width: "100%",
    height: popHeight - 65,
    overflow: "hidden",
    marginTop: 65
  },
  pop_gme_remaining: {
    position: "centerTop"
  },
  pop_gme_remaining_label: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontFamily: "pFont",
    color: "#fff",
    height: 20
  },
  pop_gme_remaining_num: {
    marginTop: -8,
    width: "100%",
    height: 40,
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 32,
      ts: 6,
      ds: 2
    })
  },
  pop_gme_progress: {
    marginTop: 70,
    width: "100%"
  },
  pop_gme_progress_main: {
    height: 30,
    position: "centerTop"
  },
  pop_gme_progress_info: {
    marginTop: 25,
    position: "centerTop",
    fontSize: 13,
    height: 25,
    width: "100%",
    textAlign: "center",
    color: "#aeb1b7",
    fontFamily: "pFontM"
  },
  pop_gme_mint_info: {
    position: "centerTop",
    marginTop: 130,
    height: 305,
    width: _v.screenWidth - 90,
    overflow: "hidden",
    background: "#27303e",
    borderRadius: 12
  },
  pop_gme_mint_info_label: {
    paddingTop: 15,
    width: "100%",
    fontSize: 14,
    color: "#fff",
    fontFamily: "pFontM",
    textAlign: "center",
    marginBottom: -60
  },
  pop_gme_mint_info_item_1: {
    marginTop: -15
  },
  pop_gme_mint_info_item_2: {
    marginTop: -5
  },
  pop_gme_mint_info_item: {
    width: "100%",
    height: 80
  },
  pop_gme_mint_info_item_mainc_con: {
    fontSize: 14,
    color: "#fff",
    fontFamily: "pFont",
    width: 120,
    height: 40,
    textAlign: "center",
    color: "#7f83a5"
  },
  pop_gme_mint_info_item_mainc_con_1: {
    position: "left"
  },
  pop_gme_mint_info_item_mainc_con_2: {
    color: "#fff",
    position: "right"
  },
  pop_gme_mint_info_item_mainc_con_arrow: {
    scale: 0.25,
    width: 150,
    height: 90,
    position: "center"
  },
  pop_gme_mint_info_item_mainc: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: -6,
    width: "90%",
    height: 40,
    borderRadius: 6,
    background: "#364254"
  },
  pop_gme_mint_info_item_label: {
    width: "90%",
    marginLeft: 15,
    color: "#fff",
    fontSize: 14,
    height: 16,
    fontFamily: "pFontM"
  },
  pop_gme_mint_info_item_con: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: -6,
    width: "90%",
    height: 40,
    borderRadius: 6,
    background: "#364254"
  },
  pop_gme_mint_info_item_con_icon: {
    scale: 0.25,
    position: "leftCenter",
    marginLeft: 5
  },
  pop_gme_mint_info_item_con_num: {
    position: "rightCenter",
    fontSize: 16,
    color: "#fff",
    fontFamily: "pFont",
    marginRight: 10,
    textAlign: "right",
    width: "200"
  },
  btn_mint: {
    position: "centerBottom",
    width: _v.screenWidth - 50,
    marginBottom: 30,
    height: 40
  },
  btn_mint_box: {
    width: "100%",
    height: "100%",
    position: "centerTop"
  },
  btn_mint_bg: {
    position: "center",
    scale: 0.25
  },
  btn_mint_main: {
    position: "center",
    width: "100%"
  },
  btn_mint_load: {
    position: "center",
    marginLeft: 12,
    marginTop: 15,
    visible: false
  },
  btn_mint_text: {
    marginTop: -2,
    position: "center",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 16
    })
  }
};