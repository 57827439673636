import { Sprite } from "pixi.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popBindWallet",
  props: {
    title: {
      typeof: String,
      default: "Hint"
    },
    btnText: {
      typeof: String,
      default: "Rebind"
    },
    show: Boolean,
    btnType: {
      typeof: String,
      default: "confirm"
    },
    parentDomName: {
      typeof: String,
      default: "pageDom"
    },
    isBtnClose: {
      typeof: Boolean,
      default: true
    }
  },
  computed: {
    ...userVuex.mapState(["accountInfo"])
  },
  watch: {
    show: {
      handler(bool) {
        if (bool) {
          this.initPop();
          this.setBtnText();
        } else {
          clearTimeout(this.btnLoadingTimer);
          this.btnLoadingTimer = false;
        }
      },
      deep: true,
      immediate: true
    },
    btnType: {
      handler(newVal, oldVal) {
        console.log("newVal=====", newVal, oldVal);
        if (newVal != oldVal) {
          this.setBtnText();
        }
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.btnLoadingTimer);
    this.btnLoadingTimer = false;
  },
  data() {
    return {
      popDom: null,
      isBtnLoading: false,
      isBinding: false
    };
  },
  methods: {
    ...gameVuex.mapActions(["bindWallet"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    setBtnText() {
      const btnTextDom = this.popDom?.content?.getByID("pop_prompt_btns_text")?.layout;
      if (!btnTextDom) return;
      const btnTextChildren = btnTextDom.content.children;
      btnTextDom.addContent(`${this.btnText}`);
      btnTextDom.removeChildByID(btnTextChildren.entries().next().value[0]);
    },
    initPop() {
      if (!this.$parent.popBindWalletDom) {
        this.$parent.popBindWalletDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popBindWalletDom);
      }
      this.$parent.popBindWalletDom.visible = true;
      this.$parent.popBindWalletDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popBindWalletDom.position.set(0, 0);
      this.anim_pop(this.$parent.popBindWalletDom);
    },
    setBtnLoading(bool) {
      const popDom = this.$parent.popBindWalletDom;
      const btnDom = popDom.getChildByID("pop_prompt_box_btn");
      const loadingDom = popDom.getChildByID("pop_prompt_loading");
      if (bool) {
        btnDom.visible = false;
        loadingDom.visible = true;
      } else {
        btnDom.visible = true;
        loadingDom.visible = false;
      }
    },
    renderDom() {
      return {
        content: {
          pop_sell: {
            content: {
              pop_prompt_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_prompt_con: {
                    content: {
                      pop_prompt_main_bg: {
                        content: this.sf_popBg({
                          width: this.screenWidth * 4 - 300,
                          height: 250 * 4
                        })
                      },
                      pop_prompt_box: {
                        content: {
                          pop_prompt_main_title: {
                            content: `${this.title}`
                          },
                          pop_prompt_main_content: {
                            content: {
                              pop_prompt_main_wallet: {
                                content: {
                                  pop_prompt_main_wallet_bg: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: _v.screenWidth * 2 + 250,
                                      height: 130
                                    })
                                  },
                                  pop_prompt_main_text_wallet: {
                                    content: `Wallet: ${this.fmt_address(this.tonWallet.uiAddress, 20)}`,
                                    styles: globalStyles.pop_prompt_main_text
                                  }
                                }
                              },
                              pop_prompt_main_arrow: {
                                content: Sprite.from(this.m_getRes("element").textures["icon-bind-arrow.png"])
                              },
                              pop_prompt_main_bind: {
                                content: {
                                  pop_prompt_main_wallet_bg: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: _v.screenWidth * 2 + 250,
                                      height: 130
                                    })
                                  },
                                  pop_prompt_main_text_bind: {
                                    content: {
                                      pop_prompt_main_text_bind_main: {
                                        content: `Bind: ${this.fmt_address(this.tonWallet.tranAddress(this.accountInfo.wallet), 20)}`,
                                        styles: globalStyles.pop_prompt_main_text_bind
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      pop_prompt_btns: this.m_btn({
                        id: "pop_prompt_btns",
                        content: {
                          pop_prompt_btns_main: {
                            content: {
                              pop_prompt_box_btn: {
                                content: {
                                  pop_prompt_btns_bg: {
                                    content: this.sf_btnBg({
                                      width: this.screenWidth * 4 - 540,
                                      height: 170
                                    }),
                                    styles: globalStyles.pop_prompt_btns_bg
                                  },
                                  pop_prompt_btns_text: {
                                    content: `${this.btnText}`,
                                    styles: globalStyles.pop_prompt_btns_text
                                  }
                                },
                                styles: globalStyles.pop_prompt_box_btn
                              },
                              pop_prompt_loading: {
                                content: this.c_btnLoading(),
                                styles: globalStyles.pop_prompt_loading
                              }
                            }
                          }
                        },
                        styles: globalStyles.pop_prompt_btns
                      }, async () => {
                        this.setBtnLoading(true);
                        console.log(3333);
                        this.btnLoadingTimer = setTimeout(async () => {
                          clearTimeout(this.btnLoadingTimer);
                          await this.bindWalletFn();
                          // await this.$emit("submit");
                          this.setBtnLoading(false);
                        }, 1500);
                      }),
                      pop_prompt_info: {
                        content: "Rebind successful"
                      },
                      pop_prompt_close: this.isBtnClose && {
                        content: this.c_btnClose(async () => {
                          this.$parent.popBindWalletDom.visible = false;
                          this.$emit("update:show", false);
                          await this.$emit("close");
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    closeFn() {
      this.popDom.destroy();
      this.$emit("update:show", false);
    },
    setWalletDom() {
      const popDom = this.$parent.popBindWalletDom.content;
      const pop_prompt_main_text_bind_main = popDom.getByID("pop_prompt_main_text_bind_main");
      pop_prompt_main_text_bind_main.children[0].text = `Bind: ${this.fmt_address(this.tonWallet.tranAddress(this.accountInfo.wallet), 20)}`;
      pop_prompt_main_text_bind_main.children[0].style.fill = "#fff";
      const pop_prompt_btns = popDom.getByID("pop_prompt_btns");
      const pop_prompt_info = popDom.getByID("pop_prompt_info");
      pop_prompt_btns.visible = false;
      pop_prompt_info.visible = true;
    },
    async bindWalletFn() {
      this.isBinding = false;
      await this.bindWallet();
      await this.getAccountInfo();
      this.setWalletDom();
      this.$parent.setWalletDom();
      this.isBinding = true;
    }
  }
};
const globalStyles = {
  pop_task: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_prompt_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_prompt_con: {
    width: _v.screenWidth - 50,
    height: 252,
    position: "center"
  },
  pop_prompt_main_bg: {
    position: "center",
    scale: 0.25
  },
  pop_prompt_box: {
    width: "100%",
    height: "100%",
    padding: 25
  },
  pop_prompt_main_title: {
    position: "centerTop",
    marginTop: 25,
    ..._v.fs_fontStyle({
      fz: 21,
      st: 2,
      ds: 1
    })
  },
  pop_prompt_main_content: {
    marginTop: -10,
    position: "center",
    width: "80%",
    textAlign: "center",
    height: 98,
    overflow: "hidden",
    fontSize: 18,
    fontFamily: "pFont",
    wordWrap: true,
    // wordWrapWidth: 500,
    whiteSpace: "pre-line",
    fill: "#fff",
    align: "center"
  },
  pop_prompt_main_wallet: {
    marginTop: 2,
    width: "100%",
    position: "centerTop",
    height: 30
  },
  pop_prompt_main_bind: {
    width: "100%",
    position: "centerBottom",
    height: 30
  },
  pop_prompt_main_wallet_bg: {
    position: "center",
    scale: 0.25
  },
  pop_prompt_main_text: {
    position: "center",
    fontSize: 14,
    width: "100%",
    fontFamily: "pFont",
    height: 30,
    display: "line",
    textAlign: "center",
    color: "#fff"
  },
  pop_prompt_main_text_bind: {
    position: "center",
    fontSize: 14,
    width: "100%",
    fontFamily: "pFont",
    height: 30,
    display: "line",
    textAlign: "center",
    color: "#f53f3f",
    padding: 0
  },
  pop_prompt_main_arrow: {
    marginTop: -2,
    scale: 0.5,
    height: 40,
    position: "center"
  },
  pop_prompt_btns: {
    position: "centerBottom",
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginBottom: 20
  },
  pop_prompt_box_btn: {
    width: "100%",
    height: "100%",
    position: "center"
  },
  pop_prompt_loading: {
    position: "center",
    marginTop: 10,
    marginLeft: 10,
    visible: false
  },
  pop_prompt_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_prompt_btns_text: {
    marginTop: -3,
    position: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_prompt_close: {
    position: "rightTop",
    marginRight: -5,
    marginTop: 5
  },
  pop_prompt_info: {
    fontSize: 16,
    color: "#fff",
    width: "100%",
    fontFamily: "pFont",
    position: "centerBottom",
    height: 45,
    marginBottom: 20,
    color: "#00b42a",
    textAlign: "center",
    visible: false
  }
};