import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export const guide = {
  data() {
    return {
      guide: null,
    };
  },
  watch: {
    "accountInfo.init": {
      handler(newstep, oldstep) {
        console.log("newstepnewstepnewstepnewstep", newstep);
        if (newstep === 3) {
          this.$refs?.guideRef?.clearPop();
        } else if (newstep === 2) {
          // this.$refs.guideRef.initPop({
          //   x: 0,
          //   y: 0,
          //   type: "other",
          //   radius: 0,
          //   blur: 5
          // });
          let timer = setInterval(() => {
            const goldminer = document.getElementById("goldminer");
            if (goldminer) {
              clearInterval(timer);
              timer = null;
              goldminer.__vue__.$children[0].pageMinerDetailShow = true;
            }
          }, 0);
        } else if (oldstep === 2 && newstep === 3) {
          this.$refs.guideRef.initPop({
            x: 0,
            y: 0,
            type: "other",
            radius: 0,
            blur: 5,
            hint: "Congratulations! You have completed the tutorial. You are about to embark on an exciting journey.",
            callback: () => {
              this.$refs.guideRef.clearPop();
            },
          });
        }
      },
      deep: true,
      immediate: true,
    },
    guideInfo_step0_0_coordinate: {
      handler(obj) {
        if (obj) {
          const radius = 110;
          this.$refs.guideRef.initPop({ x: obj.x - radius, y: obj.y - radius - 10, offsetY: -150, radius: radius, blur: 5, guideHandX: 120, guideHandY: radius + 10, hint: "Welcome to the world of Gold Miner! Try clicking it to collect 1500 coins." });
        }
      },
    },
    guideInfo_step1_0_coordinate: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.initPop({ x: obj.x - 10, y: obj.y - 10, radius: 38, blur: 5, hint: "Congratulations! You can now proceed to recruitment to hire skilled miners." });
        }
      },
    },
    guideInfo_step1_1_coordinate: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.initPop({ x: obj.x, y: obj.y - 75, w: obj.width, h: obj.height, blur: 10, radius: 10, type: "rectangle", hint: "Alright! Next, let's use the coins to recruit a miner." });
        }
      },
    },
    guideInfo_step1_1_is: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.clearPop();
        }
      },
    },
    guideInfo_step2_0_coordinate: {
      handler(obj) {
        if (obj) {
          const radius = 7
          this.$refs.guideRef.initPop({ x: obj.x, y: obj.y, w: obj.width/1.22, radius, h: 40, blur: 10, type: "rectangle",guideHandY: radius - 30,offsetY: -100, hint: "Congratulations on acquiring your first miner! Please try equipping it to the mine to collect gold." });
        }
      },
    },
    guideInfo_step2_1_coordinate: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.initPop({ x: obj.x + 9, y: obj.y + 9, radius: 40, blur: 5, offsetY: 50, hint: "Please place a miner" });
        }
      },
    },
    guideInfo_step2_1_is: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.clearPop();
        }
      },
    },
    guideInfo_step3_0_is: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.initPop({ x: 0, y: 0, radius: 0, blur: 5, hint: "Please place a miner" });
        }
      },
    },
    guideInfo_step1_1_is: {
      handler(obj) {
        if (obj) {
          this.$refs.guideRef.clearPop();
        }
      },
    },
  },
  computed: {
    ...gameVuex.mapState(["guideInfo"]),
    ...userVuex.mapState(["accountInfo"]),
    guideInfo_step0_0_coordinate() {
      return this.guideInfo.step0[0].coordinate;
    },
    guideInfo_step1_0_coordinate() {
      return this.guideInfo.step1[0].coordinate;
    },
    guideInfo_step1_1_coordinate() {
      return this.guideInfo.step1[1].coordinate;
    },
    guideInfo_step1_1_is() {
      return this.guideInfo.step1[1].is;
    },
    guideInfo_step2_0_coordinate() {
      return this.guideInfo.step2[0].coordinate;
    },
    guideInfo_step2_1_coordinate() {
      return this.guideInfo.step2[1].coordinate;
    },
    guideInfo_step2_1_is() {
      return this.guideInfo.step2[1].is;
    },
    guideInfo_step3_0_is() {
      return this.guideInfo.step3[0].is;
    },
  },
  created() {},

  methods: {},
};
