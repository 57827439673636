var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-gem-mint', {
    attrs: {
      "show": _vm.popGemMintShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGemMintShow = $event;
      }
    }
  }), _c('pop-gem-info', {
    attrs: {
      "show": _vm.popGemInfoShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGemInfoShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };