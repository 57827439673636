var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-coin-sell', {
    attrs: {
      "show": _vm.popCoinSellShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popCoinSellShow = $event;
      },
      "submit": _vm.popCoinSellSubmit
    }
  }), _c('pop-my-market', {
    ref: "popMyMarketRef",
    attrs: {
      "show": _vm.popMyMarketShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popMyMarketShow = $event;
      },
      "submit": _vm.popMyMarketSubmit
    }
  }), _c('pop-my-mine', {
    attrs: {
      "show": _vm.popMyMineShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popMyMineShow = $event;
      },
      "submit": _vm.popMyMineSubmit
    }
  }), _c('pop-prompt', {
    ref: "marketBuyRef",
    attrs: {
      "domName": "popBuyDom",
      "show": _vm.popBuyOption.show,
      "content": _vm.popBuyOption.content
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.popBuyOption, "show", $event);
      },
      "submit": _vm.popBuySubmit
    }
  }), _c('pop-prompt', {
    ref: "marketDelistRef",
    attrs: {
      "domName": "popDelistDom",
      "show": _vm.popDelistOption.show,
      "content": _vm.popDelistOption.content
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.popDelistOption, "show", $event);
      },
      "submit": _vm.popDelistSubmit
    }
  }), _c('assets-info-m', {
    ref: "assetsInfo1"
  }), _c('pop-market-info', {
    attrs: {
      "show": _vm.popMarketInfoShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popMarketInfoShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };