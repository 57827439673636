import store from "@/store";
import { Sprite, Assets, AnimatedSprite, Ticker, NineSlicePlane, Point, TilingSprite, Texture } from "pixi.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const ratio = 0.82;
import gsap from "gsap";
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollItemWidth = scrollWidth / 3 - 4;
import { Layout } from "@pixi/layout";
const [coinProgressBar, coinProgressText] = _v.c_columnRender({
  width: scrollItemWidth,
  icon: _v.m_getRes("element").textures["icon-mined-coin.png"],
  progress: 0,
  offsetTextX: -14,
  button: _v.m_otherBtn({
    content: _v.m_tooltip({
      content: Sprite.from(_v.m_getRes("element").textures["icon-question1.png"])
    }, "Free Coins: Only gold coins mined by miners are salable\n to maintain market circulation. Coins from daily tasks, rewards,\n or purchases are not salable, only usable.", {
      marginLeft: 500,
      fill: true
    })
  }, async event => {})
});
let isFlag = true;
const btnRefresh1 = Sprite.from(_v.m_getRes("element").textures["btn-refresh-1.png"]);
btnRefresh1.anchor.set(0.5);
btnRefresh1.x = btnRefresh1.x + btnRefresh1.width / 2;
btnRefresh1.y = btnRefresh1.y + btnRefresh1.height / 2;
const [diamondProgressBar, diamondProgressText] = _v.c_columnRender({
  width: scrollItemWidth,
  icon: _v.m_getRes("element").textures["icon-diamond.png"],
  progress: 0,
  offsetTextX: -14,
  button: _v.m_otherBtn({
    content: btnRefresh1
  }, async event => {
    if (isFlag) {
      _v.$toasted.clear();
      isFlag = false;
      const rotationTween = gsap.to(btnRefresh1, {
        rotation: Math.PI * 2,
        duration: 1,
        repeat: -1,
        ease: "none"
      });
      await store.dispatch("user/getAccountInfo");
      window.renderDomM({
        type: "refresh",
        diamond: true
      });
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        isFlag = true;
        rotationTween.kill();
        gsap.to(btnRefresh1, {
          rotation: 0,
          duration: 0.2
        });
      }, 3000);
    } else {
      // _v.$toasted.clear();
      // _v.$toasted.error("Refreshing too quickly!");
    }
  })
});
const [gemProgressBar, gemProgressText] = _v.c_columnRender({
  width: scrollItemWidth,
  icon: _v.m_getRes("element").textures["icon-gem.png"],
  progress: 0
});
export default {
  name: "assetsInfo",
  data() {
    const coin = coinProgressBar;
    const coin_text = coinProgressText;
    const diamond = diamondProgressBar;
    const diamond_text = diamondProgressText;
    const gem = gemProgressBar;
    const gem_text = gemProgressText;
    return {
      popGemMintShow: false,
      popGemInfoShow: false,
      assets_icon_main_coin: coin,
      assets_icon_main_coin_text: coin_text,
      assets_icon_main_diamond: diamond,
      assets_icon_main_diamond_text: diamond_text,
      assets_icon_main_gem: gem,
      assets_icon_main_gem_text: gem_text
    };
  },
  computed: {
    ...userVuex.mapState(["accountInfo", "coinInfo"]),
    pageDom() {
      return this.$parent?.pageDom;
    },
    popDom() {
      return this.$parent?.popDom;
    }
  },
  methods: {
    renderDom(option) {
      let coin = option?.coin;
      let diamond = option?.diamond;
      let gme = option?.gme;
      let type = option?.type ? option.type : "dom";
      if (coin) this.assets_icon_main_coin_text.text = `${this.toPre(this.accountInfo.free_coin, 0)}`;
      if (diamond) this.assets_icon_main_diamond_text.text = `${this.toPre(this.accountInfo.diamond, 2)}`;
      if (gme) this.assets_icon_main_gem_text.text = `${this.toPre(this.accountInfo.gme, 0)}`;
      if (type === "dom") {
        return {
          content: {
            asset_info: {
              content: {
                assets_icon: coin && {
                  content: {
                    assets_icon_main_coin: {
                      content: this.assets_icon_main_coin,
                      styles: globalStyles.assets_icon_main
                    }
                  },
                  styles: globalStyles.assets_item
                },
                assets_b: {
                  content: " "
                },
                assets_diamond: diamond && this.m_otherBtn({
                  content: {
                    assets_icon_main_diamond: {
                      content: this.assets_icon_main_diamond,
                      styles: globalStyles.assets_icon_main
                    }
                  },
                  styles: globalStyles.assets_item
                }, () => {
                  this.$parent.$parent.pageDiamondShow = true;
                  console.log(555);
                }),
                assets_x: {
                  content: " "
                },
                assets_gem: gme && this.m_btn({
                  content: {
                    assets_icon_main_gem: {
                      content: this.assets_icon_main_gem,
                      styles: globalStyles.assets_icon_main_gem
                    }
                  },
                  styles: globalStyles.assets_item
                }, () => {
                  this.popGemInfoShow = true;
                })
              }
            }
          }
        };
      } else if (type === "refresh") {
        if (coin) {
          this.assets_icon_main_coin_text.text = `${this.toPre(this.accountInfo.free_coin, 0)}`;
        }
        if (diamond) {
          this.assets_icon_main_diamond_text.text = `${this.toPre(this.accountInfo.diamond, 2)}`;
        }
        if (gme) {
          this.assets_icon_main_gem_text.text = `${this.toPre(this.accountInfo.gme, 0)}`;
        }
      }
    }
  },
  mounted() {
    window.renderDomM = this.renderDom;
  }
};
const globalStyles = {
  asset_info: {
    position: "centerLeft",
    width: _v.screenWidth
  },
  assets_item: {},
  assets_b: {
    width: 2
  }
};