import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
import CTab from "@game/components/CTab";
await Assets.loadBundle("mine");
const ratio = 0.82;
const scrollWidth = parseInt(_v.screenWidth * ratio);
let scrollHeight = _v.screenHeight - 130;
const scrollItemWidth = scrollWidth;
const scrollItemHeight = 100;
const isLoading = _v.c_listLoading();
export default {
  name: "mine",
  inject: ["changeScreenDom", "changeMinerDom"],
  computed: {
    ...gameVuex.mapState(["gameApp", "mineList", "myMineList", "myMineListPage", "myMineListTotal"]),
    ...userVuex.mapState(["accountInfo"]),
    isRender() {
      return this.$parent.pageMineShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          if (!this.tabDom) {
            this.tabDom = new CTab(this.tabList, this.isTab, itm => {
              this.isTab = itm.value;
            });
          }
          this.$nextTick(() => {
            this.$refs.assetsInfo1.renderDom({
              coin: true,
              diamond: true,
              type: "refresh"
            });
          });
          await this.initScreen();
          this.setListLoading(true);
          if (this.isTab === 0) {
            await this.getMyMineListAndCalcStock();
          } else {
            await this.getMyMineList();
            this.initMyMine();
          }
          this.loadMineList();
        } else {
          this.isSwitchFlag = true;
          if (this.isRefresh) {
            this.$parent.showMineBtn();
          }
        }
      },
      // deep: true,
      immediate: true
    },
    isTab: {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.setListLoading(true);
          if (newVal === 1) {
            await this.getMyMineList();
          } else {
            await this.getMyMineListAndCalcStock();
          }
          this.loadMineList();
        }
      }
    }
  },
  data() {
    return {
      currentScreen: null,
      pageDom: null,
      tabList: [{
        label: "MINE",
        value: 0
      }, {
        label: "MY MINE",
        value: 1
      }],
      isTab: 0,
      selectMine: null,
      popSwitchPromptShow: false,
      popSwitchSubmitOption: {
        show: false,
        type: "confirm",
        title: "Switch Mine",
        content: "Are you sure you want to switch mine?",
        btnText: "Confirm"
      },
      popBuyPromptOption: {
        show: false,
        type: "confirm",
        title: "Buy Mine",
        content: "Are you sure you want to buy mine?",
        btnText: "Confirm"
      },
      stock: 0,
      newMintList: [],
      minePool: null,
      tabDom: null,
      selectInitMine: null,
      popBuyMineDom: null,
      popSwitchMineDom: null,
      isRefresh: false,
      isSwitchFlag: true
    };
  },
  async beforeDestroy() {
    await this.m_clearCurrentScreen(this.currentScreen);
  },
  methods: {
    ...gameVuex.mapActions(["calcMineStock", "getMyMineList", "buyMine", "switchMine"]),
    ...gameVuex.mapMutations(["setMyMineListPage"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    setListLoading(bool) {
      const scrollDom = this.pageDom.content.getByID("mine_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    initScreen() {
      this.$nextTick(async () => {
        await this.setScreen();
      });
    },
    async setScreen() {
      this.setMyMineListPage({
        page: 1,
        perPage: 10
      });
      if (!_v[`page_mine`]) {
        _v[`page_mine`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_mine`]);
      }
      _v[`page_mine`].visible = true;
      _v[`page_mine`].zIndex = this.gameApp.stage.children.length + 1;
      this.pageDom = _v[`page_mine`];
      this.setZindex();
    },
    async getMyMineListAndCalcStock() {
      const mineList = this.mineList;
      const data = await this.calcMineStock();
      this.newMintList = [];
      mineList.map(item => {
        if (data.length > 0) {
          data.map(itm => {
            if (item.type_id === itm.type_id) {
              this.newMintList.push({
                ...item,
                ...itm,
                remainingStock: item.stock - itm.sold
              });
            }
          });
        } else {
          this.newMintList.push({
            ...item,
            remainingStock: item.stock
          });
        }
      });
    },
    setZindex() {
      const mine_page = this.pageDom.getChildByID("mine_page");
      const mine_btn_back = this.pageDom.getChildByID("mine_btn_back");
      const mine_coin = this.pageDom.getChildByID("mine_coin");
      const mine_head = this.pageDom.getChildByID("mine_head");
      const mine_list = this.pageDom.getChildByID("mine_list");
      mine_page.sortableChildren = true;
      mine_btn_back.zIndex = 5;
      mine_coin.zIndex = 2;
      mine_head.zIndex = 4;
      mine_list.zIndex = 3;
    },
    initSelectMin() {
      if (!this.myMineList) return;
      this.myMineList.map(it => {
        this.initMyMine(it.id);
      });
    },
    loadMineList() {
      const mine_list_page = this.pageDom.content.getByID("mine_list_page");
      if (this.isTab === 1 && this.myMineListTotal > this.myMineListPage.perPage) {
        scrollHeight = _v.screenHeight - 130;
        const mine_list_page_main = this.pageDom.content.getByID("mine_list_page_main").content;
        mine_list_page.visible = true;
        mine_list_page_main.createContent(this.c_page(this.myMineListTotal, page => {
          this.setMyMineListPage({
            page
          });
          (async _page => {
            await this.getMyMineList();
            this.loadMineList();
          })(page);
        }, this.myMineListPage));
        mine_list_page_main.removeContent(mine_list_page_main.children.entries().next().value[0]);
      } else {
        scrollHeight = _v.screenHeight - 120;
        mine_list_page.visible = false;
      }
      const scrollDom = this.pageDom.content.getByID("mine_list_scroll").content;
      const listData = this.isTab == 0 ? this.mineListDom(this.newMintList) : this.myMineListDom(this.myMineList);
      if (!listData) {
        this.setListLoading(false);
        return;
      }
      scrollDom.createContent(listData);
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      this.tabDom?.setTab(this.isTab);
      this.initSelectMin();
      this.setListLoading(false);
    },
    mineItemDom(item, index) {
      return {
        id: "mine_item",
        content: {
          mine_item_bg_1: {
            content: this.sf_taskItemBg({
              width: scrollWidth * 4,
              height: scrollItemHeight * 4
            }),
            styles: globalStyles.mine_item_bg
          },
          mine_item_main: {
            content: {
              mine_item_main_head: {
                content: {
                  mine_item_main_head_bg: {
                    content: this.sf_taskItemImg({
                      width: 300,
                      height: 300
                    })
                  },
                  mine_item_main_head_img: {
                    content: item.isBuy ? this.f_colorOverlayFilter(this.setMine(item.type_id, "thumbnail"), {
                      color: 0x262e3b,
                      alpha: 1
                    }) : Sprite.from(this.setMine(item.type_id, "thumbnail"))
                  },
                  mine_item_main_head_lock: {
                    content: item.diamond > 0 && item.isBuy && Sprite.from(this.m_getRes("element").textures["icon-lock.png"])
                  }
                }
              },
              mine_item_main_info: {
                content: {
                  mine_item_main_info_title: {
                    content: this.setMine(item.type_id, "name")
                  },
                  mine_item_main_info_reward: {
                    content: {
                      mine_item_main_info_reward_title: {
                        content: `${this.$lang("Capacity")}: ${item.cap}`
                      },
                      mine_item_main_info_reward_num: {
                        content: `${this.$lang("Accelerate")} ??%`
                      }
                    }
                  },
                  mine_item_main_info_btns: this.m_btn({
                    content: {
                      mine_item_main_info_btns_bg: {
                        content: item.remainingStock > 0 ? this.sf_btnBg({
                          width: 380,
                          height: 150
                        }) : this.sf_btnDisableBg({
                          width: 380,
                          height: 150
                        }),
                        styles: globalStyles.mine_item_main_info_btns_bg
                      },
                      mine_item_main_info_btns_main: {
                        content: {
                          mine_item_main_info_btns_icon: {
                            content: Sprite.from(this.m_getRes("element").textures["icon-diamond.png"]),
                            styles: globalStyles.mine_item_main_info_btns_icon
                          },
                          mine_item_main_info_btns_text: {
                            content: `${item.diamond}`,
                            styles: globalStyles.mine_item_main_info_btns_text
                          }
                        },
                        styles: globalStyles.mine_item_main_info_btns_main
                      }
                    },
                    styles: globalStyles.mine_item_main_info_btns
                  }, () => {
                    if (item.remainingStock > 0) {
                      this.selectMine = item.type_id;
                      this.popBuyPromptOption.show = true;
                    } else {
                      this.$toasted.error(this.$lang("Insufficient Stock!"));
                    }
                    console.log("click ok!");
                    console.log("mineList=====", this.newMintList, this.myMineList);
                  }),
                  mine_item_main_info_tag: {
                    content: item.diamond <= 0 && Sprite.from(this.m_getRes("element").textures["icon-fee.png"])
                  },
                  mine_item_main_head_stock: {
                    content: `Stock: ${this.toPre(item.remainingStock, 0)}`,
                    styles: item.remainingStock > 0 ? {
                      ...globalStyles.mine_item_main_head_stock,
                      ...globalStyles.mine_item_main_head_stock_normal
                    } : {
                      ...globalStyles.mine_item_main_head_stock,
                      ...globalStyles.mine_item_main_head_stock_error
                    }
                  }
                }
              }
            }
          },
          mine_item_main_head_question: {
            content: {
              mine_item_main_head_question_main: this.m_tooltip({
                content: Sprite.from(this.m_getRes("element").textures["icon-question1.png"])
              }, `There is a ${item.acce * 100}% chance to receive a ${item.acceMin * 100}%-${item.acceMax * 100}% mining boost, which will permanently increase the mining speed for all miners working in that mine. Additionally, only ${item.max} such mine can be active at the same time.`, {
                fill: true
              })
            },
            styles: {
              position: "leftTop",
              marginTop: 0,
              marginLeft: 0,
              scale: 0.22
            }
          }
        },
        globalStyles
      };
    },
    mineListDom(list) {
      return this.c_scrollBox(list.map((it, idx) => {
        return this.m_btn(this.mineItemDom(it, idx), null, {
          width: scrollItemWidth,
          height: scrollItemHeight + 10
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 55
      });
    },
    myMineItemDom(item, index) {
      const loading = _v.c_listLoading(0);
      return {
        id: "mine_item",
        content: {
          mine_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollWidth * 4,
              height: scrollItemHeight * 4
            }),
            styles: globalStyles.mine_item_bg
          },
          mine_item_main: {
            content: {
              mine_item_main_head: {
                content: {
                  mine_item_main_head_bg: {
                    content: this.sf_taskItemImg({
                      width: 300,
                      height: 300
                    }),
                    styles: globalStyles.mine_item_main_head_bg
                  },
                  mine_item_main_head_img: {
                    content: Sprite.from(this.setMine(item.type_id, "thumbnail")),
                    styles: globalStyles.mine_item_main_head_img
                  }
                },
                styles: globalStyles.mine_item_main_head
              },
              mine_item_main_info: {
                content: {
                  mine_item_main_info_title: {
                    content: this.setMine(item.type_id, "name"),
                    styles: globalStyles.mine_item_main_info_title
                  },
                  mine_item_main_info_reward: {
                    content: {
                      mine_item_main_info_reward_title_my: {
                        content: `ID: ${item.id}`,
                        styles: globalStyles.mine_item_main_info_reward_title_my
                      },
                      mine_item_main_info_reward_id_my: {
                        content: `${this.$lang("Capacity")}: ${item.cap}`,
                        styles: globalStyles.mine_item_main_info_reward_id_my
                      },
                      mine_item_main_info_reward_num_my: (item.acce - 1) * 100 > 0 && {
                        content: `${this.$lang("Accelerate")} ${this.toPre((item.acce - 1) * 100, 2)}%`,
                        styles: globalStyles.mine_item_main_info_reward_num_my
                      },
                      mine_item_main_info_reward_check: {
                        content: Sprite.from(_v.m_getRes("element").textures["icon-ok.png"]),
                        styles: globalStyles.mine_item_main_info_reward_check
                      },
                      common_radio: this.m_btn({
                        content: {
                          common_radio_main: {
                            content: {
                              common_radio_main_p: {
                                content: {
                                  common_radio_item_open: {
                                    content: "On",
                                    styles: {
                                      width: 40,
                                      height: 22,
                                      background: "#f9a508",
                                      color: "#111",
                                      textAlign: "center",
                                      fontSize: 12,
                                      borderRadius: 3,
                                      fontFamily: "pFont"
                                    }
                                  },
                                  common_radio_item_off: {
                                    content: "Off",
                                    styles: {
                                      borderRadius: 3,
                                      width: 40,
                                      height: 22,
                                      background: "#374354",
                                      color: "#d4d4d4",
                                      textAlign: "center",
                                      fontSize: 12,
                                      fontFamily: "pFont"
                                    }
                                  }
                                },
                                styles: {
                                  marginLeft: 2,
                                  marginTop: 2,
                                  visible: false,
                                  position: "leftTop"
                                }
                              },
                              common_radio_main_f: {
                                content: {
                                  common_radio_item_open: {
                                    content: "On",
                                    styles: {
                                      width: 40,
                                      height: 22,
                                      background: "#374354",
                                      color: "#d4d4d4",
                                      textAlign: "center",
                                      fontSize: 12,
                                      borderRadius: 3,
                                      fontFamily: "pFont"
                                    }
                                  },
                                  common_radio_item_off: {
                                    content: "Off",
                                    styles: {
                                      borderRadius: 3,
                                      width: 40,
                                      height: 22,
                                      background: "#f9a508",
                                      color: "#111",
                                      textAlign: "center",
                                      fontSize: 12,
                                      fontFamily: "pFont"
                                    }
                                  }
                                },
                                styles: {
                                  marginTop: 2,
                                  position: "leftTop"
                                }
                              }
                            },
                            styles: {
                              paddingTop: 2,
                              paddingLeft: 2,
                              paddingRight: 2,
                              paddingBottom: 2,
                              borderRadius: 3,
                              background: "#374354",
                              width: 82,
                              overflow: "hidden"
                            }
                          }
                        },
                        styles: {
                          marginTop: -20,
                          position: "rightCenter",
                          marginRight: 15
                        }
                      }, () => {
                        this.switchMyMine(item, index);
                      }),
                      common_radio_loading: {
                        content: loading.render(),
                        styles: globalStyles.common_radio_loading
                      }
                    },
                    styles: globalStyles.mine_item_main_info_reward
                  }
                },
                styles: globalStyles.mine_item_main_info
              }
            },
            styles: globalStyles.mine_item_main
          }
        },
        styles: globalStyles.mine_item
      };
    },
    myMineListDom(list) {
      if (!list) {
        return null;
      }
      return this.c_scrollBox(list.map((it, idx) => {
        return this.m_btn(this.myMineItemDom(it, idx), null, {
          width: scrollItemWidth,
          height: scrollItemHeight + 10
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 55
      });
    },
    renderDom() {
      return {
        content: {
          mine_page: {
            content: {
              mine_bg: {
                content: this.m_tilingSprite("bg5.jpg")
              },
              mine_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_mine`].visible = false;
                  this.$parent.pageMineShow = false;
                })
              },
              mine_coin: {
                content: this.$refs.assetsInfo1.renderDom({
                  diamond: true,
                  coin: true
                })
              },
              mine_head: {
                content: {
                  mine_head_bg: {
                    content: this.sf_popTitleBg({
                      width: this.screenWidth * 4 - 100,
                      height: 250
                    })
                  },
                  mine_tab: {
                    content: this.tabDom,
                    styles: globalStyles.mine_tab
                  }
                }
              },
              mine_list: {
                content: {
                  mine_list_bg: {
                    content: this.sf_marketGoods({
                      width: this.screenWidth * 4 - 140,
                      height: this.screenHeight * 4 - 380
                    })
                  },
                  common_loading: isLoading.render(),
                  mine_list_scroll: {
                    content: " "
                  },
                  mine_list_page: {
                    content: {
                      mine_list_page_main: {
                        content: " "
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    async popBuyPromptSubmit(callback) {
      console.log("this.selectMine", this.selectMine);
      switch (this.popBuyPromptOption.type) {
        case "confirm":
          this.popBuyPromptOption.type = "check";
          this.popBuyPromptOption.btnText = "Check";
          // console.log(66666666);
          const res = await this.buyMine(this.selectMine);
          if (!res) {
            this.popBuyPromptOption.type = "confirm";
            this.popBuyPromptOption.btnText = "Confirm";
            this.$refs.buyMineRef.setBtnLoading(false);
            return;
          }
          await this.getMyMineListAndCalcStock();
          await this.getAccountInfo();
          this.loadMineList();
          this.$refs.assetsInfo1.renderDom({
            diamond: true,
            type: "refresh"
          });
          typeof callback == "function" && callback();
          break;
        case "check":
          this.isTab = 1;
          this.tabDom?.setTab(this.isTab);
          this.popBuyPromptOption.type = "confirm";
          this.popBuyPromptOption.btnText = "Confirm";
          this.popBuyPromptOption.show = false;
          if (this.popBuyMineDom) {
            this.popBuyMineDom.visible = false;
          }
          break;
      }
      this.popBuyPromptOption.show = false;
    },
    initMyMine(id) {
      const scrollDom = this.pageDom.getChildByID("mine_list_scroll").children[0].children[1];
      const index = this.myMineList.findIndex(itm => itm.id == id);
      const mine_item_bg = scrollDom.children[index]?.getChildByID("mine_item_bg");
      const common_radio_main = scrollDom.children[index]?.getChildByID("common_radio_main");
      if (mine_item_bg && this.myMineList[index].is_default) {
        mine_item_bg.children[0].texture = this.m_getRes("base").textures["mine-default-item-bg.png"];
      }
      if (common_radio_main) {
        common_radio_main.visible = !Boolean(this.myMineList[index].is_default);
      }
      const common_radio_main_p = scrollDom.children[index]?.getChildByID("common_radio_main_p");
      const common_radio_main_f = scrollDom.children[index]?.getChildByID("common_radio_main_f");
      if (!common_radio_main_p || !common_radio_main_f) return;
      if (this.myMineList[index].status === 0) {
        common_radio_main_p.visible = false;
        common_radio_main_f.visible = true;
      } else {
        common_radio_main_p.visible = true;
        common_radio_main_f.visible = false;
      }
    },
    async switchMyMine(item, index) {
      if (this.isSwitchFlag) {
        const scrollDom = this.pageDom.getChildByID("mine_list_scroll").children[0].children[1];
        const common_radio_loading = scrollDom.children[index]?.getChildByID("common_radio_loading");
        this.isSwitchFlag = false;
        common_radio_loading.visible = true;
        const data = await this.switchMine(item.id);
        if (!data) {
          common_radio_loading.visible = false;
          this.isSwitchFlag = true;
          return;
        }
        this.isRefresh = true;
        const common_radio_main_p = scrollDom.children[index]?.getChildByID("common_radio_main_p");
        const common_radio_main_f = scrollDom.children[index]?.getChildByID("common_radio_main_f");
        if (item.status === 0) {
          common_radio_main_p.visible = true;
          common_radio_main_f.visible = false;
          item.status = 1;
        } else {
          common_radio_main_p.visible = false;
          common_radio_main_f.visible = true;
          item.status = 0;
        }
        await this.getAccountInfo(data);
        this.$parent.showMineBtn("refresh");
        console.log("this.accountInfo.power", this.accountInfo.power * this.powerTime);
        this.changeMinerDom();
        this.isSwitchFlag = true;
        common_radio_loading.visible = false;
      }
    },
    async onSwitchMyMine(item, index) {
      this.selectMine = {
        ...item,
        index
      };
      this.popSwitchSubmitOption.type = "confirm";
      this.popSwitchSubmitOption.btnText = "Confirm";
      this.popSwitchSubmitOption.show = true;
    },
    async popSwitchPromptSubmit(callback) {
      console.log("this.popSwitchSubmitOption.type", this.popSwitchSubmitOption.type);
      switch (this.popSwitchSubmitOption.type) {
        case "confirm":
          this.popSwitchSubmitOption.type = "check";
          this.popSwitchSubmitOption.btnText = "Check";
          // console.log(666666661111, this.selectMine.id);
          const res = await this.switchMine(this.selectMine.id);
          if (!res) {
            this.popSwitchSubmitOption.type = "confirm";
            this.popSwitchSubmitOption.btnText = "Confirm";
            this.popSwitchSubmitOption.show = false;
            this.$refs.switchRef.setBtnLoading(false);
            this.initMyMine(this.selectInitMine.id);
            return;
          }
          this.initMyMine(this.selectMine.id);
          await this.getAccountInfo();
          await this.changeScreenDom();
          await this.changeMinerDom();
          if (this.$parent.$refs?.pageMinerDetailRef?.$refs.popGoMineRef) {
            this.$parent.$refs?.pageMinerDetailRef?.$refs?.popGoMineRef.setMinerMapDom();
          }
          typeof callback == "function" && callback();
          break;
        case "check":
          this.popSwitchSubmitOption.type = "confirm";
          this.popSwitchSubmitOption.btnText = "Confirm";
          this.popSwitchSubmitOption.show = false;
          if (this.popSwitchMineDom) {
            this.popSwitchMineDom.visible = false;
          }
          _v[`page_mine`].visible = false;
          this.$parent.pageMineShow = false;
          break;
      }
    },
    popBuyPromptClose() {
      if (this.popBuyPromptOption.type == "check") {
        this.popBuyPromptOption.type = "confirm";
        this.popBuyPromptOption.btnText = "Confirm";
      }
    },
    changeMine(item, status) {
      this.$set(item, "status", status);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!_v[`page_mine`]) {
        this.tabDom = new CTab(this.tabList, this.isTab, itm => {
          this.isTab = itm.value;
        });
        _v[`page_mine`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_mine`]);
      }
      _v[`page_mine`].visible = false;
      this.pageDom = _v[`page_mine`];
    });
  }
};
const globalStyles = {
  mine_page: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren"
  },
  mine_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  mine_coin: {
    position: "topRight",
    marginTop: 15
  },
  mine_head: {
    position: "centerTop",
    width: "100%",
    height: 50,
    marginTop: 60
  },
  mine_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  mine_tab: {
    position: "center"
  },
  mine_list: {
    position: "centerTop",
    marginTop: 70
  },
  mine_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  mine_list_scroll: {
    position: "centerTop"
    // marginLeft: 35,
  },

  mine_list_page: {
    width: scrollWidth,
    position: "centerTop",
    background: "#364254",
    marginTop: scrollHeight - 20
  },
  mine_list_page_main: {
    marginTop: 8,
    position: "center"
  },
  page_layout: {
    position: "centerTop",
    anchorX: 0.5,
    anchorY: 0.5
  },
  mine_item_bg: {
    position: "center",
    maxWidth: "100%",
    scale: 0.25
  },
  mine_item_main: {
    position: "center",
    width: scrollItemWidth - 25
  },
  mine_item_main_head: {
    width: 75,
    height: 75,
    borderRadius: 12,
    overflow: "hidden"
  },
  mine_item_main_head_bg: {
    scale: 0.25
  },
  mine_item_main_head_img: {
    position: "center",
    scale: 0.2
    // marginLeft: -3,
    // marginTop: -4,
  },

  mine_item_main_head_lock: {
    position: "center",
    scale: 0.25
  },
  mine_item_main_head_stock: {
    position: "rightTop",
    fontSize: 12,
    width: 100,
    textAlign: "center",
    height: 20,
    color: "#111",
    fontFamily: "pFont",
    marginTop: 5
  },
  mine_item_main_head_stock_normal: {
    color: "#111"
  },
  mine_item_main_head_stock_error: {
    color: "#f53f3f"
  },
  mine_item_main_head_fee: {},
  mine_item_main_info: {
    width: scrollItemWidth - 75 - 5 - 20,
    height: 80,
    marginLeft: 5,
    paddingTop: 5,
    overflow: "hidden"
  },
  mine_item_main_info_title: {
    fontSize: 18,
    color: "#2e3443",
    fontFamily: "pFont",
    marginLeft: 5
  },
  mine_item_main_info_reward: {
    position: "leftTop",
    width: scrollItemWidth - 75 - 5 - 20,
    height: "100%",
    marginTop: 22,
    marginLeft: 5
    // overflow: 'hidden'
  },

  mine_item_main_info_reward_num: {
    marginTop: 15,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_title: {
    marginTop: 15,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_id_my: {
    marginTop: 6,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_title_my: {
    marginTop: 6,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_num_my: {
    marginTop: 6,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_check: {
    position: "right",
    width: 100,
    height: 30,
    scale: 0.25,
    visible: false,
    marginRight: 10,
    marginTop: 5
  },
  mine_item_main_info_btns: {
    position: "rightBottom",
    width: 100,
    height: 40,
    marginBottom: 5,
    overflow: "hidden"
  },
  mine_item_main_info_btns_bg: {
    scale: 0.25
  },
  mine_item_main_info_btns_main: {
    position: "center"
  },
  mine_item_main_info_btns_icon: {
    width: 28,
    height: 28,
    scale: 0.22
  },
  mine_item_main_info_btns_text: {
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  mine_item_main_info_tag: {
    scale: 0.3,
    marginRight: 5,
    // marginBottom: 5,
    position: "rightBottom"
  },
  common_radio_loading: {
    position: "bottomRight",
    marginRight: 20,
    marginBottom: 5,
    width: 30,
    heigth: 30,
    visible: false,
    scale: 0.6
  }
};