import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
export default {
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo"]),
    ...userVuex.mapState(["accountInfo"]),
    isRender() {
      return this.$parent.popUpgradeStorage;
    }
  },
  name: "popUpgradeStorage",
  watch: {
    isRender: {
      handler(bool) {
        if (bool) {
          this.initPop();
          this.loadStorageInfo();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["storeUpgrade"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    initPop() {
      if (!this.$parent.popUpgradeStorageDom) {
        this.$parent.popUpgradeStorageDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popUpgradeStorageDom);
      }
      this.$parent.popUpgradeStorageDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popUpgradeStorageDom.position.set(0, 0);
      this.setZindex();
      this.$parent.popUpgradeStorageDom.visible = false;
      this.anim_pop(this.$parent.popUpgradeStorageDom, null, {
        y: _v.screenHeight / 2 - 280
      });
    },
    setZindex() {
      const popDom = this.$parent.popUpgradeStorageDom;
      const pop_main = popDom.getChildByID("pop_main");
      const pop_head = popDom.getChildByID("pop_head");
      const pop_con = popDom.getChildByID("pop_con");
      pop_main.sortableChildren = true;
      pop_head.zIndex = 2;
      pop_con.zIndex = 1;
    },
    loadStorageInfo() {
      const popDom = this.$parent.popUpgradeStorageDom.content;
      if (popDom) {
        const pop_info_item_value = popDom.getByID("pop_info_item_value_1");
        pop_info_item_value.children[0].text = `${this.accountInfo.store_level}`;
        const pop_info_item_value_2 = popDom.getByID("pop_info_item_value_2");
        pop_info_item_value_2.children[0].text = `${this.toPre(this.accountInfo.store_max, 0)}`;
        const pop_btns_upgrade_value_label = popDom.getByID("pop_btns_upgrade_value_label");
        pop_btns_upgrade_value_label.children[0].text = `${this.toPre(this.accountInfo.store_fee, 0)}`;
        const pop_info = popDom.getByID("pop_info")?.layout;
        pop_info?.updateParents;
        const pop_btns_upgrade_value = popDom?.getByID("pop_btns_upgrade_value").layout;
        pop_btns_upgrade_value.updateParents();
      }
    },
    renderDom() {
      return {
        content: {
          pop_upgradeWarehouse: {
            content: {
              pop_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_head: {
                    content: {
                      pop_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_title: {
                        content: {
                          pop_title_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                          },
                          pop_title_text: {
                            content: "STORAGE"
                          }
                        }
                      },
                      pop_close: {
                        content: this.c_btnClose(() => {
                          this.$emit("update:show", false);
                          this.$parent.popUpgradeStorageDom.visible = false;
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_con: {
                    content: {
                      pop_main_bg: {
                        content: {
                          pop_main_bg_label: {
                            content: this.sf_marketGoods({
                              width: this.screenWidth * 4 - 140,
                              height: 440 * 4
                            })
                          }
                        }
                      },
                      pop_warehouse: {
                        content: {
                          content: Sprite.from("build_1")
                        }
                      },
                      pop_info: {
                        content: {
                          pop_info_item_1: {
                            content: {
                              pop_info_bg: {
                                content: {
                                  pop_info_bg_label: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: 570,
                                      height: 110
                                    })
                                  }
                                }
                              },
                              pop_info_item_title: {
                                content: "Lv."
                              },
                              pop_info_item_value_1: {
                                content: `${this.accountInfo.store_level}`,
                                styles: globalStyles.pop_info_item_value
                              }
                            },
                            styles: globalStyles.pop_info_item
                          },
                          pop_info_item_2: {
                            content: {
                              pop_info_bg: {
                                content: {
                                  pop_info_bg_label: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: 570,
                                      height: 110
                                    })
                                  }
                                }
                              },
                              pop_info_item_title: {
                                content: "Max"
                              },
                              pop_info_item_value_2: {
                                content: `${this.toPre(this.accountInfo.store_max, 0)}`,
                                styles: globalStyles.pop_info_item_value
                              }
                            },
                            styles: globalStyles.pop_info_item
                          }
                        }
                      },
                      pop_btns_upgrade: this.m_btn({
                        content: {
                          pop_btns_upgrade_con: {
                            content: {
                              pop_btns_upgrade_bg: {
                                content: this.sf_btnMainBg({
                                  width: 200 * 3,
                                  height: 220
                                }),
                                styles: globalStyles.pop_btns_upgrade_bg
                              },
                              pop_btns_upgrade_main: {
                                content: {
                                  pop_btns_upgrade_label: {
                                    content: this.$lang("UPGRADE"),
                                    styles: globalStyles.pop_btns_upgrade_label
                                  },
                                  pop_btns_upgrade_value: {
                                    content: {
                                      pop_btns_upgrade_value_label: {
                                        content: `${this.toPre(this.accountInfo.store_fee, 0)}`,
                                        styles: globalStyles.pop_btns_upgrade_value_label
                                      },
                                      pop_btns_upgrade_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-coin.png"]),
                                        styles: globalStyles.pop_btns_upgrade_icon
                                      }
                                    },
                                    styles: globalStyles.pop_btns_upgrade_value
                                  }
                                },
                                styles: globalStyles.pop_btns_upgrade_main
                              }
                            },
                            styles: globalStyles.pop_btns_upgrade_con
                          }
                        }
                      }, () => {
                        this.upgradeWarehouse();
                      }),
                      pop_load: {
                        content: this.c_btnLoading(),
                        styles: globalStyles.pop_load
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setBtnLoading(bool) {
      const popDom = this.$parent.popUpgradeStorageDom;
      const btnDom = popDom.getChildByID("pop_btns_upgrade_con");
      const loadingDom = popDom.getChildByID("pop_load");
      if (bool) {
        btnDom.visible = false;
        loadingDom.visible = true;
      } else {
        btnDom.visible = true;
        loadingDom.visible = false;
      }
    },
    async upgradeWarehouse() {
      this.setBtnLoading(true);
      this.$toasted.clear();
      const data = await this.storeUpgrade();
      if (!data) {
        this.setBtnLoading(false);
        return;
      }
      await this.getAccountInfo(data);
      this.loadStorageInfo();
      this.$parent.changeStroneDom();
      this.$parent.$refs.assetsInfo.renderDom({
        type: "refresh",
        dom: this.$parent.pageDom,
        coin: true,
        diamond: true,
        gme: true
      });
      this.setBtnLoading(false);
    }
  }
};
const globalStyles = {
  pop_upgradeWarehouse: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "left"
  },
  pop_mask: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: 550
    // overflow: "hidden",
  },

  pop_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50,
    marginTop: 60
  },
  pop_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_close: {
    position: "rightTop"
  },
  pop_con: {
    position: "centerTop",
    marginTop: 70,
    width: "100%",
    height: 420
  },
  pop_main_bg: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_main_bg_label: {
    scale: 0.25,
    position: "center"
  },
  pop_warehouse: {
    marginTop: 70,
    scale: 0.25,
    position: "centerTop"
  },
  pop_info: {
    position: "centerTop",
    marginTop: 270
  },
  pop_info_item_1: {},
  pop_info_item: {
    height: 40,
    width: 150,
    overflow: "hidden"
  },
  pop_info_bg: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_info_bg_label: {
    scale: 0.25
  },
  pop_info_item_title: {
    fontSize: 18,
    fontFamily: "pFont",
    color: "#7f83a5",
    position: "left",
    marginLeft: 12,
    marginTop: 2
  },
  pop_info_item_value: {
    fontSize: 18,
    fontFamily: "pFont",
    color: "#fff",
    position: "right",
    marginRight: 15,
    marginTop: 2,
    width: "100%",
    textAlign: "right"
  },
  pop_next: {
    position: "centerTop",
    marginTop: 340
  },
  pop_next_title: {
    fontSize: 16,
    fontFamily: "pFont",
    color: "#fff",
    position: "center",
    marginRight: 15,
    marginTop: 2
  },
  pop_next_info: {
    position: "centerTop",
    marginTop: 35
  },
  pop_next_info_item_1: {},
  pop_btns_upgrade: {
    position: "centerTop",
    width: "100%",
    height: 65,
    // overflow: "hidden",
    marginTop: 330
  },
  pop_btns_upgrade_con: {
    position: "centerTop",
    width: "100%",
    height: "100%"
  },
  pop_btns_upgrade_bg: {
    scale: 0.3,
    position: "center"
  },
  pop_btns_upgrade_main: {
    position: "center",
    textAlign: "center"
  },
  pop_btns_upgrade_label: {
    position: "center",
    marginTop: -12,
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  pop_btns_upgrade_value: {
    marginTop: 8,
    position: "center",
    height: 25,
    marginLeft: 50
  },
  pop_btns_upgrade_value_label: {
    ..._v.fs_fontStyle({
      fz: 21,
      st: 2,
      ds: 1,
      fill: "#eeea69"
    })
  },
  pop_btns_upgrade_icon: {
    scale: 0.22,
    marginTop: 2
  },
  pop_btns_upgradeStar: {
    position: "right",
    width: "100%",
    height: 65,
    overflow: "hidden"
  },
  pop_btns_upgradeStar_bg: {
    scale: 0.3,
    position: "center"
  },
  pop_btns_upgradeStar_label: {
    position: "center",
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  pop_load: {
    position: "centerTop",
    marginTop: 360,
    anchorX: 0,
    visible: false
  }
};