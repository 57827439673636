var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-prompt', {
    ref: "popPromptSwitchTipRef",
    attrs: {
      "isBtn": false,
      "content": "Switching...",
      "show": _vm.popPromptSwitchTipShow,
      "domName": "popPromptSwitchTipDom"
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptSwitchTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };