import * as PIXI from "pixi.js";
class PixiAssetManager {
  constructor(dbName = "goldminerDB") {
    this.dbName = dbName;
    this.db = null;
    this.version = localStorage.getItem("version")
  }

  openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);
      this.opened = true;

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore("assets", { keyPath: "name" });
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  async storeAsset(name, data) {
    return new Promise((resolve, reject) => {
      resolve()
    })
    // if (!this.db) {
    //   this.db = await this.openDatabase();
    // }
    // return new Promise((resolve, reject) => {
    //   const transaction = this.db.transaction("assets", "readwrite");
    //   const store = transaction.objectStore("assets");
    //   const request = store.put({ name, data });
    //
    //   request.onsuccess = () => {
    //     // console.log(`Stored asset ${name} successfully.`);
    //     resolve();
    //   };
    //
    //   request.onerror = (event) => {
    //     // console.error(`Failed to store asset ${name}:`, event.target.error);
    //     reject(event.target.error);
    //   };
    // });
  }

  async getAsset(name) {
    return new Promise((resolve, reject) => {
      resolve()
    })
    // if (!this.db) {
    //   this.db = await this.openDatabase();
    // }
    // return new Promise((resolve, reject) => {
    //   const transaction = this.db.transaction("assets", "readonly");
    //   const store = transaction.objectStore("assets");
    //   const request = store.get(name);
    //
    //   request.onsuccess = (event) => {
    //     // if (event.target.result) {
    //     //   console.log(`Retrieved asset ${name} from database.`);
    //     // } else {
    //     //   console.warn(`Asset ${name} not found in database.`);
    //     // }
    //     resolve(event.target.result ? event.target.result.data : null);
    //   };
    //
    //   request.onerror = (event) => {
    //     // console.error(`Failed to retrieve asset ${name}:`, event.target.error);
    //     reject(event.target.error);
    //   };
    // });
  }

  getAssetUrl(url){
    return `${url}?v=${this.version}`
  }

  async loadAndCacheAsset(name, url, onProgress) {
    let assetData = await this.getAsset(name);

    if (!assetData) {
      // console.log(`Fetching asset ${name} from URL: ${url}`);
      const response = await fetch(this.getAssetUrl(url));
      const blob = await response.blob();
      const reader = new FileReader();

      assetData = await new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

      await this.storeAsset(name, assetData);
    }

    const texture = await this.base64ToTexture(assetData);
    PIXI.Assets.cache.set(name, texture);

    if (onProgress) {
      onProgress(100, "Loading Resources...");
    }
  }

  async removeAsset() {
    // let request = await indexedDB.deleteDatabase(this.dbName);
    // console.log("request", request);
    //
    // request.onsuccess = function () {
    //   console.log("Database deleted successfully");
    // };
    //
    // request.onerror = function (event) {
    //   console.log("Error deleting database.");
    // };
    //
    // request.onblocked = function () {
    //   console.log("Database delete blocked.");
    // };
  }

  async base64ToTexture(base64) {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = base64;
      image.onload = () => {
        const texture = PIXI.Texture.from(image);
        resolve(texture);
      };
    });
  }

  async loadAndCacheSpritesheet(name, jsonUrl, imageUrl, onProgress) {
    let assetData = await this.getAsset(name);

    if (!assetData) {
      // console.log(`Fetching spritesheet ${name} from URLs: ${jsonUrl}, ${imageUrl}`);
      const jsonResponse = await fetch(this.getAssetUrl(jsonUrl));
      const json = await jsonResponse.json();

      const imageResponse = await fetch(this.getAssetUrl(imageUrl));
      const imageBlob = await imageResponse.blob();
      const imageReader = new FileReader();

      const imageData = await new Promise((resolve) => {
        imageReader.onloadend = () => {
          resolve(imageReader.result);
        };
        imageReader.readAsDataURL(imageBlob);
      });

      json.meta.image = imageData;
      assetData = JSON.stringify(json);

      await this.storeAsset(name, assetData);
    }

    const spritesheetData = JSON.parse(assetData);
    const texture = await this.base64ToTexture(spritesheetData.meta.image);
    const spritesheet = new PIXI.Spritesheet(texture, spritesheetData);

    await spritesheet.parse();
    PIXI.Assets.cache.set(name, spritesheet);
  }

  async loadAndCacheSequence(name, urls, onProgress) {
    let textures = [];
    let sequenceData = await this.getAsset(name);

    if (!sequenceData) {
      // console.log(`Fetching sequence ${name} from URLs: ${urls}`);
      sequenceData = [];
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        const response = await fetch(this.getAssetUrl(url));
        const blob = await response.blob();
        const reader = new FileReader();

        const assetData = await new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });

        sequenceData.push(assetData);
        if (onProgress) {
          onProgress((i / urls.length) * 100, "Loading Resources...");
        }
      }

      await this.storeAsset(name, JSON.stringify(sequenceData));
    } else {
      sequenceData = JSON.parse(sequenceData);
    }

    for (const data of sequenceData) {
      const texture = await this.base64ToTexture(data);
      textures.push(texture);
    }

    PIXI.Assets.cache.set(name, textures);
  }

  async loadAndCacheBundle(name, bundle, onProgress) {
    let bundleData = await this.getAsset(name);

    if (!bundleData || Object.keys(bundleData).length === 0) {
      // console.log(`Fetching bundle ${name} from bundle object`);
      if (!bundle || !bundle.assets || !Array.isArray(bundle.assets)) {
        // console.error('Invalid bundle format:', bundle);
        return;
      }

      try {
        await PIXI.Assets.loadBundle(bundle, (progress) => {
          if (onProgress) {
            onProgress(progress * 100, "Loading Resources...");
          }
        });

        const cachedAssets = {};
        for (const asset of bundle.assets) {
          const texture = PIXI.Assets.cache.get(asset.name);
          if (texture && texture.baseTexture && texture.baseTexture.resource && texture.baseTexture.resource.source) {
            const base64 = await this.textureToBase64(texture);
            cachedAssets[asset.name] = base64;
          } else {
            // console.warn(`Texture for asset ${asset.name} is not properly loaded.`);
          }
        }

        await this.storeAsset(name, JSON.stringify(cachedAssets));
        bundleData = cachedAssets;
      } catch (error) {
        // console.error('Error loading bundle:', error);
        return;
      }
    } else {
      // console.log(`Using cached bundle ${name}`);
      bundleData = JSON.parse(bundleData);
    }

    if (bundleData && typeof bundleData === "object") {
      for (const [assetName, base64] of Object.entries(bundleData)) {
        const texture = await this.base64ToTexture(base64);
        PIXI.Assets.cache.set(assetName, texture);
      }
    } else {
      // console.error('bundleData is not a valid object:', bundleData);
    }
  }

  async textureToBase64(texture) {
    return new Promise((resolve, reject) => {
      if (texture && texture.baseTexture && texture.baseTexture.resource && texture.baseTexture.resource.source) {
        const canvas = document.createElement("canvas");
        canvas.width = texture.width;
        canvas.height = texture.height;
        const context = canvas.getContext("2d");
        context.drawImage(texture.baseTexture.resource.source, 0, 0);
        resolve(canvas.toDataURL());
      } else {
        reject("Texture is not properly loaded");
      }
    });
  }

  getCachedAsset(name) {
    return PIXI.Assets.cache.get(name);
  }
}

// function closeAllConnections(dbName) {
//   return new Promise((resolve, reject) => {
//     const openRequest = indexedDB.open(dbName);
//
//     openRequest.onsuccess = function(event) {
//       const db = event.target.result;
//
//       db.onversionchange = function() {
//         db.close();
//         console.log("Database connection closed due to version change");
//       };
//
//       // 强制关闭数据库
//       db.close();
//
//       // 验证数据库是否已关闭
//       if (db.readyState === "closed") {
//         console.log("Database is closed");
//         resolve(1);
//       } else {
//         console.log("Database is not closed");
//         resolve();
//       }
//     };
//
//     openRequest.onerror = function(event) {
//       reject(event.target.error);
//     };
//   });
// }

export default PixiAssetManager;
