import Vue from "vue";
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
import { Input } from "@pixi/ui";
export default {
  name: "popPrompt",
  props: {
    title: {
      typeof: String,
      default: "Hint"
    },
    isCallback: {
      typeof: {
        typeof: Boolean,
        default: false
      }
    },
    content: {
      typeof: String,
      default: "Are you sure you want to do this?"
    },
    btnText: {
      typeof: String,
      default: "Confirm"
    },
    show: Boolean,
    btnType: {
      typeof: String,
      default: "confirm"
    },
    parentDomName: {
      typeof: String,
      default: "pageDom"
    },
    isBtnClose: {
      typeof: Boolean,
      default: true
    },
    domName: {
      typeof: String,
      default: null
    },
    zIndex: {
      typeof: [Number, String],
      default: "15"
    },
    isBtn: {
      typeof: Boolean,
      default: true
    }
  },
  watch: {
    show: {
      handler(bool) {
        // console.log(333, bool);
        if (bool) {
          this.initPop();
          this.setBtnText();
        } else {
          clearTimeout(this.btnLoadingTimer);
          this.btnLoadingTimer = false;
          this.setBtnLoading(false);
        }
      },
      deep: true,
      immediate: true
    },
    btnType: {
      handler(newVal, oldVal) {
        console.log("newVal=====", newVal, oldVal);
        if (newVal != oldVal) {
          this.setBtnText();
        }
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.btnLoadingTimer);
    this.btnLoadingTimer = false;
  },
  data() {
    return {
      popDom: null,
      isBtnLoading: false
    };
  },
  methods: {
    setBtnText() {
      if (!this.$parent[this.domName]) return;
      const btnTextDom = this.$parent[this.domName]?.content?.getByID("pop_prompt_btns_text");
      if (!btnTextDom) return;
      btnTextDom.children[0].text = `${this.btnText}`;
    },
    initPop() {
      if (!this.$parent[this.domName]) {
        this.$parent[this.domName] = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent[this.domName]);
        // this.setZindex();
      }

      console.log("this.zIndexthis.zIndexthis.zIndexthis.zIndex", this.zIndex);
      this.$parent[this.domName].visible = true;
      this.$parent[this.domName].zIndex = this.gameApp.stage.children.length + this.zIndex;
      this.setBtnText();
      this.anim_pop(this.$parent[this.domName]);
    },
    setZindex() {
      // const pop_main = this.pop
      // Dom.getChildByID("pop_main");
      // const pop_prompt_head = this.popDom.getChildByID("pop_prompt_head");
      // const pop_prompt_list = this.popDom.getChildByID("pop_prompt_list");
      // pop_main.sortableChildren = true;
      // pop_prompt_head.zIndex = 2;
      // pop_prompt_list.zIndex = 1;
    },
    setBtnLoading(bool) {
      try {
        const btnDom = this.$parent[this.domName].getChildByID("pop_prompt_box_btn");
        const loadingDom = this.$parent[this.domName].getChildByID("pop_prompt_loading");
        if (bool) {
          btnDom.visible = false;
          loadingDom.visible = true;
        } else {
          btnDom.visible = true;
          loadingDom.visible = false;
        }
      } catch (err) {}
    },
    renderDom() {
      return {
        content: {
          pop_prompt: {
            content: {
              pop_prompt_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_prompt_con: {
                    content: {
                      pop_prompt_main_bg: {
                        content: this.sf_popBg({
                          width: this.screenWidth * 4 - 300,
                          height: 880
                        })
                      },
                      pop_prompt_box: {
                        content: {
                          pop_prompt_main_title: {
                            content: `${this.title}`
                          },
                          pop_prompt_main_content: {
                            content: `${this.content}`
                          }
                        }
                      },
                      pop_prompt_btns: this.isBtn && this.m_btn({
                        id: "pop_prompt_btns",
                        content: {
                          pop_prompt_btns_main: {
                            content: {
                              pop_prompt_box_btn: {
                                content: {
                                  pop_prompt_btns_bg: {
                                    content: this.sf_btnBg({
                                      width: this.screenWidth * 4 - 540,
                                      height: 170
                                    }),
                                    styles: globalStyles.pop_prompt_btns_bg
                                  },
                                  pop_prompt_btns_text: {
                                    content: `${this.btnText}`,
                                    styles: globalStyles.pop_prompt_btns_text
                                  }
                                },
                                styles: globalStyles.pop_prompt_box_btn
                              },
                              pop_prompt_loading: {
                                content: this.c_btnLoading(),
                                styles: {
                                  ...globalStyles.pop_prompt_loading
                                }
                              }
                            }
                          }
                        },
                        styles: globalStyles.pop_prompt_btns
                      }, async () => {
                        if (this.btnType === "check") {
                          await this.$emit("submit");
                          if (this.$parent[this.domName]) {
                            this.$parent[this.domName].visible = false;
                          }
                          this.$emit("update:show", false);
                          return;
                        }
                        this.setBtnLoading(true);
                        this.btnLoadingTimer = setTimeout(async () => {
                          clearTimeout(this.btnLoadingTimer);
                          this.btnLoadingTimer = null;
                          await this.$emit("submit", () => {
                            this.setBtnLoading(false);
                          });
                          // if(!this.isCallback) {
                          //   this.setBtnLoading(false);
                          // }
                        }, 1500);
                      }),
                      pop_prompt_close: this.isBtnClose && {
                        content: this.c_btnClose(async () => {
                          this.setBtnLoading(false);
                          await this.$emit("close");
                          if (this.$parent[this.domName]) {
                            this.$parent[this.domName].visible = false;
                          }
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    }
  }
};
const globalStyles = {
  pop_prompt: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    position: "leftTop",
    marginLeft: 0,
    disply: "block"
  },
  pop_prompt_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_prompt_con: {
    width: _v.screenWidth - 82,
    height: 212,
    position: "center"
    // overflow: "hidden",
  },

  pop_prompt_main_bg: {
    position: "center",
    scale: 0.25
  },
  pop_prompt_box: {
    width: "100%",
    height: "100%",
    padding: 25
  },
  pop_prompt_main_title: {
    position: "centerTop",
    marginTop: 25,
    ..._v.fs_fontStyle({
      fz: 21,
      st: 2,
      ds: 1
    })
    // fontSize: 21,
    // fontFamily: "pFont",
    // align:"center",
    // color:"#fff"
  },

  pop_prompt_main_content: {
    marginTop: -10,
    position: "center",
    width: "80%",
    textAlign: "center",
    height: 65,
    overflow: "hidden",
    fontSize: 18,
    fontFamily: "pFont",
    wordWrap: true,
    // wordWrapWidth: 500,
    whiteSpace: "pre-line",
    fill: "#fff",
    align: "center"
  },
  pop_prompt_btns: {
    position: "centerBottom",
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginBottom: 20
  },
  pop_prompt_box_btn: {
    width: "100%",
    height: "100%",
    position: "center"
  },
  pop_prompt_loading: {
    position: "center",
    marginTop: 10,
    marginLeft: 10,
    visible: false
  },
  pop_prompt_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_prompt_btns_text: {
    marginTop: -3,
    position: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_prompt_close: {
    position: "rightTop",
    marginRight: -20,
    marginTop: 5
  }
};