import Vue from "vue";
import store from "@store/index";

export default function vailCode(rs, callback, errcallback) {
  if(!rs) {
    typeof errcallback == "function" && errcallback(rs);
    return 
  }
  if (rs.code == 0) {
    typeof callback == "function" && callback(rs);
  } else {

    store.commit("game/setDiamondInsufficient", {
      show: false,
      message: "",
    });
    if (rs.code === 401) {
      Vue.toasted.clear();
      Vue.toasted.error(rs.message);
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        location.reload();
      }, 2000);
    } else if (rs.code === 101) {
      store.commit("game/setDiamondInsufficient", {
        show: true,
        message: rs.message,
      });
    }else {
      Vue.toasted.clear();
      Vue.toasted.error(rs.message);
    }
    
    typeof errcallback == "function" && errcallback(rs);
  }
}
