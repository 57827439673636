var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-sell', {
    attrs: {
      "asset": _vm.selectMine,
      "show": _vm.popSellShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSellShow = $event;
      },
      "submit": _vm.popSellSubmit
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };