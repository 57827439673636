import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";

// console.log("input", input);
// console.log("TextInput", TextInput);
export default {
  name: "popSell",
  props: {
    show: Boolean,
    asset: {
      default: () => {},
      typeof: Object
    },
    parentDomName: {
      typeof: String,
      default: "pageDom"
    }
  },
  computed: {
    ...gameVuex.mapState(["gameApp"]),
    ...userVuex.mapState(["accountInfo"])
  },
  watch: {
    "$parent.popSellShow": {
      handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      amount: 0,
      ton: 0
    };
  },
  methods: {
    ...gameVuex.mapActions(["marketAdd", "bindWallet"]),
    initPop() {
      if (!this.$parent.popSellDom) {
        this.$parent.popSellDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popSellDom);
      }
      this.$parent.popSellDom.visible = true;
      this.$parent.popSellDom.zIndex = this.gameApp.stage.children.length + 12;
      this.$parent.popSellDom.position.set(0, 0);
      this.setZindex();
      this.anim_pop(this.$parent.popSellDom);
    },
    inputTonDom(option) {
      const input = this.c_inputx({
        ...option
      });
      return input;
    },
    setZindex() {
      // const pop_main = this.pop
      // Dom.getChildByID("pop_main");
      // const pop_sell_head = this.popDom.getChildByID("pop_sell_head");
      // const pop_sell_list = this.popDom.getChildByID("pop_sell_list");
      // pop_main.sortableChildren = true;
      // pop_sell_head.zIndex = 2;
      // pop_sell_list.zIndex = 1;
    },
    renderDom() {
      return {
        content: {
          pop_sell: {
            content: {
              pop_sell_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_sell_con: {
                    content: {
                      pop_sell_main_bg: {
                        content: this.sf_popBg({
                          width: this.screenWidth * 4 - 300,
                          height: 880
                        })
                      },
                      pop_sell_box: {
                        content: {
                          pop_sell_main_title: {
                            content: "SELL"
                          },
                          pop_sell_input: {
                            content: {
                              pop_sell_input_title: {
                                content: "PRICE TO SELL"
                              },
                              pop_sell_input_main: {
                                content: {
                                  pop_sell_input_main_input: {
                                    content: this.inputTonDom({
                                      change: input => {
                                        if (input.text) {
                                          const regex = /^(?!0\d)\d+(\.\d{0,8})?$/;
                                          console.log("input.text", input.text, regex.test(input.text));
                                          if (!regex.test(input.text)) {
                                            input.text = 0;
                                            this.$toasted.clear();
                                            this.$toasted.error(this.$lang("input error"));
                                            return;
                                          }
                                          this.ton = input.text;
                                        }
                                      }
                                    })
                                    // content: this.c_createPixiInput("123"),
                                  },

                                  pop_sell_input_main_icon: {
                                    content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      pop_sell_btns: this.m_btn({
                        content: {
                          pop_sell_bn: {
                            content: {
                              pop_sell_btns_bg: {
                                content: this.sf_btnBg({
                                  width: _v.screenWidth * 4 - 540,
                                  height: 170
                                }),
                                styles: globalStyles.pop_sell_btns_bg
                              },
                              pop_sell_btns_text: {
                                content: "SELL",
                                styles: globalStyles.pop_sell_btns_text
                              }
                            },
                            styles: globalStyles.pop_sell_bn
                          }
                        }
                      }, () => {
                        this.onMarketAdd();
                      }),
                      pop_load: {
                        content: this.c_btnLoading(),
                        styles: globalStyles.pop_load
                      },
                      pop_sell_close: {
                        content: this.c_btnClose(() => {
                          this.setBtnLoading(false);
                          this.$parent.popSellDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setBtnLoading(bool) {
      const btnDom = this.$parent.popSellDom.getChildByID("pop_sell_bn");
      const loadingDom = this.$parent.popSellDom.getChildByID("pop_load");
      console.log("btnDom", btnDom, loadingDom);
      if (bool) {
        btnDom.visible = false;
        loadingDom.visible = true;
      } else {
        btnDom.visible = true;
        loadingDom.visible = false;
      }
    },
    async onMarketAdd() {
      if (this.ton < 1) {
        return this.$toasted.error(this.$lang("Ton cannot be less than 1"));
      }
      const regex = /^(?!0\d)\d+(\.\d{0,8})?$/;
      if (!regex.test(this.ton)) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("input error"));
        return;
      }
      this.setBtnLoading(true);
      await _v.m_ton();
      if (!this.accountInfo.wallet) {
        await this.bindWallet();
      }
      const params = {
        asset: this.asset.type,
        asset_id: this.asset.id,
        amount: 1,
        ton: this.ton
      };
      const data = await this.marketAdd(params);
      if (!data) {
        this.setBtnLoading(false);
        return;
      }
      this.setBtnLoading(false);
      this.$parent.popSellDom.visible = false;
      this.$emit("update:show", false);
      this.$emit("submit");
    }
  }
};
const globalStyles = {
  pop_task: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_sell_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_sell_con: {
    width: _v.screenWidth - 82,
    height: 212,
    position: "center"
    // overflow: "hidden",
  },

  pop_sell_main_bg: {
    position: "center",
    scale: 0.25
  },
  pop_sell_main_bg_label: {
    width: _v.screenWidth * 4 - 300,
    height: 880,
    leftWidth: 180,
    topHeight: 190,
    rightWidth: 180,
    bottomHeight: 190
  },
  pop_sell_box: {
    width: "100%",
    height: "100%",
    padding: 25
  },
  pop_sell_main_title: {
    position: "centerTop",
    marginTop: 25,
    fill: "#fff",
    fontSize: 21,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1
  },
  pop_sell_input: {
    // marginTop: 0,
    marginTop: 5
  },
  pop_sell_input_title: {
    fill: "#868c94",
    fontSize: 15,
    fontFamily: "pFont"
  },
  pop_sell_input_main: {
    width: "90%",
    // background: "#28313f",
    borderRadius: 15
  },
  pop_sell_input_main_icon: {
    scale: 0.35,
    position: "left",
    marginLeft: 5,
    marginTop: -2,
    background: "#28313f"
  },
  pop_sell_input_main_input: {
    marginTop: -7,
    position: "left"
  },
  pop_sell_btns: {
    position: "centerBottom",
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginBottom: 20
  },
  pop_sell_bn: {
    width: "100%",
    height: "100%"
  },
  pop_sell_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_sell_btns_text: {
    fill: "#fff",
    fontSize: 16,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1,
    position: "center"
  },
  pop_sell_close: {
    position: "rightTop",
    marginRight: -25,
    marginTop: 0
  },
  pop_load: {
    position: "centerTop",
    marginTop: 170,
    anchorX: 0,
    visible: false
  }
};