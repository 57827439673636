import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
const popWidth = _v.screenWidth * 0.9;
const popHeight = 540;
import bignumber from "bignumber.js";
await Assets.load("gem-t");
export default {
  name: "popGemInfo",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo"])
  },
  watch: {
    "$parent.popGemInfoShow": {
      async handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      isMintLoading: null
    };
  },
  loadGEMInfo() {},
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["getGmeMintInfo", "gmeMintFunc"]),
    initPop() {
      if (!this.$parent.popGemInfoDom) {
        this.$parent.popGemInfoDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popGemInfoDom);
      }
      this.$parent.popGemInfoDom.visible = true;
      this.$parent.popGemInfoDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popGemInfoDom.position.set(0, 0);
      this.setZindex();
      this.anim_pop(this.$parent.popGemInfoDom, null, {
        y: _v.screenHeight / 2 - popHeight / 2
      });
    },
    setZindex() {
      const popDom = this.$parent.popGemInfoDom;
      const pop_main = popDom.getChildByID("pop_main");
      const pop_gme_head = popDom.getChildByID("pop_gme_head");
      pop_main.sortableChildren = true;
      pop_gme_head.zIndex = 2;
    },
    renderDom() {
      return {
        content: {
          pop_gme: {
            content: {
              pop_gme_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_gme_head: {
                    content: {
                      pop_gme_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_gme_title: {
                        content: {
                          pop_gme_title_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                          },
                          pop_gme_title_text: {
                            content: "GME"
                          }
                        }
                      },
                      pop_gme_close: {
                        content: this.c_btnClose(() => {
                          this.$parent.popGemInfoDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_gme_con: {
                    content: {
                      pop_gme_con_bg: {
                        content: this.sf_marketGoods({
                          width: this.screenWidth * 4 - 140,
                          height: popHeight * 4
                        })
                      },
                      pop_gme_box: {
                        content: {
                          pop_gme_remaining: {
                            content: {
                              pop_gme_remaining_label: {
                                content: "My GME"
                              },
                              pop_gme_remaining_num: {
                                content: `${this.toPre(this.accountInfo.gme, 0)}`
                              }
                            }
                          },
                          pop_gme_mint_info: {
                            content: {
                              pop_gme_mint_info_label: {
                                content: "GME Economic Model"
                              },
                              pop_gme_mint_info_total: {
                                content: `Total supply: ${this.toPre(10000000000, 0)} GME`
                              },
                              pop_gme_mint_info_img: {
                                content: Sprite.from("gem-t")
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  btn_mint: this.m_btn({
                    content: {
                      btn_mint_box: {
                        content: {
                          btn_mint_bg: {
                            content: this.sf_btnDisableBg({
                              width: this.screenWidth * 4 - 400,
                              height: 170
                            }),
                            styles: globalStyles.btn_mint_bg
                          },
                          btn_mint_main: {
                            content: {
                              btn_mint_text: {
                                content: "WITHDRAW",
                                styles: globalStyles.btn_mint_text
                              }
                            },
                            styles: globalStyles.btn_mint_main
                          }
                        },
                        styles: globalStyles.btn_mint_box
                      },
                      btn_mint_load: {
                        content: this.c_btnLoading(),
                        styles: globalStyles.btn_mint_load
                      }
                    },
                    styles: globalStyles.btn_mint
                  }, () => {
                    // this.$toasted.error(this.$lang(""))
                  })
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setBtnLoading(bool) {
      const btnDom = this.popDom.getChildByID("btn_mint_box");
      const loadingDom = this.popDom.getChildByID("btn_mint_load");
      console.log("btnDom", btnDom, loadingDom);
      if (bool) {
        btnDom.visible = false;
        loadingDom.visible = true;
      } else {
        btnDom.visible = true;
        loadingDom.visible = false;
      }
    },
    async gmeMint() {
      this.setBtnLoading(true);
      const data = await this.gmeMintFunc();
      if (!data) {
        return this.setBtnLoading(false);
      }
      const info = await this.getGmeMintInfo();
      if (!info) {
        return this.setBtnLoading(false);
      }
      this.gmeMintInfo = info;
      await this.getAccountInfo(data);
      console.log("this.$parent", this.$parent);
      this.$parent.changeAssetsDom();
      this.setBtnLoading(false);
    }
  }
};
const globalStyles = {
  pop_gme: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "left"
  },
  pop_gme_mask: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_main: {
    position: "center",
    width: popWidth,
    height: popHeight
    // overflow: "hidden",
  },

  pop_gme_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50
    // marginTop: 60,
  },

  pop_gme_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_gme_con: {
    width: "100%",
    // height: "100%",
    position: "centerTop"
  },
  pop_gme_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_gme_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_gme_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_gme_close: {
    position: "rightTop"
  },
  pop_gme_con: {
    position: "centerTop",
    width: popWidth,
    height: "100%",
    overflow: "hidden"
  },
  pop_gme_con_bg: {
    position: "centerTop",
    scale: 0.25,
    height: popHeight
  },
  pop_gme_box: {
    position: "centerTop",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    marginTop: 70
  },
  pop_gme_remaining: {
    position: "centerTop"
  },
  pop_gme_remaining_label: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontFamily: "pFont",
    color: "#fff",
    height: 20
  },
  pop_gme_remaining_num: {
    marginTop: -8,
    width: "100%",
    height: 40,
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 32,
      ts: 6,
      ds: 2
    })
  },
  pop_gme_mint_info: {
    position: "centerTop",
    marginTop: 80,
    height: 300,
    width: _v.screenWidth - 90,
    overflow: "hidden",
    background: "#27303e",
    borderRadius: 12
  },
  pop_gme_mint_info_label: {
    marginTop: 20,
    width: "100%",
    fontSize: 16,
    color: "#fff",
    fontFamily: "pFontM",
    textAlign: "center"
  },
  pop_gme_mint_info_total: {
    paddingTop: 5,
    width: "100%",
    fontSize: 13,
    color: "#ffffff",
    opacity: 0.5,
    fontFamily: "pFontM",
    textAlign: "center"
  },
  pop_gme_mint_info_img: {
    scale: 0.25,
    marginTop: 40,
    // width: "100%",
    position: "centerTop"
  },
  btn_mint: {
    position: "centerBottom",
    width: _v.screenWidth - 100,
    marginBottom: 30,
    height: 40
  },
  btn_mint_box: {
    width: "100%",
    height: "100%",
    position: "centerTop"
  },
  btn_mint_bg: {
    position: "center",
    scale: 0.25
  },
  btn_mint_main: {
    position: "center",
    width: "100%"
  },
  btn_mint_load: {
    position: "center",
    marginLeft: 12,
    marginTop: 15,
    visible: false
  },
  btn_mint_text: {
    marginTop: -2,
    position: "center",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 16,
      fill: "#abadb0"
    })
  }
};