import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane, Point, Texture, ProgressBar } from "pixi.js";
await Assets.loadBundle("index");
await _v.m_loadSpritesheet("/assets/spriteSheet/", ["head"]);
await Assets.loadBundle("mine");
await Assets.load("guide-hand");
const index_mine_coins = new Sprite(_v.m_getRes("coin").textures["0.png"]);
const index_build_2_car = _v.m_setAnmationsSprite("car", {
  isPlay: true,
  animationSpeed: 0.2,
  loop: false
});
const {
  progressBar,
  progressText
} = _v.c_progress({
  width: 200,
  height: 18,
  radio: 46,
  progress: 100
});
const index_mine_progress = progressBar;
const index_mine_text = progressText;
const build_1 = Sprite.from("build_1");
build_1.anchor.set(0.5, 0.5);
export default {
  name: "index",
  provide() {
    return {
      setPageMinerDetailShow: this.setPageMinerDetailShow,
      setPageRecruitShow: this.setPageRecruitShow,
      setPageMinerShow: this.setPageMinerShow,
      changeMinerDom: this.changeMinerDom,
      changeScreenDom: this.changeScreenDom,
      closePage: this.closePage,
      setPageMarketShow: this.setPageMarketShow,
      setPageMineShow: this.setPageMineShow,
      changeStroneDom: this.changeStroneDom
    };
  },
  computed: {
    ...gameVuex.mapState(["gameApp", "diamondInsufficient", "myMineListPage", "myMineList"]),
    ...userVuex.mapState(["accountInfo", "coinInfo", "gameInfo"]),
    isRender() {
      return this.gameApp;
    },
    enableConfirm() {
      return this.isBeforeDestroyRequest;
    }
  },
  watch: {
    isRender: {
      async handler(val) {
        if (val) {
          // await this.getAccountInfo();
          this.initScreen();
        }
      },
      immediate: true
    },
    "coinInfo.produceTotalNum": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          const accountInfo = this.accountInfo;
          const coinNum = parseInt(newVal / accountInfo.store_max * 7);
          if (newVal / accountInfo.store_max <= 0.01) {
            index_mine_coins.texture = this.m_getRes("coin").textures[`0.png`];
          } else {
            index_mine_coins.texture = this.m_getRes("coin").textures[`${coinNum + 1}.png`];
          }
          console.log("this.accountInfo.mine.type_id", this.accountInfo.mine.type_id);
          if (this.accountInfo.mine.type_id === 0) {
            index_build_2_car.play();
          } else {
            index_build_2_car.stop();
          }
          index_mine_progress.progress = Number(parseInt(newVal / accountInfo.store_max * 100));
          index_mine_text.text = `${this.toPre(newVal, 0)}/${this.toPre(accountInfo.store_max, 0)}`;
          // console.log("当前仓库剩余总量: ", this.coinInfo.produceTotalNum, " 当前收取总量: ", this.coinInfo.colletTotalNum, " 每次点击数量:", accountInfo.tap_amount, " 每秒产出率：", accountInfo.power);
        }
      },

      deep: true,
      immediate: true
    },
    "coinInfo.colletTotalNum": {
      handler(n, o) {
        const accountCoinNum = this.accountInfo.coin + this.coinInfo.colletTotalNum;
        this.$refs.assetsInfo.assets_icon_main_coin.progress = accountCoinNum / this.accountInfo.gme_fee * 100;
        this.$refs.assetsInfo.assets_icon_main_coin_text.text = `${this.toPre(accountCoinNum, 0)}`;
      },
      deep: true
    },
    "accountInfo.coin": {
      handler(num) {
        const accountCoinNum = this.accountInfo.coin + this.coinInfo.colletTotalNum;
        this.$refs.assetsInfo.assets_icon_main_coin.progress = accountCoinNum / this.accountInfo.gme_fee * 100;
        this.$refs.assetsInfo.assets_icon_main_coin_text.text = `${this.toPre(accountCoinNum, 0)}`;
      },
      deep: true
    },
    pageMineShow(bool) {
      if (!bool) {
        this.$refs.assetsInfo.renderDom({
          diamond: true,
          type: "refresh"
        });
      }
    },
    pageRecruitShow(bool) {
      if (!bool) {
        this.$refs.assetsInfo.renderDom({
          diamond: true,
          coin: true,
          type: "refresh"
        });
      }
    },
    pageDiamondShow(bool) {
      if (!bool) {
        this.$refs.assetsInfo.renderDom({
          diamond: true,
          type: "refresh"
        });
      }
    },
    pageMinerDetailShow(bool) {
      if (!bool) {
        this.$refs.assetsInfo.renderDom({
          coin: true,
          type: "refresh"
        });
      }
    },
    popTaskShow(bool) {
      if (!bool) {
        this.$refs.assetsInfo.renderDom({
          coin: true,
          diamond: true,
          gme: true,
          type: "refresh"
        });
      }
    },
    pageUpgradeStarShow(bool) {
      if (!bool) {
        // this.$refs.assetsInfo.renderDom({ coin: true, diamond: true, gme: true, type: "refresh" });
      }
    },
    pageMarketShow(bool) {
      if (!bool) {
        this.$refs.assetsInfo.renderDom({
          coin: true,
          diamond: true,
          gme: true,
          type: "refresh"
        });
      }
    },
    "diamondInsufficient.show": {
      handler(bool) {
        if (bool) {
          this.popInsufficientBalanceShow = bool;
          this.popInsufficientBalanceContent = this.diamondInsufficient.message;
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      currentScreen: null,
      pageDom: null,
      timer: null,
      popTaskShow: false,
      popRankingShow: false,
      popSelectMinerShow: false,
      selectPosition: 0,
      popGemMintShow: false,
      popUpgradeStorage: false,
      popGemInfoShow: false,
      coinAnimNum: 0,
      coinAnimList: [],
      popSettingShow: false,
      caclTimeVal: null,
      isBeforeDestroyRequest: false,
      pageInviteShow: false,
      pageMineShow: false,
      pageRecruitShow: false,
      pageDiamondShow: false,
      pageMinerShow: false,
      pageMinerDetailShow: false,
      pageMarketShow: false,
      pageUpgradeStarShow: false,
      popTaskDom: null,
      popUpgradeStorageDom: null,
      popSettingDom: null,
      popSelectMinerDom: null,
      popRankingDom: null,
      pageMinerEntrance: false,
      popBuyPositionOption: {
        show: false,
        content: ""
      },
      popBuyPositionDom: null,
      popInsufficientBalanceShow: false,
      popInsufficientBalanceDom: null,
      popSwitchMineDom: null,
      popSwitchMineShow: false,
      popInsufficientBalanceContent: ""
    };
  },
  beforeRouteLeave(to, from, next) {
    let timer = setTimeout(async () => {
      clearTimeout(timer);
      timer = null;
      clearTimeout(this.caclTimeVal);
      this.caclTimeVal = null;
      if (this.isBeforeDestroyRequest) {
        await this.getAccountInfo();
        console.log("离开首页,请求/account/collect(防止点击等待2秒过程中直接跳页为请求到接口), 当前点击收取金币数量: ", parseInt(this.coinInfo.colletTotalNum));
      } else {
        console.log("离开首页");
      }
      next();
    }, 0);
  },
  methods: {
    ...gameVuex.mapMutations(["setSelectMinerInfo", "setTotalCoin", "setDiamondInsufficient"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["getMyMineList", "addMinePosition"]),
    closePage() {
      if (_v["page_invite"]) {
        this.pageInviteShow = false;
        _v["page_invite"].visible = false;
      }
      if (_v["page_mine"]) {
        this.pageMineShow = false;
        _v["page_mine"].visible = false;
      }
      if (_v["page_recruit"]) {
        this.pageRecruitShow = false;
        _v["page_recruit"].visible = false;
      }
      if (_v["page_payDiamond"]) {
        this.pageDiamondShow = false;
        _v["page_payDiamond"].visible = false;
      }
      if (_v["page_miner"]) {
        this.pageMinerShow = false;
        _v["page_miner"].visible = false;
      }
      if (_v["page_minerDetail"]) {
        this.pageMinerDetailShow = false;
        _v["page_minerDetail"].visible = false;
      }
      if (_v["page_upgrade"]) {
        this.pageUpgradeStarShow = false;
        _v["page_upgrade"].visible = false;
      }
      if (this.popBuyPositionDom) {
        this.popBuyPositionDom.visible = false;
        this.popBuyPositionOption.show = false;
      }
      if (this.popTaskDom) {
        this.popTaskDom.visible = false;
        this.popTaskShow = false;
      }
      if (this.popRankingDom) {
        this.popRankingDom.visible = false;
        this.popRankingShow = false;
      }
      if (this.$refs.pageMineRef) {
        const pageMineRef = this.$refs.pageMineRef;
        if (pageMineRef.popBuyMineDom) {
          pageMineRef.popBuyMineDom.visible = false;
          pageMineRef.popBuyPromptOption.show = false;
        }
      }
    },
    setPageMinerDetailShow(bool) {
      if (_v["page_minerDetail"]) {
        _v["page_minerDetail"].visible = bool;
      }
      this.pageMinerDetailShow = bool;
    },
    setPageMinerShow(bool) {
      if (_v["page_miner"]) {
        _v["page_miner"].visible = bool;
      }
      this.pageMinerShow = bool;
    },
    setPageMarketShow(bool) {
      if (_v["page_market"]) {
        _v["page_market"].visible = bool;
      }
      this.pageMarketShow = bool;
    },
    setPageRecruitShow(bool) {
      if (_v["page_recruit"]) {
        _v["page_recruit"].visible = bool;
      }
      this.pageRecruitShow = bool;
    },
    setPageMineShow(bool) {
      if (_v["page_mine"]) {
        _v["page_mine"].visible = bool;
      }
      this.pageMineShow = bool;
    },
    initScreen() {
      this.$nextTick(async () => {
        this.coinAnimNum = parseInt(this.accountInfo.store_coin / this.accountInfo.store_max * 50);
        await this.setScreen();
      });
    },
    changeScreenDom() {
      if (_v[`page_index`]) {
        const index_mine_power = _v[`page_index`].content.getByID("index_mine_power");
        index_mine_power.children[0].text = `Power: ${this.toPre(this.accountInfo.power * this.powerTime, 0)}/h`;
        const index_build = _v[`page_index`].content.getByID("index_build").content;
        for (let i = 0; i < 4; i++) {
          const buildItem = index_build.getByID(`index_build_bg_${i + 1}`).children[0];
          buildItem.texture = this.m_getRes(this.setMine(this.accountInfo.mine.type_id, "goldmine")[i]);
        }
        const index_bg = _v[`page_index`].content.getByID("index_bg").children[0];
        index_bg.texture = this.m_getRes(this.setMine(this.accountInfo.mine.type_id, "indexBg"));
      }
    },
    changeStroneDom() {
      index_mine_progress.progress = Number(parseInt(this.accountInfo.store_coin / this.accountInfo.store_max * 100));
      index_mine_text.text = `${this.toPre(this.accountInfo.store_coin, 0)}/${this.toPre(this.accountInfo.store_max, 0)}`;
    },
    changeMinerDom() {
      if (_v[`page_index`]) {
        const index_mine_power = _v[`page_index`].content.getByID("index_mine_power");
        index_mine_power.children[0].text = `Power: ${this.toPre(this.accountInfo.power * this.powerTime, 0)}/h`;
        const index_miner = _v[`page_index`].content.getByID("index_miner").content;
        console.log("index_miner=====", index_miner);
        index_miner.createContent({
          content: {
            index_miner_box: {
              content: this.getMinerInfo()
            }
          }
        });
        index_miner.removeContent(index_miner.children.entries().next().value[0]);
      }
    },
    async setScreen() {
      if (window["loadingPage"]) {
        window["loadingPage"].destroy();
        this.gameApp.stage.removeChild(window["loadingPage"]);
      }
      if (!_v[`page_index`]) {
        _v[`page_index`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_index`]);
      } else {
        _v[`page_index`].visible = true;
      }
      this.pageDom = _v[`page_index`];
      this.setZindex(this.pageDom);
      this.currentScreen = this.pageDom;
      this.gameApp.stage.addChild(this.pageDom);
      if (this.accountInfo.init === 0) {
        this.m_getGuideInfo(this.pageDom, "index_mine_build", {
          step: "step0",
          stage: 0,
          key: "coordinate"
        });
      } else {
        this.m_getGuideInfo(this.pageDom, "index_navs_recruit", {
          step: "step1",
          stage: 0,
          key: "coordinate"
        });
      }
      await this.showMineBtn();
    },
    async showMineBtn() {
      const pageDom = _v[`page_index`];
      if (!pageDom) return;
      const index_btn_mine = pageDom.content.getByID("index_btn_mine");
      const data = await this.getMyMineList({
        perPage: this.myMineListPage.perPage,
        page: this.myMineListPage.page,
        status: 1
      });
      if (!data) return;
      index_btn_mine.visible = this.myMineList.length > 1;
    },
    setZindex() {
      const index_page = this.pageDom.getChildByID("index_page");
      const index_assets = this.pageDom.getChildByID("index_assets");
      const index_navs = this.pageDom.getChildByID("index_navs");
      const index_menu = this.pageDom.getChildByID("index_menu");
      const index_build = this.pageDom.getChildByID("index_build");
      const index_mine = this.pageDom.getChildByID("index_mine");
      const index_miner = this.pageDom.getChildByID("index_miner");
      const index_btn_mine = this.pageDom.getChildByID("index_btn_mine");
      index_page.sortableChildren = true;
      index_build.zIndex = 1;
      index_mine.zIndex = 3;
      index_assets.zIndex = 5;
      index_navs.zIndex = 5;
      index_menu.zIndex = 5;
      index_btn_mine.zIndex = 6;
      index_miner.zIndex = 2;
    },
    renderDom() {
      return {
        content: {
          index_page: {
            content: {
              index_bg: {
                content: Sprite.from(this.setMine(this.accountInfo.mine.type_id, "indexBg"))
              },
              index_assets: {
                content: this.$refs.assetsInfo.renderDom({
                  type: "dom",
                  coin: true,
                  diamond: true,
                  gme: true
                })
              },
              index_navs: {
                content: {
                  index_navs_task: this.m_btn({
                    content: {
                      index_navs_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["icon-menu-bg.png"]),
                        styles: globalStyles.index_navs_bg
                      },
                      index_navs_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-menu-task.png"]),
                        styles: globalStyles.index_navs_icon
                      },
                      index_navs_text: {
                        content: "TASK",
                        styles: globalStyles.index_navs_text
                      }
                    },
                    styles: globalStyles.index_navs_task
                  }, () => {
                    this.popTaskShow = true;
                  }),
                  index_navs_miner: this.m_btn({
                    content: {
                      index_navs_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["icon-menu-bg.png"]),
                        styles: globalStyles.index_navs_bg
                      },
                      index_navs_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-menu-miner.png"]),
                        styles: globalStyles.index_navs_icon
                      },
                      index_navs_text: {
                        content: "MINER",
                        styles: globalStyles.index_navs_text
                      }
                    },
                    styles: globalStyles.index_navs_miner
                  }, () => {
                    this.pageMinerShow = true;
                  }),
                  index_navs_recruit: {
                    content: {
                      index_navs_main: this.m_btn({
                        content: {
                          index_navs_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["icon-menu-bg.png"]),
                            styles: globalStyles.index_navs_bg
                          },
                          index_navs_icon: {
                            content: Sprite.from(this.m_getRes("element").textures["icon-menu-recruit.png"]),
                            styles: globalStyles.index_navs_icon
                          },
                          index_navs_text: {
                            content: "RECRUIT",
                            styles: globalStyles.index_navs_text
                          }
                        },
                        styles: globalStyles.index_navs_recruit_main
                      }, () => {
                        this.pageRecruitShow = true;
                      })
                    },
                    styles: globalStyles.index_navs_recruit
                  },
                  index_navs_ranking: this.m_btn({
                    content: {
                      index_navs_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["icon-menu-bg.png"]),
                        styles: globalStyles.index_navs_bg
                      },
                      index_navs_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-menu-ranking.png"]),
                        styles: globalStyles.index_navs_icon
                      },
                      index_navs_text: {
                        content: "RANKING",
                        styles: globalStyles.index_navs_text
                      }
                    },
                    styles: globalStyles.index_navs_ranking
                  }, () => {
                    this.popRankingShow = true;
                  }),
                  index_navs_warehouse: this.m_btn({
                    content: {
                      index_navs_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["icon-menu-bg.png"]),
                        styles: globalStyles.index_navs_bg
                      },
                      index_navs_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-menu-warehouse.png"]),
                        styles: globalStyles.index_navs_icon
                      },
                      index_navs_text: {
                        content: this.$lang("STORAGE"),
                        styles: globalStyles.index_navs_text
                      }
                    },
                    styles: globalStyles.index_navs_warehouse
                  }, () => {
                    this.popUpgradeStorage = true;
                  }),
                  index_navs_setting: this.m_btn({
                    content: {
                      index_navs_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["icon-menu-bg.png"]),
                        styles: globalStyles.index_navs_bg
                      },
                      index_navs_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-menu-set.png"]),
                        styles: globalStyles.index_navs_icon
                      },
                      index_navs_text: {
                        content: this.$lang("SETTING"),
                        styles: globalStyles.index_navs_text
                      }
                    },
                    styles: globalStyles.index_navs_setting
                  }, () => {
                    this.popSettingShow = true;
                  })
                }
              },
              index_menu: {
                content: {
                  index_menu_bg: {
                    content: Sprite.from("nav-bg")
                  },
                  index_menu_main: {
                    content: {
                      index_menu_item_mine: this.m_btn({
                        content: {
                          index_menu_item_bg: {
                            content: Sprite.from("nav-item-bg"),
                            styles: globalStyles.index_menu_item_bg
                          },
                          index_menu_item_icon: {
                            content: Sprite.from(_v.m_getRes("element").textures["icon-nav-mine.png"]),
                            styles: globalStyles.index_menu_item_icon
                          },
                          index_menu_item_text: {
                            content: "MINE",
                            styles: globalStyles.index_menu_item_text
                          }
                        },
                        styles: globalStyles.index_menu_item_mine
                      }, () => {
                        this.pageMineShow = true;
                      }),
                      index_menu_item_market: this.m_btn({
                        content: {
                          index_menu_item_bg: {
                            content: Sprite.from("nav-item-bg"),
                            styles: globalStyles.index_menu_item_bg
                          },
                          index_menu_item_icon: {
                            content: Sprite.from(_v.m_getRes("element").textures["icon-nav-market.png"]),
                            styles: globalStyles.index_menu_item_icon
                          },
                          index_menu_item_text: {
                            content: "MARKET",
                            styles: globalStyles.index_menu_item_text
                          }
                        },
                        styles: globalStyles.index_menu_item_market
                      }, () => {
                        this.pageMarketShow = true;
                        // this.onMenuClick("/market");
                      }),

                      index_menu_item_invite: this.m_btn({
                        content: {
                          index_menu_item_bg: {
                            content: Sprite.from("nav-item-bg"),
                            styles: globalStyles.index_menu_item_bg
                          },
                          index_menu_item_icon: {
                            content: Sprite.from(_v.m_getRes("element").textures["icon-nav-invite.png"]),
                            styles: globalStyles.index_menu_item_icon
                          },
                          index_menu_item_text: {
                            content: "INVITE",
                            styles: globalStyles.index_menu_item_text
                          }
                        },
                        styles: globalStyles.index_menu_item_invite
                      }, () => {
                        this.pageInviteShow = true;
                      })
                    }
                  }
                }
              },
              index_build: {
                content: {
                  index_build_1: {
                    content: {
                      index_build_bg_1: {
                        content: Sprite.from(this.setMine(this.accountInfo.mine.type_id, "goldmine")[0]),
                        styles: globalStyles.index_build_bg
                      }
                    }
                  },
                  index_build_2: {
                    content: {
                      index_build_bg_2: {
                        content: Sprite.from(this.setMine(this.accountInfo.mine.type_id, "goldmine")[1]),
                        styles: globalStyles.index_build_bg
                      },
                      index_build_2_car: {
                        content: index_build_2_car
                      }
                    }
                  },
                  index_build_3: {
                    content: {
                      index_build_bg_3: {
                        content: Sprite.from(this.setMine(this.accountInfo.mine.type_id, "goldmine")[2]),
                        styles: globalStyles.index_build_bg
                      }
                    }
                  },
                  index_build_4: {
                    content: {
                      index_build_bg_4: {
                        content: Sprite.from(this.setMine(this.accountInfo.mine.type_id, "goldmine")[3]),
                        styles: globalStyles.index_build_bg
                      }
                    }
                  }
                }
              },
              index_mine: {
                content: {
                  index_mine_main: this.m_storeBtn({
                    content: {
                      index_mine_build: {
                        content: build_1,
                        styles: globalStyles.index_mine_build
                      },
                      index_mine_coins: {
                        content: index_mine_coins,
                        styles: globalStyles.index_mine_coins
                      },
                      index_mine_progress: {
                        content: index_mine_progress,
                        styles: globalStyles.index_mine_progress
                      },
                      index_mine_power: {
                        content: `Power: ${this.toPre(this.accountInfo.power * this.powerTime, 0)}/h`,
                        styles: globalStyles.index_mine_power
                      }
                    },
                    styles: globalStyles.index_mine
                  }, e => {
                    this.collectCoin(e);
                  })
                }
              },
              index_miner: {
                content: {
                  index_miner_box: {
                    content: this.getMinerInfo()
                  }
                }
              },
              index_btn_mine: {
                content: {
                  index_btn_mine_bg: {
                    content: {
                      index_btn_mine_bg_main: {
                        content: this.sf_btnSecondBg({
                          width: 150 * 4,
                          height: 40 * 4
                        }),
                        styles: globalStyles.index_btn_mine_bg_main
                      }
                    },
                    styles: globalStyles.index_btn_mine_bg
                  },
                  index_btn_mine_text: this.m_btn({
                    content: "Manage Mines",
                    styles: globalStyles.index_btn_mine_text
                  }, () => {
                    this.popSwitchMineShow = true;
                    if (this.popSwitchMineDom) {
                      this.popSwitchMineDom.visible = true;
                    }
                  })
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    getMinerInfo() {
      let mineList = [];
      let cap = this.accountInfo?.mine?.cap || 0;
      let type_id = this.accountInfo?.mine?.type_id;
      let minePositionPrice = this.gameInfo.minePositionPrice;
      let current_type_id = null;
      let currentMinePositionPrice = null;
      for (const key in minePositionPrice) {
        if (type_id == key) {
          current_type_id = key;
          currentMinePositionPrice = minePositionPrice[key];
        }
      }
      let max = currentMinePositionPrice.max;
      let miners = this.accountInfo?.miners;
      for (let i = 1; i <= max; i++) {
        const minerType = i == 2 || i == 4 ? globalStyles.miner_anim_2 : globalStyles.miner_anim;
        const miner = miners.filter(itm => i === itm.position);
        if (miner.length > 0) {
          const minerItem = miner[0];
          mineList[`miner_${i}`] = this.m_btn({
            content: {
              index_miner_main: {
                content: {
                  miner_anim: {
                    content: this.m_setSpineAnmationsSprite(`${this.setMiner(minerItem.star, "img")}-work`, {
                      loop: true,
                      isPlay: true,
                      animationSpeed: minerItem.star == 1 && 0.3 || minerItem.star == 2 && 0.34 || minerItem.star == 3 && 0.38 || minerItem.star == 4 && 0.42
                    }),
                    styles: minerType
                  },
                  styles: globalStyles.miner_anim
                },
                styles: globalStyles.index_miner_main
              }
            },
            styles: globalStyles[`miner_${i}`]
          }, () => {
            console.log("minerItem", minerItem);
            this.setSelectMinerInfo(minerItem);
            this.$nextTick(() => {
              this.pageMinerDetailShow = true;
              // this.$router.push(`/minerDetail?id=${minerItem.id}`);
            });
          });
        } else {
          if (i <= cap) {
            let cur = {};
            cur[`index_miner_add_${i}`] = {
              content: this.m_btn({
                content: {
                  index_miner_add_main: {
                    content: Sprite.from(this.m_getRes("head").textures["head-bg.png"]),
                    styles: globalStyles.index_miner_add_main
                  }
                }
              }, () => {
                this.selectPosition = i;
                this.popSelectMinerShow = true;
              }),
              styles: globalStyles[`index_miner_add_${i}`]
            };
            mineList[`miner_${i}`] = {
              content: {
                index_miner_add: cur
              },
              styles: globalStyles[`miner_${i}`]
            };
          } else {
            let cur = {};
            cur[`index_miner_add_${i}`] = {
              content: this.m_btn({
                content: {
                  index_miner_add_main: {
                    content: Sprite.from(this.m_getRes("head").textures["head-bg.png"]),
                    styles: globalStyles.index_miner_add_main
                  },
                  index_miner_add_main_lock: {
                    content: Sprite.from(this.m_getRes("element").textures["icon-lock.png"]),
                    styles: globalStyles.index_miner_add_main_lock
                  },
                  index_miner_add_main_price: {
                    content: {
                      index_miner_add_main_price_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-diamond.png"]),
                        styles: globalStyles.index_miner_add_main_price_icon
                      },
                      index_miner_add_main_price_label: {
                        content: `${currentMinePositionPrice.diamond}`,
                        styles: globalStyles.index_miner_add_main_price_label
                      }
                    },
                    styles: globalStyles.index_miner_add_main_price
                  }
                }
              }, () => {
                this.popBuyPositionOption.content = this.$lang(`Spend ${currentMinePositionPrice.diamond} diamonds to buy this spot?`);
                this.popBuyPositionOption.show = true;
              }),
              styles: {
                ...globalStyles[`index_miner_add_${i}`],
                visible: i - cap === 1
              }
            };
            mineList[`miner_${i}`] = {
              content: {
                index_miner_add: cur
              },
              styles: globalStyles[`miner_${i}`]
            };
          }
        }
      }
      return {
        ...mineList
      };
    },
    onMenuClick(path) {
      this.$router.push(path);
    },
    createFloatingNumber(e, value) {
      if (!this.pageDom) {
        return;
      }
      const index_mine = this.pageDom.content.getByID("index_mine");
      if (!index_mine) {
        return;
      }
      const clickPosition = e.data.getLocalPosition(index_mine);
      const startX = index_mine.x + clickPosition.x;
      const startY = index_mine.y + clickPosition.y - 40;
      const text = new Text(`+${this.toPre(value, 0)}`, {
        fill: 0xffffff,
        fontSize: 24,
        stroke: "#11131e",
        strokeThickness: 2,
        lineJoin: "round",
        fontFamily: "pFont",
        miterLimit: 100,
        dropShadow: true,
        dropShadowAngle: 90,
        dropShadowDistance: 1
      });
      text.x = startX;
      text.y = startY;
      this.gameApp.stage.addChild(text);

      // 创建漂移动画
      const duration = 500; // 漂移动画持续时间 (ms)
      const distance = 50; // 向上漂移距离

      const startTime = Date.now();
      const that = this;
      function animate() {
        const elapsed = Date.now() - startTime;
        if (elapsed < duration) {
          const progress = elapsed / duration;
          text.y = startY - progress * distance;
          requestAnimationFrame(animate);
        } else {
          text.visible = false;
          that.gameApp.stage.removeChild(text);
          text.destroy(); // 销毁文本对象
        }
      }

      requestAnimationFrame(animate);
    },
    async collectCoin(e) {
      this.isBeforeDestroyRequest = true;
      clearTimeout(this.caclTimeVal);
      this.WebApp.enableClosingConfirmation();
      const setFn = () => {
        this.music_collet();
        this.caclTimeVal = setTimeout(() => {
          this.requireCoinCollect();
        }, 2000);
        if (!this.$refs.assetsInfo) {
          return;
        }
      };
      console.log("this.coinInfo.colletTotalNum", this.coinInfo.colletTotalNum, this.accountInfo.store_max);
      if (this.coinInfo.colletTotalNum >= this.accountInfo.store_max) {
        clearTimeout(this.caclTimeVal);
        await this.requireCoinCollect();
        return;
      }
      if (this.coinInfo.produceTotalNum > this.accountInfo.tap_amount) {
        //当仓库剩余金币数大于单次点击数
        this.coinInfo.colletTotalNum += Number(this.accountInfo.tap_amount);
        this.coinInfo.produceTotalNum -= Number(this.accountInfo.tap_amount);
        this.createFloatingNumber(e, this.accountInfo.tap_amount);
      } else if (this.coinInfo.produceTotalNum <= this.accountInfo.tap_amount && (parseInt(this.coinInfo.produceTotalNum) > 0 || this.coinInfo.colletTotalNum > 0)) {
        //当仓库剩余金币量不足单次点击收取金币量时
        if (parseInt(this.coinInfo.produceTotalNum) > 0) {
          this.createFloatingNumber(e, Number(this.coinInfo.produceTotalNum));
        }
        this.coinInfo.colletTotalNum += Number(this.coinInfo.produceTotalNum);
        this.coinInfo.produceTotalNum -= Number(this.coinInfo.produceTotalNum);
      }
      if (this.coinInfo.colletTotalNum > 0) {
        setFn();
      }
    },
    async requireCoinCollect() {
      await this.getAccountInfo();
      if (this.accountInfo.init == 1) {
        this.m_getGuideInfo(this.pageDom, "index_navs_recruit", {
          step: "step1",
          stage: 0,
          key: "coordinate"
        });
      }
      if (this.coinInfo.colletTotalNum == 0) {
        this.WebApp.disableClosingConfirmation();
      }
    },
    async popBuyPositionSubmit(callback) {
      const id = this.accountInfo?.mine?.id;
      const res = await this.addMinePosition(id);
      console.log("resresresres", res);
      if (!res) {
        this.$refs.popBuyPositionRef.setBtnLoading(false);
        return;
      }
      await this.getAccountInfo(res.data);
      this.changeMinerDom();
      this.$refs.assetsInfo.renderDom({
        diamond: true,
        type: "refresh"
      });
      this.popBuyPositionOption.show = false;
      this.popBuyPositionDom.visible = false;
      typeof callback == "function" && callback();
    },
    popInsufficientBalanceSumbit(callback) {
      this.closePage();
      this.popInsufficientBalanceShow = false;
      this.popInsufficientBalanceDom.visible = false;
      this.popInsufficientBalanceContent = "";
      this.setDiamondInsufficient({
        show: false,
        message: ""
      });
      this.pageDiamondShow = true;
      if (_v["page_payDiamond"]) {
        _v["page_payDiamond"].visible = false;
      }
      typeof callback == "function" && callback();
    }
  },
  mounted() {}
};
const globalStyles = {
  index_page: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren"
  },
  index_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  index_assets: {
    position: "rightTop",
    paddingTop: 20,
    marginLeft: 10
  },
  index_navs: {
    position: "centerTop",
    width: "92%",
    height: 135,
    marginTop: 130,
    anchor: 0.5
  },
  index_navs_task: {
    position: "leftTop",
    width: 60,
    height: 60,
    textAlign: "center"
  },
  index_navs_miner: {
    position: "rightTop",
    width: 60,
    height: 60,
    textAlign: "center"
  },
  index_navs_recruit: {
    marginTop: 60 + 15,
    position: "leftTop",
    width: 60,
    height: 60,
    textAlign: "center"
  },
  index_navs_main: {
    width: "100%",
    height: "100%",
    position: "center"
  },
  index_navs_warehouse: {
    position: "leftTop",
    marginTop: 60 * 2 + 30,
    width: 60,
    height: 60,
    textAlign: "center"
  },
  index_navs_setting: {
    position: "rightTop",
    marginTop: 60 * 2 + 30,
    width: 60,
    height: 60,
    textAlign: "center"
  },
  index_navs_ranking: {
    marginTop: 60 + 15,
    position: "rightTop",
    width: 60,
    height: 60,
    textAlign: "center"
  },
  index_navs_bg: {
    scale: 0.25
    // position: "leftTop",
  },

  index_navs_icon: {
    scale: 0.25,
    position: "center",
    marginLeft: -3,
    marginTop: -3
  },
  index_navs_text: {
    position: "center",
    fill: "#fff",
    fontSize: 14,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1,
    marginTop: 20
  },
  index_menu: {
    position: "centerBottom",
    width: "100%",
    height: 60,
    marginBottom: -30
    // overflow:"hidden"
  },

  index_menu_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  index_menu_main: {
    position: "centerBottom",
    width: "90%",
    height: 60,
    marginBottom: 30
  },
  index_menu_item_mine: {
    position: "centerBottom",
    width: 110,
    height: 78,
    marginLeft: -110 - 5
  },
  index_menu_item_market: {
    position: "centerBottom",
    width: 110,
    height: 78,
    overflow: "hidden"
  },
  index_menu_item_invite: {
    position: "centerBottom",
    width: 110,
    height: 78,
    marginLeft: 110 + 5
  },
  index_menu_item_bg: {
    scale: 0.28,
    position: "center"
  },
  index_menu_item_icon: {
    scale: 0.3,
    position: "center",
    marginTop: -10
  },
  index_menu_item_text: {
    marginTop: 19,
    position: "center",
    fill: "#fff",
    fontSize: 14,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1
  },
  index_build: {
    position: "centerBottom",
    maxWidth: "100%"
  },
  index_build_bg: {},
  index_build_2_car: {
    position: "leftBottom",
    marginBottom: 680,
    marginLeft: 230

    // width:"100%",
    // height:"100%",
  },

  index_build_2_car_anim: {},
  index_build_1: {
    position: "centerBottom"
  },
  index_build_2: {
    position: "centerBottom"
  },
  index_build_3: {
    position: "centerBottom"
  },
  index_build_4: {
    position: "centerBottom"
  },
  miner_1: {
    position: "centerBottom",
    overflow: "hidden",
    width: 150,
    height: 100,
    marginBottom: 390,
    marginLeft: 80
  },
  miner_2: {
    position: "centerBottom",
    width: 150,
    height: 100,
    marginBottom: 280,
    marginLeft: 75
  },
  miner_3: {
    position: "centerBottom",
    width: 150,
    height: 100,
    marginBottom: 280,
    marginLeft: 100
  },
  miner_4: {
    position: "centerBottom",
    width: 150,
    height: 100,
    marginBottom: 370,
    marginLeft: 80
  },
  index_miner: {
    position: "centerBottom",
    maxWidth: _v.screenWidth
    // height: _v.screenHeight,
  },

  index_miner_main: {
    scale: 0.2
  },
  miner_anim_2: {
    scaleX: -1
  },
  miner_anim: {},
  index_miner_add: {},
  index_miner_add_2: {
    marginBottom: -5,
    marginLeft: -110
  },
  index_miner_add_4: {
    marginLeft: -80
  },
  index_miner_add_main: {
    scale: 0.2
  },
  index_miner_add_main_lock: {
    scale: 0.22,
    position: "leftTop",
    marginTop: 16,
    marginLeft: 21
  },
  index_miner_add_main_price: {
    position: "leftTop",
    marginTop: 43,
    marginLeft: 17
  },
  index_miner_add_main_price_icon: {
    scale: 0.15,
    position: "leftTop"
  },
  index_miner_add_main_price_label: {
    ..._v.fs_fontStyle({
      fz: 11,
      st: 2
    }),
    position: "leftTop",
    marginLeft: 17,
    marginTop: -2
  },
  index_mine: {
    position: "centerBottom"
  },
  index_mine_main: {
    position: "centerBottom",
    marginLeft: 200,
    anchorX: 0.5,
    anchorY: 0.5,
    width: 400,
    height: 400
  },
  index_mine_build: {
    scale: 0.26,
    anchorX: 0.5,
    anchorY: 0.5,
    position: "center",
    width: 1540,
    height: 1540
  },
  index_mine_coins: {
    marginTop: -5,
    position: "left",
    anchorX: 0.5,
    anchorY: 1,
    scale: 0.25
  },
  index_mine_progress: {
    position: "left",
    width: 200,
    height: 50,
    anchorX: 0.5,
    anchorY: 0.5,
    marginTop: 30
  },
  index_mine_power: {
    anchorX: 0.5,
    position: "left",
    textAlign: "center",
    width: 100,
    height: 20,
    marginTop: 30,
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2
    })
  },
  index_btn_mine: {
    position: "bottomCenter",
    width: 150,
    height: 40,
    overflow: "hidden",
    marginBottom: 100,
    visible: false
  },
  index_btn_mine_bg: {
    position: "center",
    width: 150,
    height: 40
  },
  index_btn_mine_bg_main: {
    scale: 0.22,
    position: "center"
  },
  index_btn_mine_text: {
    ..._v.fs_fontStyle({
      fz: 14,
      st: 2
    }),
    width: 150,
    height: 40,
    position: "center",
    textAlign: "center"
  }
};