var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-prompt', {
    ref: "popGoMineRef",
    attrs: {
      "domName": "popGoMineDom",
      "is-btn-close": _vm.popGoMineOption.isBtnClose,
      "btn-type": _vm.popGoMineOption.type,
      "parent-dom-name": "popDom",
      "show": _vm.popGoMineOption.show,
      "title": _vm.popGoMineOption.title,
      "content": _vm.popGoMineOption.content,
      "btnText": _vm.popGoMineOption.btnText
    },
    on: {
      "close": _vm.popGoMineClose,
      "update:show": function ($event) {
        return _vm.$set(_vm.popGoMineOption, "show", $event);
      },
      "submit": _vm.popGoWorkSubmit
    }
  }), _c('pop-prompt', {
    ref: "popLeaveWorkRef",
    attrs: {
      "parentDomName": "popDom",
      "domName": "popLeaveWorkDom",
      "isCallback": _vm.popLeaveWorkIsCallback,
      "show": _vm.popLeaveWorkshow,
      "title": _vm.popLeaveWorkOption.title,
      "content": _vm.popLeaveWorkOption.content
    },
    on: {
      "update:show": function ($event) {
        _vm.popLeaveWorkshow = $event;
      },
      "submit": _vm.popLeaveWorkSubmit
    }
  }), _c('pop-prompt', {
    ref: "popPromptSwitchTipRef",
    attrs: {
      "isBtn": false,
      "content": "Switching...",
      "show": _vm.popPromptSwitchTipShow,
      "domName": "popPromptSwitchTipDom"
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptSwitchTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };