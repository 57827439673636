import { Layout } from "@pixi/layout";
// 对象池类
class ObjectPool {
  constructor(createFunc, size = 10) {
    this.createFunc = createFunc; // 用于创建新对象的函数
    this.pool = []; // 对象池数组
    this.init(size); // 初始化对象池
  }

  // 初始化对象池，创建指定数量的对象
  init(size) {
    for (let i = 0; i < size; i++) {
      // const layout = this.createFunc();
      this.pool.push(this.createFunc);
    }
  }

  // 获取一个对象，如果池中有可用对象则返回，否则创建一个新对象
  acquire() {
    return this.pool.length > 0 ? this.pool.pop() : this.createFunc();
  }

  // 释放一个对象，将其放回池中
  release(obj) {
    this.pool.push(obj);
  }
}

export default ObjectPool;
