const audioContext = new (window.AudioContext || window.webkitAudioContext)();
function initializeAudio(url) {
  return new Promise((resolve, reject) => {
    console.log("audioContext", audioContext);
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((data) => audioContext.decodeAudioData(data))
      .then((buffer) => {
        resolve(buffer);
      });
  });
}

function playHitSound(hitSoundBuffer) {
  if (hitSoundBuffer) {
    const source = audioContext.createBufferSource();
    source.buffer = hitSoundBuffer;
    source.connect(audioContext.destination);
    console.log("source", source);
    source.start(0);
  }
}
const collet = "/assets/music/collet.mp3";
const music_collet = await initializeAudio(collet);
export default {
  music_collet: async () => {
    playHitSound(music_collet);
  },
  music_bgm: async () => {
    const url = "/assets/music/bgm.mp3";
    const bgm = new Audio(url);
    bgm.loop = true
    bgm.addEventListener("canplaythrough", () => {
      if(bgm.paused) {
        bgm.play();
      }
    });
    bgm.addEventListener("error", (e) => {
      let errorMessage = "An error occurred while loading the audio.";

      if (bgm.error) {
        switch (bgm.error.code) {
          case MediaError.MEDIA_ERR_ABORTED:
            errorMessage = "The fetching of the audio resource was aborted by the user.";
            break;
          case MediaError.MEDIA_ERR_NETWORK:
            errorMessage = "A network error occurred while fetching the audio resource.";
            break;
          case MediaError.MEDIA_ERR_DECODE:
            errorMessage = "An error occurred while decoding the audio resource.";
            break;
          case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            errorMessage = "The audio resource is not supported by the browser.";
            break;
          default:
            errorMessage = "An unknown error occurred.";
            break;
        }
      }

      console.error(errorMessage);
    });
  },
};
