import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane, Texture } from "pixi.js";
const ratio = 0.9;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 120;
const scrollItemWidth = scrollWidth;
const scrollItemHeight = 80;
const isLoading = _v.c_listLoading();
import gsap from "gsap";
const btnRefresh1 = Sprite.from(_v.m_getRes("element").textures["btn-refresh-1.png"]);
btnRefresh1.anchor.set(0.5);
export default {
  name: "popTask",
  watch: {
    "$parent.popTaskShow": {
      async handler(bool) {
        if (bool) {
          await this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...gameVuex.mapState(["taskList"]),
    ...userVuex.mapState(["accountInfo"])
  },
  data() {
    return {
      popDom: null,
      initPopBgDom: null,
      isFlag: true
    };
  },
  methods: {
    ...gameVuex.mapActions(["getTaskList", "taskSkip"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    setListLoading(bool) {
      const scrollDom = this.$parent.popTaskDom.content.getByID("pop_task_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    async initPop() {
      if (!this.$parent.popTaskDom) {
        this.$parent.popTaskDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popTaskDom);
      }
      this.$parent.popTaskDom.visible = true;
      this.$parent.popTaskDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popTaskDom.position.set(0, 0);
      this.setZindex();
      this.setListLoading(true);
      this.anim_pop(this.$parent.popTaskDom, async () => {
        await this.loadTaskList();
      });
    },
    setZindex() {
      if (!this.$parent.popTaskDom) {
        return;
      }
      const pop_main = this.$parent.popTaskDom.getChildByID("pop_main");
      const pop_task_head = this.$parent.popTaskDom.getChildByID("pop_task_head");
      const pop_task_list = this.$parent.popTaskDom.getChildByID("pop_task_list");
      pop_main.sortableChildren = true;
      pop_task_head.zIndex = 2;
      pop_task_list.zIndex = 1;
    },
    async loadTaskList() {
      this.setListLoading(true);
      await this.getTaskList();
      const scrollDom = this.$parent.popTaskDom.content.getByID("pop_task_list_scroll").content;
      console.log("scrollDom", scrollDom);
      scrollDom.createContent(this.minerListDom());
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      this.setListLoading(false);
      const _scrollDom = this.$parent.popTaskDom.content.getByID("pop_task_list_scroll");
      console.log("this.minerListDom", _scrollDom);
      const nodeList = _scrollDom.children[0]?.children[1]?.children;
      console.log("nodeList", nodeList);
      if (nodeList) {
        nodeList.map(async (item, index) => {
          const pop_task_item_main_head_icon = item.content.getByID("pop_task_item_main_head_icon");
          console.log("this.taskList[index].icon", this.taskList[index].icon);
          console.log("pop_task_item_main_head_icon.children[0]", pop_task_item_main_head_icon.children[0]);
          if (this.taskList[index].icon) {
            Assets.add({
              alias: `networkItem_${index}`,
              src: this.taskList[index].icon
            });
            const networkItem = await Assets.load(`networkItem_${index}`);
            pop_task_item_main_head_icon.children[0].texture = networkItem;
          } else {
            pop_task_item_main_head_icon.children[0].texture = Assets.cache.get(`${this.taskList[index].type}`);
          }
        });
      }
      await this.getAccountInfo();
    },
    minerListDom() {
      // Sprite.from(this.m_getRes("element").textures[`${item.type}.png`])
      const minerItemDom = (item, index) => {
        return {
          id: "pop_task_item",
          content: {
            pop_task_item_bg: {
              content: this.sf_taskItemBg({
                width: scrollWidth * 4 - 160,
                height: scrollItemHeight * 4
              }),
              styles: globalStyles.pop_task_item_bg
            },
            pop_task_item_main: {
              content: {
                pop_task_item_main_head: {
                  content: {
                    // pop_task_item_main_head_bg: {
                    //   content: this.sf_taskItemImg({ width: 240, height: 240 }),
                    //   styles: globalStyles.pop_task_item_main_head_bg,
                    // },
                    pop_task_item_main_head_icon: {
                      content: new Sprite(null),
                      styles: globalStyles.pop_task_item_main_head_icon
                    }
                  },
                  styles: globalStyles.pop_task_item_main_head
                },
                pop_task_item_main_info: {
                  content: {
                    pop_task_item_main_info_title: {
                      content: `${item.title}`,
                      styles: globalStyles.pop_task_item_main_info_title
                    },
                    pop_task_item_main_info_reward: {
                      content: {
                        pop_task_item_main_info_reward_bg: {
                          content: this.sf_taskItemAssetBg({
                            width: 450,
                            height: 100
                          }),
                          styles: globalStyles.pop_task_item_main_info_reward_bg
                        },
                        pop_task_item_main_info_reward_num: {
                          content: `${this.toPre(item.reward, 0)}`,
                          styles: globalStyles.pop_task_item_main_info_reward_num
                        },
                        pop_task_item_main_info_reward_icon: {
                          content: Sprite.from(_v.m_getRes("element").textures[`icon-${item.asset === "gme" ? "gem" : item.asset}.png`]),
                          styles: globalStyles.pop_task_item_main_info_reward_icon
                        }
                      },
                      styles: globalStyles.pop_task_item_main_info_reward
                    }
                  },
                  styles: globalStyles.pop_task_item_main_info
                },
                pop_task_item_main_check: item.status == 1 && {
                  content: Sprite.from(_v.m_getRes("element").textures["icon-ok.png"]),
                  styles: globalStyles.pop_task_item_main_check
                },
                pop_task_item_main_arrow: item.status == 0 && {
                  content: Sprite.from(_v.m_getRes("element").textures["icon-arrow.png"]),
                  styles: globalStyles.pop_task_item_main_arrow
                }
              },
              styles: globalStyles.pop_task_item_main
            }
          },
          styles: globalStyles.pop_task_item
        };
      };
      return this.c_scrollBox(this.taskList.map((it, idx) => {
        return it.id != -1 ? this.m_btn(minerItemDom(it, idx), () => {
          this.onItemClick(it, idx);
        }, {
          width: scrollItemWidth,
          height: scrollItemHeight + 5
          // gsap: true,
          // init: 0.98,
          // result: 1,
        }) : this.m_layoutDom({
          content: {
            taskTypeTitle: {
              content: "Extra Task",
              styles: {
                fontSize: 16,
                color: "#fff",
                fontFamily: "pFont",
                lineHiehgt: 21,
                height: 40,
                width: scrollItemWidth,
                marginTop: 45,
                marginLeft: 25
              }
            }
          }
        }, {
          width: scrollItemWidth,
          height: 40
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 60
      });
    },
    renderDom() {
      return {
        content: {
          pop_task: {
            content: {
              pop_task_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_task_head: {
                    content: {
                      pop_task_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_task_title: {
                        content: {
                          pop_task_title_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                          },
                          pop_task_title_text: {
                            content: "TASK"
                          }
                        }
                      },
                      pop_task_title_refresh: this.m_btn({
                        content: btnRefresh1,
                        styles: globalStyles.pop_task_title_refresh
                      }, async () => {
                        if (this.isFlag) {
                          this.$toasted.clear();
                          this.isFlag = false;
                          const rotationTween = gsap.to(btnRefresh1, {
                            rotation: Math.PI * 2,
                            duration: 1,
                            repeat: -1,
                            ease: "none"
                          });
                          await this.loadTaskList();
                          rotationTween.kill();
                          gsap.to(btnRefresh1, {
                            rotation: 0,
                            duration: 0.2
                          });
                          let timer = setTimeout(() => {
                            clearTimeout(timer);
                            timer = null;
                            this.isFlag = true;
                          }, 3000);
                        } else {
                          // this.$toasted.clear();
                          // this.$toasted.error("Refreshing too quickly!");
                        }
                      }),
                      pop_task_close: {
                        content: this.c_btnClose(() => {
                          this.$emit("update:show", false);
                          this.$parent.popTaskDom.visible = false;
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_task_list: {
                    content: {
                      pop_task_list_bg: {
                        content: this.sf_marketGoods({
                          width: this.screenWidth * 4 - 140,
                          height: this.screenHeight * 4 - 380
                        })
                      },
                      common_loading: isLoading.render(),
                      pop_task_list_scroll: {
                        content: " "
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    async onItemClick(item) {
      const url = await this.taskSkip(item.id);
      await this.getAccountInfo();
      this.$parent?.$refs.assetsInfo?.renderDom({
        diamond: true,
        coin: true,
        gem: true,
        type: "refresh",
        dom: this.$parent.pageDom
      });
      if (item.type == "telegram" || item.type == "channel") {
        this.WebApp.openTelegramLink(url);
      } else {
        this.WebApp.openLink(url);
      }
      this.$emit("update:show", false);
      if (this.popDom) this.popDom.destroy();
    }
  }
};
const globalStyles = {
  pop_task: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "left"
  },
  pop_task_mask: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_main: {},
  pop_task_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50,
    marginTop: 60
  },
  pop_task_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_task_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_task_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_task_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_task_title_refresh: {
    width: 20,
    height: 20,
    position: "centerTop",
    scale: 0.23,
    marginTop: 15,
    marginLeft: 42
  },
  pop_task_close: {
    position: "rightTop"
  },
  pop_task_list: {
    position: "centerTop",
    marginTop: 70
  },
  pop_task_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  pop_task_list_scroll: {
    position: "centerTop"
    // marginLeft: 35,
  },

  pop_task_item_bg: {
    position: "center",
    scale: 0.25
  },
  pop_task_item_main: {
    position: "center",
    marginTop: -2,
    width: scrollItemWidth - 40
  },
  pop_task_item_main_head: {
    width: 60,
    height: 60,
    overflow: "hidden",
    background: "#5f7188",
    borderRadius: 10,
    marginLeft: 10
  },
  pop_task_item_main_head_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_task_item_main_head_icon: {
    position: "leftTop",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    scale: 0.3
  },
  pop_task_item_main_head_img: {
    position: "center",
    maxWidth: "100%",
    maxHeight: "100%"
    // scale: 0.25,
  },

  pop_task_item_main_info: {
    width: scrollItemWidth - 75 - 5 - 20,
    height: 60,
    marginLeft: 20
  },
  pop_task_item_main_info_title: {
    fontSize: 16,
    color: "#2e3443",
    fontFamily: "pFont"
  },
  pop_task_item_main_info_reward: {
    position: "leftCenter",
    width: scrollItemWidth - 75 - 5 - 20,
    marginTop: 48,
    marginLeft: 8
    // overflow: 'hidden'
  },

  pop_task_item_main_info_reward_bg: {
    scale: 0.25,
    position: "leftTop",
    width: "100%"
  },
  pop_task_item_main_info_reward_num: {
    position: "leftTop",
    marginLeft: 105,
    paddingLeft: 10,
    paddingTop: 5,
    fontSize: 16,
    fontFamily: "pFont",
    color: "#cd5d0a",
    marginTop: -2,
    anchorX: 1
  },
  pop_task_item_main_info_reward_icon: {
    position: "leftTop",
    marginTop: -4,
    marginLeft: -10,
    width: 28,
    height: 28,
    scale: 0.3
  },
  pop_task_item_main_check: {
    scale: 0.25,
    position: "rightCenter",
    marginRight: 10
  },
  pop_task_item_main_arrow: {
    scale: 0.25,
    position: "rightCenter",
    marginRight: 10,
    marginTop: 5
  }
};