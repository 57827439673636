import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
export default {
  name: "popSell",
  props: {
    show: Boolean
  },
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo", "guideInfo"]),
    isRender() {
      return this.$parent.popGuideShow && this.gameApp;
    }
  },
  watch: {
    isRender: {
      handler(bool) {
        console.log("34343411111", bool);
        if (!bool) {
          if (this.popDom) this.popDom.destroy();
        }
      }
      // deep: true,
      // immediate: true,
    }
  },

  data() {
    return {
      popDom: null,
      amount: 0,
      ton: 0
    };
  },
  methods: {
    ...gameVuex.mapActions(["marketAdd", "bindWallet"]),
    initPop(option) {
      this.pageDom?.destroy();
      this.pageDom = this.m_pageDom(this.renderDom());
      const g_pageDom = this.c_guide(this.pageDom, option);
      this.gameApp.stage.addChild(g_pageDom);
      this.gameApp.stage.sortableChildren = true;
      this.pageDom.zIndex = 20;
    },
    clearPop() {
      this.pageDom?.destroy();
    },
    setMarks() {
      const dom = this.c_createMask();
      return dom;
    },
    renderDom() {
      return {
        content: {
          pop_sell: {
            content: {
              pop_sell_mask: {
                content: this.setMarks()
              }
            }
          }
        },
        globalStyles
      };
    }
  }
};
const globalStyles = {
  pop_task: {
    width: "100%",
    height: "100%",
    position: "leftTop"
  },
  pop_sell_mask: {
    position: "leftTop",
    width: "100%",
    height: "100%"
  }
};