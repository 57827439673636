import "@tonWallet/index";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "layout",
  inject: ["reload"],
  data() {
    return {
      unsubscribe: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile"]),
    ...walletVuex.mapGetters(["isWallet"])
  },
  watch: {},
  methods: {
    ...walletVuex.mapMutations(["setTonConnect"]),
    unsubscribeTonConnectStatus() {
      try {
        const tonWallet = this.tonWallet;
        const unsubscribe = tonWallet.ton.onStatusChange(walletAndwalletInfo => {
          console.log("unsubscribe nromal", unsubscribe);
          // update state/reactive variables to show updates in the ui
          tonWallet.getWalletInfo();
          console.log("tonWallet.ton.connected", tonWallet.ton.connected);
          this.setTonConnect(tonWallet.ton.connected);
          this.$set(tonWallet, "wallet", walletAndwalletInfo);
        });
        this.unsubscribe = unsubscribe;
      } catch (err) {
        console.log("unsubscribeTonConnectStatus :", err);
      }
    },
    initTonWallet() {
      let timer = setTimeout(async () => {
        clearTimeout(timer);
        timer = null;
        const div = document.createElement("div");
        div.setAttribute("id", "ton-connect");
        div.style.display = "none";
        div.style.position = "absolute";
        div.style.zIndex = -99999;
        document.body.appendChild(div);
        const tonConnect = document.getElementById("ton-connect");
        if (tonConnect) {
          clearTimeout(timer);
          timer = null;
          try {
            console.log("this.tonWalletthis.tonWallet", this.tonWallet);
            typeof this.unsubscribe === "function" && this.unsubscribe();
            await this.tonWallet.initTon("ton-connect");
            await this.unsubscribeTonConnectStatus();
            this.setTonConnect(this.tonWallet.ton.connected);
          } catch (err) {
            console.log(err);
          }
        }
      }, 1000);
    },
    createTonDom() {}
  },
  mounted() {
    this.initTonWallet();
  }
};