import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane, TilingSprite, Texture } from "pixi.js";
const ratio = 0.82;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 225;
const scrollItemWidth = scrollWidth / 2;
const scrollItemHeight = 225;
Assets.load("market-title-bg");
await Assets.loadBundle("mine");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
await Assets.loadBundle("market");
const isLoading = _v.c_listLoading();
export default {
  name: "market",
  inject: ["closePage", "setPageRecruitShow"],
  computed: {
    ...gameVuex.mapState(["gameApp", "marketList", "marketListPage", "marketListTotal", "myMarketList", "myMarketListPage", "myMarketListTotal", "selectMinerInfo"]),
    ...userVuex.mapState(["gameInfo", "accountInfo", "coinInfo"]),
    isRender() {
      return this.$parent.pageMarketShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        // console.log("666666666", bool);
        if (bool) {
          this.initScreen();
          if (this.coinInfo.colletTotalNum > 0) {
            await this.getAccountInfo();
            this.$refs.assetsInfo1.renderDom({
              coin: true,
              diamond: true,
              type: "refresh"
            });
          }
        }
      },
      immediate: true
    },
    "selectTabInfo.asset": {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          (async () => {
            this.setListLoading(true);
            console.log(3332);
            if (this.selectMTabInfo.value === 0) {
              this.setMarketListPage({
                page: 1
              });
              this._setMarketListPage(this.marketList, this.marketListTotal, this.marketListPage);
            } else {
              this.setMyMarketListPage({
                page: 1
              });
              this._setMarketListPage(this.myMarketList, this.myMarketListTotal, this.myMarketListPage);
            }
            this.setComingsoon();
            this.loadMinerList();
            this.setBtnSellShow();
          })();
        }
      },
      deep: true
    },
    "selectMTabInfo.value": {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.setListLoading(true);
          if (newVal === 0) {
            this.setMarketListPage({
              page: 1
            });
            this._setMarketListPage(this.marketList, this.marketListTotal, this.marketListPage);
          } else {
            this.setMyMarketListPage({
              page: 1
            });
            this._setMarketListPage(this.myMarketList, this.myMarketListTotal, this.myMarketListPage);
          }
          this.setComingsoon();
          this.loadMinerList();
          this.setBtnSellShow();
        }
      },
      deep: true
    }
  },
  data() {
    const mTabList = [{
      label: "MARKET",
      value: 0
    }, {
      label: "MY ORDER",
      value: 1
    }];
    const tabList = [{
      label: "MINER",
      value: 0,
      asset: "miner"
    }, {
      label: "MINE",
      value: 1,
      asset: "mine"
    }, {
      label: "COIN",
      value: 2,
      asset: "coin"
    }];
    return {
      currentScreen: null,
      pageDom: null,
      tabList,
      isTab: tabList[0].value,
      selectTabInfo: tabList[0],
      popMyMarketShow: false,
      mTabList,
      isMTab: mTabList[0].value,
      selectMTabInfo: mTabList[0],
      popBuyOption: {
        show: false,
        content: "Should I buy this miner"
      },
      selectRow: null,
      popDelistOption: {
        show: false,
        content: "Whether to Unlist"
      },
      popMyMineShow: false,
      popCoinSellShow: false,
      isComingsoon: false,
      popMyMarketDom: null,
      popMyMineDom: null,
      popCoinSellDom: null,
      popBuyDom: null,
      popDelistDom: null,
      popMarketInfoDom: null,
      popMarketInfoShow: false
    };
  },
  // async beforeDestroy() {
  //   this.pageDom?.destroy();
  //   await this.m_clearCurrentScreen(this.currentScreen);
  // },
  methods: {
    ...gameVuex.mapActions(["getMinerList", "getMarketList", "getMyMarketList", "marketBuy", "removeMarket", "bindWallet"]),
    ...gameVuex.mapMutations(["setMarketListPage", "setMyMarketListPage", "setSelectMinerInfo"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    setListLoading(bool) {
      const scrollDom = this.pageDom.content.getByID("market_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    initScreen() {
      this.$nextTick(async () => {
        this.setComingsoon();
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
        await this.setScreen();
      });
    },
    setComingsoon() {
      let bool = false;
      if (this.selectMTabInfo.value === 1) {
        bool = false;
      } else {
        switch (this.selectTabInfo.asset) {
          case "miner":
            bool = false;
            break;
          case "mine":
            bool = !this.gameInfo.mineMarketOpen;
            break;
          case "coin":
            bool = !this.gameInfo.coinMarketOpen;
            break;
          default:
            bool = false;
            break;
        }
      }
      this.isComingsoon = bool;
    },
    async setScreen() {
      if (!_v[`page_market`]) {
        _v[`page_market`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_market`]);
      }
      _v[`page_market`].visible = true;
      _v[`page_market`].zIndex = this.gameApp.stage.children.length + 1;
      this.pageDom = _v[`page_market`];
      this.setDom();
      this.setBtnSellShow();
      this.loadMinerList();
    },
    setBtnSellShow() {
      const market_list_own = this.pageDom.content.getByID("market_list_own");
      const market_list_text = this.pageDom.content.getByID("market_list_text");
      if (this.selectMTabInfo.value === 0) {
        market_list_own.visible = true;
      } else {
        market_list_own.visible = false;
      }
      // market_list_own.visible = false;
      switch (this.selectTabInfo.asset) {
        case "miner":
          market_list_text.children[0].text = this.$lang("I want to sell miner");
          break;
        case "mine":
          market_list_text.children[0].text = this.$lang("I want to sell mine");
          break;
        case "coin":
          market_list_text.children[0].text = this.$lang("I want to sell coin");
          break;
      }
      console.log("market_list_text.children[0].text", market_list_text.children[0].text);
    },
    _setMarketListPage(list = [], listTotal, listPage) {
      const market_list_page = this.pageDom.content.getByID("market_list_page");
      const market_list_page_main = this.pageDom.content.getByID("market_list_page_main").content;
      const setPage = (_list, _listTotal, _listPage) => {
        market_list_page.visible = true;
        market_list_page_main.createContent(this.c_page(_listTotal, page => {
          setListPage(page);
          (async _page => {
            this.loadMinerList();
          })(page);
        }, _listPage));
        market_list_page_main.removeContent(market_list_page_main.children.entries().next().value[0]);
      };
      if (list && list.length > 0 && listTotal > listPage.perPage) {
        setPage(list, listTotal, listPage);
      } else {
        // if (listPage > 1 && listTotal >= listPage.perPage) {
        //   if (this.selectMTabInfo.value == 0) {
        //     this.setMarketListPage({ page: 1 });
        //     setPage(this.marketList, this.marketListTotal, this.marketListPage);
        //   } else {
        //     this.setMyMarketListPage({ page: 1 });
        //     setPage(this.myMarketList, this.myMarketListTotal, this.myMarketListPage);
        //   }
        // }
        market_list_page.visible = false;
      }
    },
    setDom() {
      this.pageDom.sortableChildren = true;
      const market_head = this.pageDom.getChildByID("market_head");
      const market_list = this.pageDom.getChildByID("market_list");
      const market_page = this.pageDom.getChildByID("market_page");
      const market_btn_back = this.pageDom.getChildByID("market_btn_back");
      const market_coin = this.pageDom.getChildByID("market_coin");
      market_page.sortableChildren = true;
      market_head.zIndex = 2;
      market_list.zIndex = 1;
      market_btn_back.zIndex = 3;
      market_coin.zIndex = 3;
    },
    async loadMinerList() {
      const pageDom = this.pageDom.content;
      const scrollDom = this.pageDom.content.getByID("market_list_scroll").content;
      const market_list_page = this.pageDom.content.getByID("market_list_page");
      const market_list_box_1 = this.pageDom.content.getByID("market_list_box_1");
      const market_list_box = this.pageDom.content.getByID("market_list_box");
      const market_list_page_main = this.pageDom.content.getByID("market_list_page_main").content;
      const market_empty = pageDom.getByID("market_empty");
      let list = null;
      let listTotal = null;
      let listPage = null;
      console.log("this.selectMTabInfo.value == 0", this.selectMTabInfo.value, this.selectTabInfo.asset, this.gameInfo.mineMarketOpen);
      if (this.selectMTabInfo.value == 0 && (this.selectTabInfo.asset === "mine" && !this.gameInfo.mineMarketOpen || this.selectTabInfo.asset === "coin" && !this.gameInfo.coinMarketOpen)) {
        this.setListLoading(false);
        market_list_page.visible = false;
        market_empty.visible = false;
        market_list_box.visible = false;
        market_list_box_1.visible = true;
        return;
      } else {
        market_list_box.visible = true;
        market_list_box_1.visible = false;
      }
      let setListPage = page => {
        if (this.selectMTabInfo.value == 0) {
          this.setMarketListPage({
            page
          });
        } else {
          this.setMyMarketListPage({
            page
          });
        }
      };
      if (this.selectMTabInfo.value == 0) {
        await this.getMarketList({
          asset: this.selectTabInfo.asset
        });
        list = this.marketList;
        listTotal = this.marketListTotal;
        listPage = this.marketListPage;
        console.log("this.marketListTotal", this.marketListTotal);
      } else {
        await this.getMyMarketList({
          asset: this.selectTabInfo.asset
        });
        list = this.myMarketList;
        listTotal = this.myMarketListTotal;
        listPage = this.myMarketListPage;
        console.log("this.myMarketListTotal", this.myMarketListTotal);
      }
      if (list.length <= 0) {
        market_empty.visible = true;
      } else {
        market_empty.visible = false;
      }
      this._setMarketListPage(list, listTotal, listPage);
      if (list.length > 0 && listTotal > listPage.perPage) {
        market_list_page.visible = true;
        market_list_page_main.createContent(this.c_page(listTotal, page => {
          setListPage(page);
          (async _page => {
            this.loadMinerList();
          })(page);
        }, listPage));
        market_list_page_main.removeContent(market_list_page_main.children.entries().next().value[0]);
      } else {
        market_list_page.visible = false;
      }
      if (this.selectMTabInfo.value == 0) {
        if (this.marketListTotal > this.marketListPage.perPage) {
          this.scrollListHeight = scrollHeight - 80;
          market_list_page.setStyles({
            marginTop: scrollHeight + 12
          });
        } else {
          this.scrollListHeight = scrollHeight - 50;
        }
      } else {
        if (this.myMarketListTotal > this.myMarketListPage.perPage) {
          this.scrollListHeight = scrollHeight - 20;
          market_list_page.setStyles({
            marginTop: scrollHeight + 75
          });
        } else {
          this.scrollListHeight = scrollHeight;
        }
      }
      scrollDom.createContent(this.marketListDom());
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      this.setListLoading(false);
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.market_item_star_label
        });
      }
      return list;
    },
    marketItemMinerDom(item, index) {
      console.log("marketItemMinerDom====", item);
      return {
        id: "market_item",
        content: {
          market_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollItemWidth * 4 - 40,
              height: scrollItemHeight * 4 - 40
            })
          },
          market_item_goods: {
            content: {
              market_item_goods_bg: {
                content: this.sf_taskItemImg({
                  width: scrollItemWidth * 4 - 140,
                  height: 430
                })
              },
              market_item_goods_miner: {
                content: this.m_setSpineAnmationsSprite(`${this.setMiner(item.star, "img")}-idle`, {
                  loop: true,
                  isPlay: true,
                  animationSpeed: 0.25
                })
              },
              market_item_goods_star: {
                content: this.starList(item)
              },
              market_item_goods_level: {
                content: `Lv.${item.level}`
              },
              market_item_tag: this.selectMTabInfo.value === 1 && {
                content: {
                  market_item_tag_main_1: item.trade === 0 && {
                    content: "Unsold",
                    styles: {
                      ...globalStyles.market_item_tag_main,
                      ...globalStyles.market_item_tag_traded
                    }
                  },
                  market_item_tag_main_2: item.trade === 1 && {
                    content: "Pending",
                    styles: {
                      ...globalStyles.market_item_tag_main,
                      ...globalStyles.market_item_tag_trading
                    }
                  },
                  market_item_tag_main_3: item.trade === 2 && {
                    content: "Sold",
                    styles: {
                      ...globalStyles.market_item_tag_main,
                      ...globalStyles.market_item_tag_success
                    }
                  }
                },
                styles: globalStyles.market_item_tag
              }
            }
          },
          market_item_name: {
            content: `${this.setMiner(item.star, "name")}`
          },
          market_item_pro: {
            content: `Power: ${this.toPre(item.power * this.powerTime, 0)}/h`
          },
          market_item_btns: {
            content: {
              market_item_btns_main: this.selectMTabInfo.value == 0 && this.m_btn({
                content: {
                  market_item_btns_bg: {
                    content: this.sf_btnBg({
                      width: scrollItemWidth * 4 - 110,
                      height: 150
                    }),
                    styles: globalStyles.market_item_btns_bg
                  },
                  market_item_btns_main: {
                    content: {
                      market_item_btns_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                        styles: globalStyles.market_item_btns_icon
                      },
                      market_item_btns_text: {
                        content: `${this.toPre(item.ton, 2)}`,
                        styles: globalStyles.market_item_btns_text
                      }
                    },
                    styles: globalStyles.market_item_btns_main
                  }
                },
                styles: globalStyles.market_item_btns_main
              }, () => {
                this.onBuy(item, index);
              }),
              market_item_btns_main_1: this.selectMTabInfo.value == 1 && item.trade === 0 && this.m_btn({
                content: {
                  market_item_btns_bg: {
                    content: this.sf_btnBg({
                      width: scrollItemWidth * 4 - 110,
                      height: 150
                    }),
                    styles: globalStyles.market_item_btns_bg
                  },
                  market_item_btns_main: {
                    content: {
                      market_item_btns_text: {
                        content: this.$lang("Unlist"),
                        styles: globalStyles.market_item_btns_text
                      }
                    },
                    styles: globalStyles.market_item_btns_main
                  }
                },
                styles: globalStyles.market_item_btns_main
              }, () => {
                this.onDelist(item, index);
              }),
              market_item_price: this.selectMTabInfo.value == 1 && (item.trade === 2 || item.trade === 1) && {
                content: {
                  market_item_price_main: {
                    content: {
                      market_item_price_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                      },
                      market_item_price_text: {
                        content: `${this.toPre(item.ton, 2)}`
                      }
                    }
                  }
                }
              }
            },
            styles: globalStyles.market_item_btns
          },
          market_item_id: {
            content: `ID: ${item.asset_id}`
          }
        },
        globalStyles
      };
    },
    marketItemMineDom(item, index) {
      console.log("item=======", item);
      return {
        id: "market_item",
        content: {
          mine_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollWidth * 4,
              height: 100 * 4
            }),
            styles: globalStyles.mine_item_bg
          },
          mine_item_main: {
            content: {
              mine_item_main_head: {
                content: {
                  mine_item_main_head_bg: {
                    content: this.sf_taskItemImg({
                      width: 300,
                      height: 300
                    }),
                    styles: globalStyles.mine_item_main_head_bg
                  },
                  mine_item_main_head_img: {
                    content: Sprite.from(this.setMine(item.type_id, "thumbnail")),
                    styles: globalStyles.mine_item_main_head_img
                  }
                },
                styles: globalStyles.mine_item_main_head
              },
              mine_item_main_info: {
                content: {
                  mine_item_main_info_title: {
                    content: this.setMine(item.type_id, "name"),
                    styles: globalStyles.mine_item_main_info_title
                  },
                  mine_item_main_info_reward: {
                    content: {
                      mine_item_main_info_reward_id: {
                        content: `ID: ${item.asset_id}`,
                        styles: globalStyles.mine_item_main_info_reward_id
                      },
                      mine_item_main_info_reward_title: {
                        content: `${this.$lang("Capacity")}: ${item.cap}`,
                        styles: globalStyles.mine_item_main_info_reward_title
                      },
                      mine_item_main_info_reward_num: (item.acce - 1) * 100 > 0 && {
                        content: `${this.$lang("Accelerate")} ${this.toPre((item.acce - 1) * 100, 2)}%`,
                        styles: globalStyles.mine_item_main_info_reward_num
                      }
                    },
                    styles: globalStyles.mine_item_main_info_reward
                  }
                },
                styles: globalStyles.mine_item_main_info
              },
              mine_item_main_info_btns: this.selectMTabInfo.value == 0 && this.m_btn({
                content: {
                  mine_item_main_info_btns_bg: {
                    content: this.sf_btnBg({
                      width: 380,
                      height: 150
                    }),
                    styles: globalStyles.mine_item_main_info_btns_bg
                  },
                  mine_item_main_info_btns_main: {
                    content: {
                      mine_item_main_info_btns_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                        styles: globalStyles.mine_item_main_info_btns_icon
                      },
                      mine_item_main_info_btns_text: {
                        content: `${this.toPre(item.ton, 2)}`,
                        styles: globalStyles.mine_item_main_info_btns_text
                      }
                    },
                    styles: globalStyles.mine_item_main_info_btns_main
                  }
                },
                styles: globalStyles.mine_item_main_info_btns
              }, () => {
                this.onBuy(item, index);
                // this.selectMine = item.type_id;
                // this.popBuyPromptShow = true;
                // console.log("click ok!");
                // console.log("mineList=====", this.mineList, this.myMineList);
              }),

              mine_item_main_info_btns_1: this.selectMTabInfo.value == 1 && item.trade === 0 && this.m_btn({
                content: {
                  mine_item_main_info_btns_bg: {
                    content: this.sf_btnBg({
                      width: 380,
                      height: 150
                    }),
                    styles: globalStyles.mine_item_main_info_btns_bg
                  },
                  mine_item_main_info_btns_main: {
                    content: {
                      mine_item_main_info_btns_text: {
                        content: this.$lang("Unlist"),
                        styles: globalStyles.mine_item_main_info_btns_text
                      }
                    },
                    styles: globalStyles.mine_item_main_info_btns_main
                  }
                },
                styles: globalStyles.mine_item_main_info_btns
              }, () => {
                this.onDelist(item, index);
              })
            },
            styles: globalStyles.mine_item_main
          },
          mine_item_tag: this.selectMTabInfo.value === 1 && {
            content: {
              mine_item_tag_main_1: item.trade === 0 && {
                content: "Unsold",
                styles: {
                  ...globalStyles.mine_item_tag_main,
                  ...globalStyles.mine_item_tag_traded
                }
              },
              mine_item_tag_main_2: item.trade === 1 && {
                content: "Pending",
                styles: {
                  ...globalStyles.mine_item_tag_main,
                  ...globalStyles.mine_item_tag_trading
                }
              },
              mine_item_tag_main_3: item.trade === 2 && {
                content: "Sold",
                styles: {
                  ...globalStyles.mine_item_tag_main,
                  ...globalStyles.mine_item_tag_success
                }
              }
            },
            styles: globalStyles.mine_item_tag
          },
          mine_item_price: this.selectMTabInfo.value == 1 && (item.trade === 2 || item.trade === 1) && {
            content: {
              market_item_price_main: {
                content: {
                  market_item_price_icon: {
                    content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                  },
                  market_item_price_text: {
                    content: `${this.toPre(item.ton, 2)}`
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    marketItemGoldDom(item, index) {
      return {
        id: "market_item",
        content: {
          market_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollItemWidth * 4 - 40,
              height: scrollItemHeight * 4 - 80
            })
          },
          market_item_goods: {
            content: {
              market_item_goods_bg: {
                content: this.sf_taskItemImg({
                  width: scrollItemWidth * 4 - 140,
                  height: 430
                }),
                styles: globalStyles.market_item_goods_bg
              },
              market_item_goods_gold: {
                content: Sprite.from(this.m_getRes("element").textures["icon-market-coin.png"]),
                styles: globalStyles.market_item_goods_gold
              },
              market_item_tag: this.selectMTabInfo.value === 1 && {
                content: {
                  market_item_tag_main_1: item.trade === 0 && {
                    content: "Unsold",
                    styles: {
                      ...globalStyles.market_item_tag_main,
                      ...globalStyles.market_item_tag_traded
                    }
                  },
                  market_item_tag_main_2: item.trade === 1 && {
                    content: "Pending",
                    styles: {
                      ...globalStyles.market_item_tag_main,
                      ...globalStyles.market_item_tag_trading
                    }
                  },
                  market_item_tag_main_3: item.trade === 2 && {
                    content: "Sold",
                    styles: {
                      ...globalStyles.market_item_tag_main,
                      ...globalStyles.market_item_tag_success
                    }
                  }
                },
                styles: globalStyles.market_item_tag
              }
            },
            styles: globalStyles.market_item_goods
          },
          market_item_name_icon: {
            content: `Coin x${this.toPre(item.amount)}`
          },
          market_item_btns: {
            content: {
              market_item_btns_main: this.selectMTabInfo.value == 0 && this.m_btn({
                content: {
                  market_item_btns_bg: {
                    content: this.sf_btnBg({
                      width: scrollItemWidth * 4 - 110,
                      height: 150
                    }),
                    styles: globalStyles.market_item_btns_bg
                  },
                  market_item_btns_main: {
                    content: {
                      market_item_btns_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                        styles: globalStyles.market_item_btns_icon
                      },
                      market_item_btns_text: {
                        content: `${this.toPre(item.ton, 2)}`,
                        styles: globalStyles.market_item_btns_text
                      }
                    },
                    styles: globalStyles.market_item_btns_main
                  }
                },
                styles: globalStyles.market_item_btns_main
              }, () => {
                this.onBuy(item, index);
              }),
              market_item_btns_main_1: this.selectMTabInfo.value == 1 && item.trade === 0 && this.m_btn({
                content: {
                  market_item_btns_bg: {
                    content: this.sf_btnBg({
                      width: scrollItemWidth * 4 - 110,
                      height: 150
                    }),
                    styles: globalStyles.market_item_btns_bg
                  },
                  market_item_btns_main: {
                    content: {
                      market_item_btns_text: {
                        content: this.$lang("Unlist"),
                        styles: globalStyles.market_item_btns_text
                      }
                    },
                    styles: globalStyles.market_item_btns_main
                  }
                },
                styles: globalStyles.market_item_btns_main
              }, () => {
                this.onDelist(item, index);
              }),
              market_item_price: this.selectMTabInfo.value == 1 && (item.trade === 2 || item.trade === 1) && {
                content: {
                  market_item_price_main: {
                    content: {
                      market_item_price_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                      },
                      market_item_price_text: {
                        content: `${this.toPre(item.ton, 2)}`
                      }
                    }
                  }
                }
              }
            },
            styles: {
              ...globalStyles.market_item_btns,
              ...globalStyles.market_item_btns_glod
            }
          }
        },
        globalStyles
      };
    },
    marketListDom() {
      const list = this.selectMTabInfo.value === 0 ? this.marketList : this.myMarketList;
      const marketItem = (it, idx) => {
        if (this.selectTabInfo.asset === "miner") {
          return this.marketItemMinerDom(it, idx);
        } else if (this.selectTabInfo.asset === "mine") {
          return this.marketItemMineDom(it, idx);
        } else if (this.selectTabInfo.asset === "coin") {
          return this.marketItemGoldDom(it, idx);
        }
      };
      return this.c_scrollBox(list.map((it, idx) => {
        return this.m_btn(marketItem(it, idx), null, {
          width: this.selectTabInfo.asset != "mine" ? scrollItemWidth : scrollWidth,
          height: this.selectTabInfo.asset === "miner" ? scrollItemHeight : this.selectTabInfo.asset === "mine" ? 108 : this.selectTabInfo.asset === "coin" ? scrollItemHeight - 10 : " "
        });
      }), {
        width: scrollWidth,
        height: this.scrollListHeight,
        vertPadding: 10
      });
    },
    renderDom() {
      return {
        content: {
          market_page: {
            content: {
              market_bg: {
                content: this.m_tilingSprite("bg5.jpg")
              },
              market_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_market`].visible = false;
                  this.$emit("update:show", false);
                })
              },
              market_coin: {
                content: this.$refs.assetsInfo1.renderDom({
                  coin: true,
                  diamond: true
                })
              },
              market_head: {
                content: {
                  market_head_bg: {
                    content: Sprite.from("market-title-bg"),
                    styles: this.m_isIOS() ? {
                      ...globalStyles.market_head_bg,
                      marginTop: -10
                    } : globalStyles.market_head_bg
                  },
                  market_m_tabs: {
                    content: this.c_main_tab(this.mTabList, this.isMTab, item => {
                      console.log("itemitem", item);
                      this.isMTab = item.value;
                      this.selectMTabInfo = item;
                    }, {
                      width: 150
                    })
                  },
                  market_info: this.m_btn({
                    content: Sprite.from(this.m_getRes("element").textures["icon-question.png"]),
                    styles: globalStyles.market_info
                  }, () => {
                    this.popMarketInfoShow = true;
                  })
                }
              },
              market_list: {
                content: {
                  market_list_bg: {
                    content: this.sf_marketGoods({
                      width: this.screenWidth * 4 - 160,
                      height: this.screenHeight * 4 - 370
                    })
                  },
                  market_tabs: {
                    content: this.c_tab(this.tabList, this.isTab, item => {
                      console.log("itemitem", item);
                      this.isTab = item.value;
                      this.selectTabInfo = item;
                    }, {
                      width: this.scrollWidth / 3
                    })
                  },
                  common_loading: isLoading.render(),
                  market_list_box: {
                    content: {
                      market_list_scroll: {
                        content: " ",
                        styles: globalStyles.market_list_scroll
                      },
                      market_list_page: {
                        content: {
                          market_list_page_main: {
                            content: " "
                          }
                        }
                      },
                      market_empty: {
                        content: this.c_empty(this.$lang("There's nothing at all."), {
                          isBtn: false
                        }, () => {
                          this.closePage();
                          this.$parent.popMyMarketDom.visible = false;
                          this.$emit("update:show", false);
                          this.setPageRecruitShow(true);
                        }),
                        styles: globalStyles.market_empty
                      },
                      market_list_own: {
                        content: this.m_btn({
                          content: {
                            market_list_own_bg: {
                              content: this.sf_rankingOwnBg({
                                width: this.screenWidth * 4 - 200,
                                height: 290
                              }),
                              styles: globalStyles.market_list_own_bg
                            },
                            market_list_text: {
                              content: " ",
                              styles: globalStyles.market_list_text
                            }
                          },
                          styles: {
                            width: "100%",
                            height: "100%"
                          }
                        }, async () => {
                          if (this.selectTabInfo.asset === "miner") {
                            this.popMyMarketShow = true;
                          } else if (this.selectTabInfo.asset === "mine") {
                            this.popMyMineShow = true;
                          } else if (this.selectTabInfo.asset === "coin") {
                            console.log(3344);
                            this.popCoinSellShow = true;
                          }
                        }),
                        styles: globalStyles.market_list_own
                      }
                    },
                    styles: globalStyles.market_list_box
                  },
                  market_list_box_1: {
                    content: {
                      market_empty_1: {
                        content: this.c_comingsoon("Coming Soon", {
                          isBtn: false
                        }),
                        styles: globalStyles.market_empty_1
                      }
                    },
                    styles: {
                      ...globalStyles.market_list_box,
                      ...globalStyles.market_list_box_1
                    }
                  }
                },
                styles: globalStyles.market_list
              }
            }
          }
        },
        globalStyles
      };
    },
    async onBuy(item, index) {
      this.selectRow = [];
      this.selectRow.push(item.id);
      await _v.m_ton();
      if (!this.accountInfo.wallet) {
        await this.bindWallet();
      }
      if (this.selectMTabInfo.value == 0 && this.selectTabInfo.asset === "miner") {
        this.popBuyOption.content = "Confirm purchase of this miner?";
      } else if (this.selectMTabInfo.value == 0 && this.selectTabInfo.asset === "mine") {
        this.popBuyOption.content = "Confirm purchase of this mine?";
      } else if (this.selectMTabInfo.value == 0 && this.selectTabInfo.asset === "coin") {
        this.popBuyOption.content = "Confirm purchase of this coin?";
      }
      this.popBuyOption.show = true;
    },
    onDelist(item, index) {
      this.selectRow = [];
      this.selectRow.push(item.id);
      this.popDelistOption.show = true;
    },
    async popMyMarketSubmit() {
      this.isTab = 0;
      this.selectTabInfo = {
        label: "MINER",
        value: 0,
        asset: "miner"
      };
      await this.getMarketList({
        asset: "miner"
      });
      this.loadMinerList();
      // if (this.$parent.$refs?.pageMinerRef) {
      //   const pageMinerRef = this.$parent.$refs.pageMinerRef;
      //   await this.getMinerList();
      //   pageMinerRef.loadMinerList();
      // }
    },

    async popDelistSubmit(callback) {
      const data = await this.removeMarket(this.selectRow[0]);
      if (!data) {
        this.$refs.marketDelistRef.setBtnLoading(false);
        return;
      }
      if (this.selectTabInfo.asset === "coin") {
        await this.getAccountInfo();
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
      }
      if (this.myMarketListPage.page > 1 && this.myMarketListTotal >= this.myMarketListPage.perPage) {
        this.setMyMarketListPage({
          page: this.myMarketListPage.page - 1
        });
      }
      await this.getMyMarketList({
        asset: this.selectTabInfo.asset
      });
      this.popDelistOption.show = false;
      this.popDelistDom.visible = false;
      typeof callback == "function" && callback();
      this.loadMinerList();
    },
    async popBuySubmit(callback) {
      const result = await this.marketBuy(this.selectRow);
      if (!result || result.code > 0) {
        if (result && result.code === 10000) {
          this.setMarketListPage({
            page: 1
          });
          if (this.marketListPage.page > 1 && this.marketListTotal >= this.marketListPage.perPage) {
            this.setMarketListPage({
              page: this.marketListPage.page - 1
            });
          }
          await this.getMarketList({
            asset: this.selectTabInfo.asset
          });
          this.$refs.marketBuyRef.setBtnLoading(false);
          this.popBuyDom.visible = false;
          this.popBuyOption.show = false;
          this.loadMinerList();
        } else {
          this.$refs.marketBuyRef.setBtnLoading(false);
          return;
        }
      }
      // console.log("this.selectTabInfo.asset ", this.selectTabInfo.asset);
      if (this.selectTabInfo.asset === "coin") {
        await this.getAccountInfo();
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
      }
      if (this.marketListPage.page > 1 && this.marketListTotal >= this.marketListPage.perPage) {
        this.setMarketListPage({
          page: this.marketListPage.page - 1
        });
      }
      await this.getMarketList({
        asset: this.selectTabInfo.asset
      });
      this.popBuyOption.show = false;
      this.popBuyDom.visible = false;
      typeof callback == "function" && callback();
      this.loadMinerList();
    },
    async popMyMineSubmit() {
      this.isTab = 1;
      this.selectTabInfo = {
        label: "MINE",
        value: 1,
        asset: "mine"
      };
      await this.getMarketList({
        asset: "mine"
      });
      this.loadMinerList();
    },
    async popCoinSellSubmit() {
      await this.getAccountInfo();
      await this.getMarketList({
        asset: "coin"
      });
      this.loadMinerList();
      this.$refs.assetsInfo1.renderDom({
        coin: true,
        diamond: true,
        type: "refresh"
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!_v[`page_market`]) {
        _v[`page_market`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_market`]);
      }
      _v[`page_market`].visible = false;
      this.pageDom = _v[`page_market`];
      this.setDom();
      this.setBtnSellShow();
    });
  }
};
const globalStyles = {
  market_page: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren"
  },
  market_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  market_diamond_coin: {
    marginTop: 15,
    position: "rightTop",
    marginRight: 135
  },
  market_coin: {
    marginTop: 15,
    position: "rightTop"
  },
  market_head: {
    position: "centerTop",
    height: 520,
    overflow: "hidden",
    marginTop: -10
  },
  market_head_bg: {
    // width: 100,
    position: "centerTop",
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenWidth * 0.352
  },
  market_list: {
    position: "centerTop",
    marginTop: 70,
    height: _v.screenHeight - 50,
    overflow: "hidden"
  },
  market_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  market_m_tabs: {
    marginTop: 60,
    position: "centerTop"
  },
  market_tabs: {
    marginTop: 55,
    position: "centerTop"
  },
  market_list_box: {
    width: "100%",
    height: "100%"
  },
  market_list_box_1: {
    position: "centerTop",
    visible: false
  },
  market_list_scroll: {
    marginTop: 95,
    position: "centerTop"
  },
  market_item: {
    // minWidth: "100%",
  },
  market_item_bg: {
    position: "centerTop",
    // maxWidth: "100%",
    scale: 0.25
  },
  market_item_goods: {
    position: "centerTop",
    marginTop: 11,
    width: "90%"
  },
  market_item_goods_bg: {
    position: "centerTop",
    scale: 0.25
  },
  market_item_goods_img: {
    position: "centerTop",
    marginTop: 10,
    scale: 0.25
  },
  market_item_goods_miner: {
    position: "centerTop",
    marginTop: 5,
    scale: 0.12
  },
  market_item_goods_mine: {
    position: "centerTop",
    marginTop: 15,
    scale: 0.25
  },
  market_item_goods_gold: {
    position: "centerTop",
    marginTop: -10,
    scale: 0.3
  },
  market_item_goods_tag: {
    background: "#232b36",
    color: "#676b71",
    fontSize: 12,
    fontFamily: "pFont",
    marginLeft: 15,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    marginTop: 5
  },
  market_item_goods_star: {
    position: "left",
    marginTop: 89,
    marginLeft: 15
  },
  market_item_star_label: {
    scale: 0.2,
    width: 16,
    height: 16
  },
  market_item_goods_level: {
    marginTop: 87,
    position: "right",
    fontSize: 16,
    fontFamily: "pFont",
    marginRight: 15,
    color: "#fff"
  },
  market_item_tag: {
    position: "leftTop",
    marginLeft: 15,
    marginTop: 8
  },
  market_item_tag_main: {
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    fontSize: 13,
    fontFamily: "pFont"
  },
  market_item_tag_traded: {
    background: "#232b36",
    color: "#676b71"
  },
  market_item_tag_trading: {
    background: "#e6a23c",
    color: "#fff"
  },
  market_item_tag_success: {
    background: "#67c23a",
    color: "#fff"
  },
  market_item_name: {
    position: "centerTop",
    marginTop: 120,
    fontFamily: "pFont",
    fontSize: 15,
    fill: "#2e3443"
  },
  market_item_id: {
    width: "100%",
    position: "centerBottom",
    marginBottom: 18,
    textAlign: "center",
    fontSize: 13,
    color: "#11131e",
    fontFamily: "pFont"
  },
  market_item_name_icon: {
    position: "centerTop",
    marginTop: 130,
    fontFamily: "pFont",
    fontSize: 16,
    fill: "#2e3443"
  },
  market_item_pro: {
    position: "centerTop",
    marginTop: 136,
    fontFamily: "pFont",
    fontSize: 12,
    fill: "#7f83a5"
  },
  market_item_price: {
    width: "80%",
    position: "center",
    background: "#cbc6b5",
    height: 30,
    borderRadius: 6
  },
  mine_item_price: {
    width: 90,
    position: "centerRight",
    background: "#cbc6b5",
    height: 30,
    borderRadius: 6,
    marginRight: 10,
    marginTop: 8
  },
  market_item_price_main: {
    position: "center",
    height: 20
  },
  market_item_price_icon: {
    scale: 0.25,
    marginTop: 2,
    width: 25
  },
  market_item_price_text: {
    fontSize: 18,
    color: "#111",
    fontFamily: "pFont"
  },
  market_item_btns: {
    position: "centerBottom",
    marginBottom: 32,
    width: "100%",
    height: 40
  },
  market_item_btns_mine: {
    marginTop: 132,
    width: "100%",
    height: 40,
    overflow: "hidden"
  },
  market_item_btns_glod: {
    marginBottom: 20,
    bottom: 135,
    height: 40
  },
  market_item_btns_bg: {
    position: "center",
    scale: 0.25
  },
  market_item_btns_main: {
    position: "center"
  },
  market_item_btns_main_1: {
    position: "center"
  },
  market_item_btns_icon: {
    width: 28,
    height: 28,
    scale: 0.28,
    marginTop: 3,
    marginRight: -4
  },
  market_item_btns_text: {
    fill: "#fff",
    fontSize: 18,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1
  },
  market_list_page: {
    // maxHeight: 30,
    position: "centerTop",
    width: scrollWidth - 5,
    background: "#364254",
    marginTop: scrollHeight + 90
  },
  market_list_page_main: {
    marginTop: 8,
    position: "center"
  },
  market_empty: {
    marginTop: 60,
    visible: false
  },
  market_empty_1: {
    marginTop: 80
  },
  market_list_own: {
    position: "centerBottom",
    height: 75,
    width: _v.screenWidth - 50,
    overflow: "hidden",
    marginBottom: 50
  },
  market_list_own_bg: {
    scale: 0.25
  },
  market_list_text: {
    position: "center",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    }),
    width: "100%",
    height: "100%"
  },
  mine_item_bg: {
    position: "center",
    maxWidth: "100%",
    scale: 0.25
  },
  mine_item_main: {
    position: "center",
    paddingLeft: 10
  },
  mine_item_main_head: {
    width: 75,
    height: 75,
    overflow: "hidden"
  },
  mine_item_main_head_bg: {
    scale: 0.25
  },
  mine_item_main_head_img: {
    position: "center",
    scale: 0.2
    // marginLeft: -3,
    // marginTop: -4,
  },

  mine_item_main_head_lock: {
    position: "center",
    scale: 0.25
  },
  mine_item_main_head_fee: {},
  mine_item_main_info: {
    width: scrollWidth - 100,
    height: 80,
    marginLeft: 5,
    paddingTop: 5
    // overflow: "hidden",
  },

  mine_item_main_info_title: {
    fontSize: 18,
    color: "#2e3443",
    fontFamily: "pFont"
  },
  mine_item_main_info_reward: {
    position: "leftTop",
    width: 450,
    height: "100%",
    marginTop: 22,
    marginLeft: 5
    // overflow: 'hidden'
  },

  mine_item_main_info_reward_id: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_num: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_title: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_btns: {
    position: "rightBottom",
    width: 100,
    height: 40,
    marginBottom: 5,
    overflow: "hidden"
  },
  mine_item_main_info_btns_bg: {
    scale: 0.25
  },
  mine_item_main_info_btns_main: {
    position: "center"
  },
  mine_item_main_info_btns_icon: {
    width: 28,
    height: 28,
    scale: 0.28,
    marginTop: 2,
    marginRight: -2
  },
  mine_item_main_info_btns_text: {
    marginTop: -2,
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  mine_item_main_info_tag: {
    scale: 0.3,
    marginRight: 5,
    // marginBottom: 5,
    position: "rightBottom"
  },
  mine_item_tag: {
    position: "rightTop",
    marginRight: 15,
    marginTop: 15
  },
  mine_item_tag_main: {
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    fontSize: 13,
    fontFamily: "pFont"
  },
  mine_item_tag_traded: {
    background: "#232b36",
    color: "#676b71"
  },
  mine_item_tag_trading: {
    background: "#e6a23c",
    color: "#fff"
  },
  mine_item_tag_success: {
    background: "#67c23a",
    color: "#fff"
  },
  market_info: {
    position: "centerTop",
    marginTop: 65,
    scale: 0.28,
    marginLeft: 135,
    width: 40,
    height: 40
  }
};