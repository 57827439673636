import { Assets, Sprite, Text } from "pixi.js";
window["disablePcPage"] = null;
export const gameDisablePc = async (app, dbHandler) => {
  Assets.addBundle("preload", {
    qrcode: "/assets/preload/qrcode.jpg",
  });
  await Assets.loadBundle("preload");

  const globalStyles = {
    disablePc: {
      position: "centerTop",
      width: _v.screenWidth,
      height: _v.screenHeight,
      maxWidth: _v.screenWidth,
      maxHeight: _v.screenHeight,
    },
    bg: {
      marginTop: 100,
      scale: 0.6,
      position: "centerTop",
    },
    info: {
      marginTop: 20,
      width: 100,
      position: "centerTop",
      fontSize: 20,
      width: "100%",
      textAlign: "center",
      color: "#fff",
    },
  };
  const setDom = () => {
    return {
      content: {
        disablePc: {
          content: {
            bg: {
              content: Sprite.from("qrcode"),
              styles: globalStyles.bg,
            },
            info: {
              content: " ",
              styles: globalStyles.info,
            },
          },
          styles: globalStyles.disablePc,
        },
      },
    };
  };
  return {
    render: () => {
      window["disablePcPage"] = _v.m_pageDom(setDom());
      app.stage.addChild(window["disablePcPage"]);
    },
    destroy: () => {
      if (window["disablePcPage"]) {
        window["disablePcPage"].destroy();
        app.stage.removeChild(window["disablePcPage"]);
      }
    },
    changeInfo: (content) => {
      let _content = content || "";
      if (window["disablePcPage"]) {
        const info = window["disablePcPage"].content.getByID("info");
        info.children[0].text = _content;
      }
    },
  };
};
