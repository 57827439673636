import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import gsap from "gsap";
import * as filters from "pixi-filters";
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.load("bg3");
await Assets.load("g");
await Assets.load("guide-hand");
export default {
  name: "recruit",
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo", "guideInfo"]),
    ...userVuex.mapState(["gameInfo", "accountInfo"]),
    isRender() {
      return this.$parent.pageRecruitShow;
    }
  },
  watch: {
    isRender: {
      handler(val) {
        if (val) {
          this.initScreen();
        } else {
          this.isFlag = true;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      currentScreen: null,
      pageDom: null,
      detail: {
        miner: "chaigong_1",
        star: 4
      },
      isFlag: true,
      popRecruitMinerShow: false,
      popRecruitInfoShow: false,
      popRecruitInfoDom: null
    };
  },
  methods: {
    ...gameVuex.mapMutations(["setSelectMinerInfo"]),
    ...gameVuex.mapActions(["recruitMinerFunc"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    playRecuitDefaultAnim() {
      const pageDom = this.pageDom;
      const g = pageDom.getChildByID("recruit_goods_bg");
      g.pivot.set(g.width / 2, g.height / 2);
      g.position.set(g.width / 8, g.height / 8);

      // const m = pageDom.getChildByID("recruit_goods_main");
      gsap.to(g, {
        duration: 20,
        rotation: 2 * Math.PI,
        // Rotate 360 degrees (2 * Math.PI radians)
        repeat: -1,
        // Infinite repeat
        ease: "linear" // Ensures constant speed
      });
      // gsap.to(m, {
      //   duration: 5,
      //   y: m.y - 10, // 向上移动 20 像素
      //   repeat: -1, // 无限循环
      //   yoyo: true, // 回到起始位置
      //   ease: "power1.inOut", // 使用缓动效果
      // });
    },

    playRecuitAnim() {
      const pageDom = this.pageDom;
      const g = pageDom.getChildByID("recruit_goods_bg");
      const recruit_btns = pageDom.getChildByID("recruit_btns");
      const recruit_btn_back = pageDom.getChildByID("recruit_btn_back");
      recruit_btns.visible = false;
      if (this.accountInfo.init <= 1) {
        recruit_btn_back.visible = false;
      }
      g.pivot.set(g.width / 2, g.height / 2);
      g.position.set(g.width / 8, g.height / 8);
      let count = 0;
      const targetCount = 2;
      const that = this;
      // 旋转动画函数
      function startRotation() {
        gsap.to(g, {
          duration: 2,
          // 每次旋转的持续时间
          rotation: g.rotation + 2 * Math.PI,
          // 旋转360度
          ease: "linear",
          // 保持恒定速度
          onComplete: () => {
            count++;
            if (count < targetCount) {
              startRotation();
            } else {
              that.playRecuitDefaultAnim();
              // 弹出弹窗
              recruit_btns.visible = true;
              that.popRecruitMinerShow = true;
              recruit_btn_back.visible = true;
              count = 0;
              that.isFlag = true;
              that.$nextTick(async () => {
                await that.getAccountInfo();
                that.$refs.assetsInfo1.renderDom({
                  coin: true,
                  diamond: true,
                  type: "refresh"
                });
                console.log(33334444, that.$refs.assetsInfo1.renderDom);
              });
            }
          }
        });
      }
      startRotation();
    },
    initScreen() {
      this.$nextTick(async () => {
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
        await this.setScreen();
        this.playRecuitDefaultAnim();
      });
    },
    async setScreen() {
      if (!_v[`page_recruit`]) {
        _v[`page_recruit`] = this.m_pageDom(this.renderDom());
        _v[`page_recruit`].visible = false;
        this.gameApp.stage.addChild(_v[`page_recruit`]);
      }
      _v[`page_recruit`].visible = true;
      this.pageDom = _v[`page_recruit`];
      this.m_getGuideInfo(this.pageDom, "recruit_btns_bg", {
        step: "step1",
        stage: 1,
        key: "coordinate"
      });
      _v[`page_recruit`].zIndex = this.gameApp.stage.children.length + 4;
    },
    renderDom() {
      return {
        content: {
          recruit_page: {
            content: {
              recruit_bg: {
                content: Sprite.from("bg3")
              },
              recruit_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_recruit`].visible = false;
                  this.$parent.pageRecruitShow = false;
                })
              },
              recruit_coin: {
                content: this.$refs.assetsInfo1.renderDom({
                  type: "dom",
                  coin: true,
                  diamond: true
                })
                // content: this.c_columnRender(`${this.toPre(this.accountInfo.coin)}`, { icon: this.m_getRes("element").textures["icon-coin.png"], width: 110 }),
              },

              recruit_main: {
                content: {
                  recruit_title: {
                    content: {
                      recruit_title_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["title-bg-1.png"])
                      },
                      recruit_title_text: {
                        content: "RECRUIT MINERS"
                      },
                      recruit_title_question: {
                        content: {
                          recruit_title_question_main: this.m_btn({
                            content: Sprite.from(this.m_getRes("element").textures["icon-question.png"]),
                            styles: globalStyles.recruit_title_question_main
                          }, () => {
                            this.popRecruitInfoShow = true;
                          })
                        },
                        styles: globalStyles.recruit_title_question
                      },
                      recruit_title_info: {
                        content: "Higher stars boost output rates further"
                      }
                    }
                  },
                  recruit_goods: {
                    content: {
                      recruit_goods_bg: {
                        content: Sprite.from("g")
                      },
                      recruit_goods_main: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-menu-recruit.png"])
                      }
                    }
                  },
                  recruit_btns: {
                    content: {
                      recruit_btns_upgrade: {
                        content: {
                          recruit_btns_upgrade_main: this.m_btn({
                            content: {
                              recruit_btns_bg: {
                                content: this.sf_btnSecondBg({
                                  width: this.screenWidth * 3.333 - 400,
                                  height: 220
                                }),
                                styles: globalStyles.recruit_btns_bg
                              },
                              recruit_btns_main: {
                                content: {
                                  recruit_btns_label_1: {
                                    content: "COIN RECRUITMENT",
                                    styles: globalStyles.recruit_btns_label
                                  },
                                  recruit_btns_value: {
                                    content: {
                                      recruit_btns_value_text: {
                                        content: `${this.gameInfo.coinRecruit ? this.toPre(this.gameInfo.coinRecruit) : "Fee"}`,
                                        styles: globalStyles.recruit_btns_value_text
                                      },
                                      recruit_btns_value_icon: {
                                        content: this.gameInfo.coinRecruit > 0 && Sprite.from(this.m_getRes("element").textures["icon-coin.png"]),
                                        styles: globalStyles.recruit_btns_value_icon
                                      }
                                    },
                                    styles: globalStyles.recruit_btns_value
                                  }
                                },
                                styles: globalStyles.recruit_btns_main
                              }
                            }
                          }, () => {
                            this.recruitMiner("coin");
                          })
                        }
                      },
                      recruit_btns_upgradeStar: this.m_btn({
                        content: {
                          recruit_btns_bg: {
                            content: this.sf_btnMainBg({
                              width: this.screenWidth * 3.333 - 400,
                              height: 220
                            }),
                            styles: globalStyles.recruit_btns_bg
                          },
                          recruit_btns_main: {
                            content: {
                              recruit_btns_label: {
                                content: "DIAMOND RECRUITMENT",
                                styles: globalStyles.recruit_btns_label
                              },
                              recruit_btns_value: {
                                content: {
                                  recruit_btns_value_text: {
                                    content: `${this.gameInfo.diamondRecruit ? this.toPre(this.gameInfo.diamondRecruit) : "Fee"}`,
                                    styles: globalStyles.recruit_btns_value_text
                                  },
                                  recruit_btns_value_icon: {
                                    content: Sprite.from(this.m_getRes("element").textures["icon-diamond.png"]),
                                    styles: globalStyles.recruit_btns_value_icon
                                  }
                                },
                                styles: globalStyles.recruit_btns_value
                              }
                            },
                            styles: globalStyles.recruit_btns_main
                          }
                        }
                      }, async () => {
                        await this.recruitMiner("diamond");
                      })
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    async recruitMiner(asset) {
      if (this.isFlag) {
        this.isFlag = false;
        const res = await this.recruitMinerFunc(asset);
        if (!res) {
          this.isFlag = true;
          return;
        }
        this.setSelectMinerInfo(res);
        this.m_getGuideInfo(null, null, {
          step: "step1",
          stage: 1,
          key: "is",
          value: true
        });
        console.log("guideInfo", this.guideInfo["step1"]);
        this.m_removeDom("recruitDefaultAnim");
        this.playRecuitAnim();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!_v[`page_recruit`]) {
        _v[`page_recruit`] = this.m_pageDom(this.renderDom());
        _v[`page_recruit`].visible = false;
        this.gameApp.stage.addChild(_v[`page_recruit`]);
      }
      this.pageDom = _v[`page_recruit`];
    });
  }
};
const globalStyles = {
  recruit_page: {
    position: "letTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren",
    zIndex: 2
  },
  recruit_bg: {
    maxWidth: "100%",
    position: "center"
  },
  recruit_coin: {
    marginTop: 20,
    position: "rightTop"
  },
  recruit_main: {
    position: "center",
    height: _v.screenHeight / 1.5,
    width: "100%"
  },
  recruit_title: {
    width: 300,
    height: 120,
    marginTop: -25,
    position: "centerTop"
    // overflow: "hidden",
  },

  recruit_title_bg: {
    position: "centerTop",
    scale: 0.3
  },
  recruit_title_text: {
    position: "center",
    marginTop: -33,
    height: 40,
    ..._v.fs_fontStyle({
      fz: 23,
      st: 2,
      ds: 2
    })
  },
  recruit_title_question: {
    scale: 0.3,
    width: 40,
    height: 40,
    position: "centerTop",
    marginLeft: 150 - 30,
    marginTop: -10
  },
  recruit_title_question_main: {
    width: 40,
    height: 40,
    position: "centerTop"
  },
  recruit_title_info: {
    fontSize: 13,
    color: "#fff",
    width: "100%",
    textAlign: "center",
    position: "centerTop",
    fontFamily: "pFontM",
    marginTop: 68
  },
  recruit_goods: {
    position: "center",
    width: 240,
    height: 240,
    overflow: "hidden"
  },
  recruit_goods_bg: {
    scale: 0.25
  },
  recruit_goods_main: {
    position: "center",
    marginLeft: 5,
    scale: 0.85
  },
  recruit_btns: {
    width: _v.screenWidth,
    position: "centerBottom",
    marginBottom: 0
  },
  recruit_btns_upgrade: {
    height: 65,
    width: "100%"
  },
  recruit_btns_upgrade_main: {
    width: "100%",
    height: "100%"
  },
  recruit_btns_upgradeStar: {
    height: 65,
    width: "100%",
    marginTop: 5
  },
  recruit_btns_bg: {
    position: "center",
    scale: 0.3
  },
  recruit_btns_main: {
    position: "center"
    // anchorX: 0,
  },

  recruit_btns_label: {
    position: "center",
    marginTop: -14,
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  recruit_btns_value: {
    position: "center",
    marginTop: 7,
    anchorX: 0.25,
    height: 25
  },
  recruit_btns_value_text: {
    ..._v.fs_fontStyle({
      fz: 20,
      st: 2,
      ds: 1
    })
  },
  recruit_btns_value_icon: {
    width: 20,
    scale: 0.22,
    marginTop: 2
  }
};