import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import "./assets/css/reset.scss";
import "./assets/icons"; //svg图标
import "./assets/fonts/font.css";
import Axios from "./http/index";
import router from "./router";
import common from "@game/common";
import gameAssets from "@game/gameAssets";
import animate from "@game/animate";
import uiComponents from "@game/uiComponents";
import spriteFrame from "@game/spriteFrame";
import fontStyle from "@game/fontStyle";
import filter from "@game/filter";
import m_init from "@game/index";
import gameMixin from "./mixins/gameMixin";
// import { FPS } from "yy-fps";
import Toasted from "vue-toasted";
import directive from "./directive";
import formatter from "./utils/formatter";
import loadConfig from "@tonWalletConfig/config";
import WebApp from "@twa-dev/sdk";
import ObjectPool from "@game/pool";
import music from "@game/music"

// import vconsole from "vconsole";
// if (loadConfig.debug) {
//   new vconsole();
// }
window._v = Vue.prototype;
_v.screenWidth = window.innerWidth;
_v.screenHeight = window.innerHeight;
WebApp.ready();
WebApp.expand();
const newFont = new FontFace("pFont", "url(/assets/font/bold.ttf)");
const loadedFont = await newFont.load();
document.fonts.add(loadedFont);
document.fonts.ready.then(function () {
  initProject();
});
Axios.install(Vue);

const options = {
  duration: 3000,
  position: "top-center",
  fullWidth: false,
};
Vue.use(Toasted, options);

async function initProject() {
  _v.WebApp = WebApp;
  for (let func in common) {
    _v[func] = common[func];
  }
  for (let func in music) {
    _v[func] = music[func];
  }

  for (let func in gameAssets) {
    _v[func] = gameAssets[func];
  }

  for (let func in animate) {
    _v[func] = animate[func];
  }

  for (let func in uiComponents) {
    _v[func] = uiComponents[func];
  }

  for (let func in spriteFrame) {
    _v[func] = spriteFrame[func];
  }

  for (let func in fontStyle) {
    _v[func] = fontStyle[func];
  }

  for (let func in filter) {
    _v[func] = filter[func];
  }

  for (let func in formatter) {
    _v[func] = formatter[func];
  }
  for (let func in formatter) {
    _v[func] = formatter[func];
  }

  _v["ObjectPool"] = ObjectPool;

  _v["page_index"] = null;
  _v["page_invite"] = null;
  _v["page_mine"] = null;
  _v["page_market"] = null;
  _v["page_payDiamond"] = null;
  _v["page_miner"] = null;
  _v["page_minerDetail"] = null;
  _v["page_recruit"] = null;
  _v["page_upgrade"] = null;

  _v["tickerCallbacks"] = [];
  _v.gameApp = await m_init();
  console.log(" _v.gameApp", _v.gameApp);

  Vue.mixin(gameMixin);

  // const fps = new FPS();
  // function update() {
  //   fps.frame();
  //   requestAnimationFrame(update);
  // }
  // update();

  // import VConsole from "vconsole";
  // new VConsole();

  Vue.config.warnHandler = function (msg, vm, trace) {
    // 忽略所有警告
  };

  Vue.use(directive);
  for (let func in formatter) {
    Vue.filter(func, formatter[func]);
  }
  _v.formatter = formatter;

  for (const key in loadConfig) {
    _v[key] = loadConfig[key];
  }

  _v.setMiner = (star, key) => {
    return _v.minerInfo[star][key];
  };
  _v.setMine = (id, key) => {
    return _v.mineInfo[id][key];
  };

  Vue.config.productionTip = false;

  _v.registerBodyClick = (isRegister, callback) => {
    isRegister ? document.body.addEventListener("click", callback) : document.body.removeEventListener("click", callback);
  };

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}
