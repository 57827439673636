import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
const ratio = 0.95;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 60 - 50 - 20;
const scrollItemWidth = scrollWidth;
const scrollItemHeight = 100;
const isLoading = _v.c_listLoading();
export default {
  name: "popMyMine",
  inject: ["closePage", "setPageMineShow"],
  props: {
    show: Boolean,
    position: {
      typeof: [String, Number],
      default: 0
    },
    parentDomName: {
      typeof: String,
      default: "pageDom"
    }
  },
  computed: {
    ...gameVuex.mapState(["gameApp", "myMineList", "myMineListPage", "myMineListTotal", "selectMinerInfo"]),
    ...userVuex.mapState(["token", "accountInfo"]),
    isRender() {
      return this.$parent.popMyMineShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
    // "myMineList.length": {
    //   handler(newLen, oldLen) {
    //     if (newLen != oldLen) {
    //       this.mineList = this.myMineList.filter((item) => {
    //         return item.status == 0 && item.type_id != 0;
    //       });
    //       console.log("this.mineListthis.mineList", this.mineList);
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },

  data() {
    return {
      popDom: null,
      tabList: [{
        label: "MINE",
        value: 0
      }, {
        label: "MY MINE",
        value: 1
      }],
      popSelectMinerPromptOption: {
        show: false,
        title: "Hint",
        content: "Is it for sale Miner?",
        type: "confirm",
        btnText: "Confirm"
      },
      selectMine: null,
      popSellShow: false,
      mineList: null,
      popSellDom: null
    };
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["getMyMineList"]),
    ...gameVuex.mapMutations(["setMyMineListPage", "setSelectMinerInfo"]),
    setListLoading(bool) {
      const scrollDom = this.$parent.popMyMineDom.content.getByID("miner_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    async initPop() {
      this.setMyMineListPage({
        page: 1,
        perPage: 10
      });
      if (!this.$parent.popMyMineDom) {
        this.$parent.popMyMineDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popMyMineDom);
      }
      this.$parent.popMyMineDom.visible = true;
      this.$parent.popMyMineDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popMyMineDom.position.set(0, 0);
      this.setListLoading(true);
      this.anim_pop(this.$parent.popMyMineDom, async () => {
        await this.getMyMineList({
          perPage: 8,
          page: 1,
          status: 0,
          type_id: 1
        });
        this.mineList = this.myMineList;
        this.loadMarketInfo();
      }, {
        y: 100
      });
    },
    loadMarketInfo() {
      const popDom = this.$parent.popMyMineDom;
      const miner_empty = popDom.content.getByID("miner_empty");
      if (this.mineList.length <= 0) {
        miner_empty.visible = true;
      } else {
        miner_empty.visible = false;
        const scrollDom = popDom.content.getByID("miner_list_scroll").content;
        scrollDom.createContent(this.myMineListDom(this.mineList));
        scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
        const miner_list_page_main = popDom.content.getByID("miner_list_page_main").content;
        miner_list_page_main.createContent(this.c_page(this.myMineListTotal, page => {
          this.setMyMineListPage({
            page
          });
          (async _page => {
            await this.getMyMineList({
              perPage: 8,
              page: 1,
              status: 0,
              type_id: 1
            });
            this.loadMarketInfo();
          })(page);
        }, this.myMineListPage));
        miner_list_page_main.removeContent(miner_list_page_main.children.entries().next().value[0]);
      }
      this.setListLoading(false);
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.miner_item_star_label
        });
      }
      return {
        ...list
      };
    },
    myMineListDom(list) {
      const minerItemDom = (item, index) => {
        return {
          id: "mine_item",
          content: {
            mine_item_bg: {
              content: this.sf_taskItemBg({
                width: scrollWidth * 4,
                height: scrollItemHeight * 4
              }),
              styles: globalStyles.mine_item_bg
            },
            mine_item_main: {
              content: {
                mine_item_main_head: {
                  content: {
                    mine_item_main_head_bg: {
                      content: this.sf_taskItemImg({
                        width: 300,
                        height: 300
                      }),
                      styles: globalStyles.mine_item_main_head_bg
                    },
                    mine_item_main_head_img: {
                      content: Sprite.from(this.setMine(item.type_id, "thumbnail")),
                      styles: globalStyles.mine_item_main_head_img
                    }
                  },
                  styles: globalStyles.mine_item_main_head
                },
                mine_item_main_info: {
                  content: {
                    mine_item_main_info_title: {
                      content: this.setMine(item.type_id, "name"),
                      styles: globalStyles.mine_item_main_info_title
                    },
                    mine_item_main_info_reward: {
                      content: {
                        mine_item_main_info_reward_id: {
                          content: `ID: ${item.id}`,
                          styles: globalStyles.mine_item_main_info_reward_id
                        },
                        mine_item_main_info_reward_title: {
                          content: `${this.$lang("Capacity")}: ${item.cap}`,
                          styles: globalStyles.mine_item_main_info_reward_title
                        },
                        mine_item_main_info_reward_num: (item.acce - 1) * 100 > 0 & {
                          content: `${this.$lang("Accelerate")} ${this.toPre((item.acce - 1) * 100, 2)}%`,
                          styles: globalStyles.mine_item_main_info_reward_num
                        },
                        mine_item_main_info_reward_check: {
                          content: Sprite.from(_v.m_getRes("element").textures["icon-ok.png"]),
                          styles: globalStyles.mine_item_main_info_reward_check
                        }
                      },
                      styles: globalStyles.mine_item_main_info_reward
                    }
                  },
                  styles: globalStyles.mine_item_main_info
                }
              },
              styles: globalStyles.mine_item_main
            }
          },
          styles: globalStyles.mine_item
        };
      };
      return this.c_scrollBox(list.map((it, idx) => {
        return this.m_btn(minerItemDom(it, idx), () => {
          this.onMinerItem(it, idx);
        }, {
          width: scrollItemWidth,
          height: scrollItemHeight + 10
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 45
      });
    },
    renderDom() {
      return {
        content: {
          pop_select: {
            content: {
              pop_select_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_select_con: {
                    content: {
                      miner_list: {
                        content: {
                          ommon_loading: isLoading.render(),
                          miner_list_scroll: {
                            content: " "
                          },
                          miner_list_page: {
                            content: {
                              miner_list_page_main: {
                                content: this.c_page(this.myMineListTotal, async page => {
                                  this.setMyMineListPage({
                                    page
                                  });
                                  await this.getMyMineList({
                                    status: 0,
                                    type_id: 1,
                                    page
                                  });
                                  this.mineList = this.myMineList;
                                  const miner_list_scroll = this.popDom.content.getByID("miner_list_scroll").content;
                                  miner_list_scroll.createContent(this.myMineListDom(this.myMineList));
                                  miner_list_scroll.removeContent(miner_list_scroll.children.entries().next().value[0]);
                                }, {
                                  page: this.myMineListPage.page,
                                  perPage: 9
                                })
                              }
                            }
                          },
                          miner_empty: {
                            content: this.c_empty(this.$lang("If there is no mine, go and buy it."), {
                              isBtn: true,
                              btnText: this.$lang("Buy mine")
                            }, () => {
                              this.closePage();
                              this.$parent.popMyMineDom.visible = false;
                              this.$emit("update:show", false);
                              this.setPageMineShow(true);
                            }),
                            styles: globalStyles.miner_empty
                          }
                        }
                      }
                    }
                  },
                  pop_select_head: {
                    content: {
                      pop_select_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4,
                          height: 60 * 4
                        })
                      },
                      pop_select_head_text: {
                        content: "SELECT MINE"
                      },
                      pop_select_close: {
                        content: this.c_btnClose(() => {
                          this.$parent.popMyMineDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    onMinerItem(it) {
      this.selectMine = {
        ...it,
        type: "mine"
      };
      this.popSellShow = true;
    },
    popSellSubmit() {
      this.$parent.popMyMineDom.visible = false;
      this.$emit("update:show", false);
      typeof callback == "function" && callback();
      this.$emit("submit");
    }
  }
};
const globalStyles = {
  pop_select: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_select_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "bottom",
    width: "100%",
    height: _v.screenHeight - 100
  },
  pop_select_head: {
    position: "centerTop",
    width: "100%",
    height: 50,
    marginTop: -20
  },
  pop_select_head_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_select_close: {
    position: "right",
    marginTop: 12
  },
  pop_select_head_text: {
    position: "center",
    width: "100%",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 19
    }),
    marginTop: -5
  },
  pop_select_con: {
    position: "centerTop",
    overflow: "hidden",
    width: "100%",
    background: "#364254"
  },
  miner_list: {
    position: "centerTop",
    height: "100%"
  },
  miner_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  miner_list_scroll: {
    position: "centerTop"
  },
  miner_list_page: {
    // maxHeight: 30,
    position: "centerTop",
    width: scrollWidth,
    background: "#364254",
    marginTop: scrollHeight - 25
  },
  miner_list_page_main: {
    marginTop: 8,
    position: "center"
  },
  mine_item_bg: {
    position: "center",
    maxWidth: "100%",
    scale: 0.25
  },
  mine_item_main: {
    position: "center",
    width: scrollItemWidth - 25
  },
  mine_item_main_head: {
    width: 75,
    height: 75,
    overflow: "hidden"
  },
  mine_item_main_head_bg: {
    scale: 0.25
  },
  mine_item_main_head_img: {
    position: "center",
    scale: 0.2
    // marginLeft: -3,
    // marginTop: -4,
  },

  mine_item_main_head_lock: {
    position: "center",
    scale: 0.25
  },
  mine_item_main_head_fee: {},
  mine_item_main_info: {
    width: scrollItemWidth - 75 - 5 - 20,
    height: 80,
    marginLeft: 5,
    paddingTop: 5,
    overflow: "hidden"
  },
  mine_item_main_info_title: {
    fontSize: 18,
    color: "#2e3443",
    fontFamily: "pFont"
  },
  mine_item_main_info_reward: {
    position: "leftTop",
    width: 450,
    height: "100%",
    marginTop: 22,
    marginLeft: 5
    // overflow: 'hidden'
  },

  mine_item_main_info_reward_id: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_title: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_num: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_title: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_check: {
    position: "topLeft",
    width: 100,
    height: 30,
    scale: 0.25,
    marginLeft: 170,
    marginTop: 20,
    visible: false
  },
  mine_item_main_info_btns: {
    position: "rightBottom",
    width: 100,
    height: 40,
    marginBottom: 5,
    overflow: "hidden"
  },
  mine_item_main_info_btns_bg: {
    scale: 0.25
  },
  mine_item_main_info_btns_main: {
    position: "center"
  },
  mine_item_main_info_btns_icon: {
    width: 28,
    height: 28,
    scale: 0.22
  },
  mine_item_main_info_btns_text: {
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  mine_item_main_info_tag: {
    scale: 0.3,
    marginRight: 5,
    // marginBottom: 5,
    position: "rightBottom"
  },
  miner_empty: {
    visible: false
  }
};