var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "goldminer"
    }
  }, [_c('pop-task', {
    attrs: {
      "show": _vm.popTaskShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popTaskShow = $event;
      }
    }
  }), _c('pop-ranking', {
    attrs: {
      "show": _vm.popRankingShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRankingShow = $event;
      }
    }
  }), _c('pop-select-miner', {
    attrs: {
      "position": _vm.selectPosition,
      "show": _vm.popSelectMinerShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSelectMinerShow = $event;
      }
    }
  }), _c('pop-upgrade-warehouse', {
    attrs: {
      "show": _vm.popUpgradeStorage
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpgradeStorage = $event;
      }
    }
  }), _c('assets-info', {
    ref: "assetsInfo"
  }), _c('pop-setting', {
    attrs: {
      "show": _vm.popSettingShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSettingShow = $event;
      }
    }
  }), _c('page-invite', {
    attrs: {
      "show": _vm.pageInviteShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageInviteShow = $event;
      }
    }
  }), _c('page-mine', {
    ref: "pageMineRef",
    attrs: {
      "show": _vm.pageMineShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageMineShow = $event;
      }
    }
  }), _c('page-recruit', {
    attrs: {
      "show": _vm.pageRecruitShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageRecruitShow = $event;
      }
    }
  }), _c('page-diamond', {
    attrs: {
      "show": _vm.pageDiamondShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageDiamondShow = $event;
      }
    }
  }), _c('page-miner', {
    ref: "pageMinerRef",
    attrs: {
      "show": _vm.pageMinerShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageMinerShow = $event;
      }
    }
  }), _c('page-miner-detail', {
    ref: "pageMinerDetailRef",
    attrs: {
      "show": _vm.pageMinerDetailShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageMinerDetailShow = $event;
      }
    }
  }), _c('page-market', {
    attrs: {
      "show": _vm.pageMarketShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageMarketShow = $event;
      }
    }
  }), _c('page-upgrade-star', {
    attrs: {
      "show": _vm.pageUpgradeStarShow
    },
    on: {
      "update:show": function ($event) {
        _vm.pageUpgradeStarShow = $event;
      }
    }
  }), _c('pop-prompt', {
    ref: "popBuyPositionRef",
    attrs: {
      "domName": "popBuyPositionDom",
      "show": _vm.popBuyPositionOption.show,
      "content": _vm.popBuyPositionOption.content
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.popBuyPositionOption, "show", $event);
      },
      "submit": _vm.popBuyPositionSubmit
    }
  }), _c('pop-prompt', {
    attrs: {
      "z-index": "20",
      "domName": "popInsufficientBalanceDom",
      "show": _vm.popInsufficientBalanceShow,
      "content": _vm.popInsufficientBalanceContent,
      "btnText": "Buy diamond"
    },
    on: {
      "update:show": function ($event) {
        _vm.popInsufficientBalanceShow = $event;
      },
      "submit": _vm.popInsufficientBalanceSumbit
    }
  }), _c('pop-switch-mine', {
    attrs: {
      "domName": "popSwitchMineDom",
      "show": _vm.popSwitchMineShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSwitchMineShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };