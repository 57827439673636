import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
const ratio = 0.95;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 60 - 50 - 20;
const scrollItemWidth = scrollWidth / 3;
const scrollItemHeight = 150;
const isLoading = _v.c_listLoading();
export default {
  name: "popMyMarket",
  inject: ["closePage", "setPageRecruitShow"],
  props: {
    show: Boolean,
    position: {
      typeof: [String, Number],
      default: 0
    },
    parentDomName: {
      typeof: String,
      default: "pageDom"
    }
  },
  computed: {
    ...gameVuex.mapState(["gameApp", "minerList", "minerListPage", "minerListTotal", "selectMinerInfo"]),
    ...userVuex.mapState(["token", "accountInfo"]),
    isRender() {
      return this.$parent.popMyMarketShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      tabList: [{
        label: "MINE",
        value: 0
      }, {
        label: "MY MINE",
        value: 1
      }],
      popSelectMinerPromptOption: {
        show: false,
        title: "Hint",
        content: "Is it for sale Miner?",
        type: "confirm",
        btnText: "Confirm"
      },
      selectMiner: null,
      popSellShow: false,
      popSellDom: null
    };
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["getMinerList"]),
    ...gameVuex.mapMutations(["setMinerListPage", "setSelectMinerInfo"]),
    setListLoading(bool) {
      const scrollDom = this.$parent.popMyMarketDom.content.getByID("miner_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    initPop() {
      this.setMinerListPage({
        page: 1,
        perPage: 9
      });
      if (!this.$parent.popMyMarketDom) {
        this.$parent.popMyMarketDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popMyMarketDom);
      }
      this.$parent.popMyMarketDom.visible = true;
      this.$parent.popMyMarketDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popMyMarketDom.position.set(0, 0);
      this.setListLoading(true);
      this.anim_pop(this.$parent.popMyMarketDom, async () => {
        await this.getMinerList({
          perPage: 9,
          page: 1,
          status: 0,
          shop: 1
        });
        this.loadMarketInfo();
      }, {
        y: 100
      });
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.miner_item_star_label
        });
      }
      return {
        ...list
      };
    },
    minerListDom() {
      const minerItemDom = (item, index) => {
        return {
          id: "miner_item",
          content: {
            miner_item_bg: {
              content: this.sf_taskItemBg({
                width: scrollItemWidth * 4 - 30,
                height: scrollItemHeight * 4
              }),
              styles: globalStyles.miner_item_bg
            },
            miner_item_main: {
              content: {
                miner_item_miner_bg: {
                  content: {
                    miner_item_miner_bg_main: {
                      content: this.sf_taskItemImg({
                        width: scrollItemWidth * 4 - 100,
                        height: 100 * 4 - 30
                      }),
                      styles: globalStyles.miner_item_miner_bg_main
                    }
                  },
                  styles: globalStyles.miner_item_miner_bg
                },
                miner_item_mine: {
                  content: {
                    miner_item_mine_main: {
                      content: this.m_setSpineAnmationsSprite(`${this.setMiner(item.star, "img")}-idle`, {
                        loop: true,
                        isPlay: true,
                        animationSpeed: 0.25
                      }),
                      styles: globalStyles.miner_item_mine_main
                    }
                  },
                  styles: globalStyles.miner_item_mine
                },
                miner_item_star: {
                  content: this.starList(item),
                  styles: globalStyles.miner_item_star
                },
                miner_item_level: {
                  content: `Lv.${item.level}`,
                  styles: globalStyles.miner_item_level
                }
              },
              styles: globalStyles.miner_item_main
            },
            miner_item_info: {
              content: {
                miner_item_name: {
                  content: `${this.setMiner(item.star, "name")}`,
                  styles: globalStyles.miner_item_name
                },
                minerDetail_info_productivity: {
                  content: `Power:${this.toPre(item.power * this.powerTime, 0)}/h`,
                  styles: globalStyles.minerDetail_info_productivity
                },
                miner_item_id: {
                  content: `ID: ${item.id}`,
                  styles: globalStyles.miner_item_id
                }
              },
              styles: globalStyles.miner_item_info
            }
          },
          styles: globalStyles.miner_item
        };
      };
      return this.c_scrollBox(this.minerList.map((it, idx) => {
        return this.m_btn(minerItemDom(it, idx), () => {
          this.onMinerItem(it, idx);
        }, {
          width: scrollItemWidth,
          height: scrollItemHeight + 5
          // gsap: true,
          // init: 0.98,
          // result: 1,
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 50
      });
    },
    renderDom() {
      console.log(66655);
      return {
        content: {
          pop_select: {
            content: {
              pop_select_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_select_con: {
                    content: {
                      miner_list: {
                        content: {
                          common_loading: isLoading.render(),
                          miner_list_scroll: {
                            content: " "
                          },
                          miner_list_page: {
                            content: {
                              miner_list_page_main: {
                                content: " "
                              }
                            }
                          },
                          miner_empty: this.c_empty(this.$lang("No miners, go recruit them"), {
                            isBtn: true,
                            btnText: this.$lang("Go Recruit")
                          }, () => {
                            this.closePage();
                            this.$parent.popMyMarketDom.visible = false;
                            this.$emit("update:show", false);
                            this.setPageRecruitShow(true);
                          })
                        }
                      }
                    }
                  },
                  pop_select_head: {
                    content: {
                      pop_select_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4,
                          height: 60 * 4
                        })
                      },
                      pop_select_head_text: {
                        content: "SELECT MINER"
                      },
                      pop_select_close: {
                        content: this.c_btnClose(() => {
                          this.$emit("update:show", false);
                          this.$parent.popMyMarketDom.visible = false;
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    loadMarketInfo() {
      const popDom = this.$parent.popMyMarketDom;
      const scrollDom = popDom.content.getByID("miner_list_scroll").content;
      scrollDom.createContent(this.minerListDom());
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      const miner_empty = popDom.content.getByID("miner_empty");
      if (this.minerList.length <= 0) {
        miner_empty.visible = true;
      } else {
        miner_empty.visible = false;
        const miner_list_page_main = popDom.content.getByID("miner_list_page_main").content;
        miner_list_page_main.createContent(this.c_page(this.minerListTotal, page => {
          this.setMinerListPage({
            page
          });
          (async _page => {
            await this.getMinerList({
              perPage: 9,
              page: 1,
              status: 0,
              shop: 1
            });
            this.loadMarketInfo();
          })(page);
        }, this.minerListPage));
        miner_list_page_main.removeContent(miner_list_page_main.children.entries().next().value[0]);
      }
      this.setListLoading(false);
    },
    onMinerItem(it) {
      this.selectMiner = {
        ...it,
        type: "miner"
      };
      this.popSellShow = true;
    },
    popSellSubmit(callback) {
      this.$parent.popMyMarketDom.visible = false;
      this.$emit("update:show", false);
      typeof callback == "function" && callback();
      this.$emit("submit");
    }
  }
};
const globalStyles = {
  pop_select: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_select_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "bottom",
    width: "100%",
    height: _v.screenHeight - 100
  },
  pop_select_head: {
    position: "centerTop",
    width: "100%",
    height: 50,
    marginTop: -20
  },
  pop_select_head_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_select_close: {
    position: "right",
    marginTop: 12
  },
  pop_select_head_text: {
    position: "center",
    width: "100%",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 19
    }),
    marginTop: -5
  },
  pop_select_con: {
    position: "centerTop",
    overflow: "hidden",
    width: "100%",
    background: "#364254"
  },
  miner_list: {
    position: "centerTop",
    height: "100%"
  },
  miner_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  miner_list_scroll: {
    position: "centerTop"
  },
  miner_list_page: {
    // maxHeight: 30,
    position: "centerTop",
    width: scrollWidth - 5,
    background: "#364254",
    marginTop: scrollHeight - 25
  },
  miner_list_page_main: {
    marginTop: 8,
    position: "center"
  },
  miner_item: {
    // anchorX: 0.5,
    // minWidth: "100%",
  },
  miner_item_bg: {
    position: "center",
    scale: 0.25
  },
  miner_item_main: {
    position: "centerTop",
    width: scrollItemWidth - 10,
    height: 95,
    marginTop: 10,
    overflow: "hidden"
  },
  miner_item_miner_bg: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  miner_item_miner_bg_main: {
    scale: 0.25,
    position: "center"
  },
  miner_item_mine: {
    width: "100%",
    height: "100%",
    position: "center"
  },
  miner_item_mine_main: {
    scale: 0.095,
    position: "center"
  },
  miner_item_star: {
    height: 8,
    position: "leftBottom",
    marginBottom: 12,
    marginLeft: 12
  },
  miner_item_star_label: {
    scale: 0.18,
    width: 12,
    height: 12
  },
  miner_item_name: {
    width: "100%",
    textAlign: "center",
    position: "centerTop",
    marginTop: 130,
    ..._v.fs_fontStyle({
      fz: 14,
      st: 1,
      ds: 1
    })
  },
  miner_item_id: {
    fontSize: 11,
    fontFamily: "pFont",
    color: "#2e3443",
    width: "100%",
    textAlign: "center"
  },
  miner_item_level: {
    position: "rightBottom",
    marginRight: 12,
    marginBottom: 4,
    fontSize: 13,
    fontFamily: "pFont",
    color: "#fff"
  },
  miner_item_info: {
    position: "centerBottom",
    width: "100%",
    height: 40,
    marginBottom: 10
  },
  miner_item_name: {
    fontSize: 11,
    fontFamily: "pFont",
    color: "#2e3443",
    width: "100%",
    textAlign: "center"
  },
  minerDetail_info_productivity: {
    fontSize: 10,
    fontFamily: "pFont",
    color: "#7f83a5",
    width: "100%",
    textAlign: "center"
  },
  miner_item_tag: {
    position: "leftTop",
    background: "#232b36",
    color: "#676b71",
    fontSize: 13,
    fontFamily: "pFont",
    marginLeft: 15,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    marginTop: 10
  },
  miner_empty: {
    visible: false
  }
};