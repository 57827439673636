var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-prompt', {
    ref: "popSelectMinerRef",
    attrs: {
      "domName": "popSelectMinerPromptDom",
      "show": _vm.popSelectMinerPromptOption.show,
      "btnType": _vm.popSelectMinerPromptOption.type,
      "btnText": _vm.popSelectMinerPromptOption.btnText,
      "title": _vm.popSelectMinerPromptOption.title,
      "content": _vm.popSelectMinerPromptOption.content
    },
    on: {
      "close": _vm.popSelectMinerPromptClose,
      "update:show": function ($event) {
        return _vm.$set(_vm.popSelectMinerPromptOption, "show", $event);
      },
      "submit": _vm.popSelectMinerPromptSubmint
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };