import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
export default {
  name: "popSell",
  props: {
    show: Boolean,
    asset: {
      default: () => {},
      typeof: Object
    },
    parentDomName: {
      typeof: String,
      default: "pageDom"
    }
  },
  computed: {
    ...gameVuex.mapState(["gameApp"]),
    ...userVuex.mapState(["accountInfo", "gameInfo"])
  },
  watch: {
    "$parent.popCoinSellShow": {
      handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      amount: 0,
      ton: 0
    };
  },
  methods: {
    ...gameVuex.mapActions(["marketAdd", "bindWallet"]),
    initPop() {
      if (!this.$parent.popCoinSellDom) {
        this.$parent.popCoinSellDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popCoinSellDom);
      }
      this.$parent.popCoinSellDom.visible = true;
      this.$parent.popCoinSellDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popCoinSellDom.position.set(0, 0);
      this.anim_pop(this.$parent.popCoinSellDom);
    },
    inputTonDom(option) {
      console.log("optionoptionoption", option);
      const input = this.c_inputx({
        ...option
      });
      return input;
    },
    renderDom() {
      return {
        content: {
          pop_sell: {
            content: {
              pop_sell_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_sell_con: {
                    content: {
                      pop_sell_main_bg: {
                        content: this.sf_popBg({
                          width: this.screenWidth * 4 - 300,
                          height: 360 * 4
                        })
                      },
                      pop_sell_box: {
                        content: {
                          pop_sell_main_title: {
                            content: "SELL"
                          },
                          pop_sell_count: {
                            content: {
                              pop_sell_input_title: {
                                content: "QUANTITY FOR SALE"
                              },
                              pop_sell_count_main: {
                                content: {
                                  pop_sell_count_a: this.m_btn({
                                    content: "-",
                                    styles: globalStyles.pop_sell_count_a
                                  }, () => {
                                    this.reduceAmount();
                                  }),
                                  pop_sell_count_value: {
                                    content: `${this.toPre(this.amount, 0)}`
                                  },
                                  pop_sell_count_m: this.m_btn({
                                    content: "+",
                                    styles: globalStyles.pop_sell_count_m
                                  }, () => {
                                    this.addAmount();
                                  })
                                }
                              },
                              pop_sell_count_info: {
                                content: {
                                  pop_sell_count_info_coin: {
                                    content: Sprite.from(this.m_getRes("element").textures["icon-mined-coin.png"])
                                  },
                                  pop_sell_count_info_value: {
                                    content: `${this.toPre(this.gameInfo.coinDiv, 0)}/parts`
                                  }
                                }
                              }
                            }
                          },
                          pop_sell_input: {
                            content: {
                              pop_sell_input_title: {
                                content: "SELLING PRICE"
                              },
                              pop_sell_input_main: {
                                content: {
                                  pop_sell_input_main_input: {
                                    content: this.inputTonDom({
                                      change: input => {
                                        if (input.text) {
                                          const regex = /^(?!0\d)\d+(\.\d{0,8})?$/;
                                          console.log("input.text", input.text, regex.test(input.text));
                                          if (!regex.test(input.text)) {
                                            input.text = 0;
                                            this.$toasted.clear();
                                            this.$toasted.error(this.$lang("input error"));
                                            return;
                                          }
                                          this.ton = input.text;
                                          const popCoinSellDom = this.$parent.popCoinSellDom;
                                          const pop_fee_text = popCoinSellDom.content.getByID("pop_fee_text");
                                          const pop_fee = popCoinSellDom.content.getByID("pop_fee").layout;
                                          pop_fee_text.children[0].text = `Fee: ${this.toPre(this.ton * this.gameInfo.marketFee, 2)}`;
                                          pop_fee.updateParents();
                                        }
                                      },
                                      width: _v.screenWidth - 160
                                    })
                                    // content: this.c_createPixiInput("123"),
                                  },

                                  pop_sell_input_main_icon: {
                                    content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                                  }
                                }
                              }
                            }
                          },
                          pop_fee: {
                            content: {
                              pop_fee_text: {
                                content: "Fee: --"
                              },
                              pop_fee_diamond: {
                                content: Sprite.from(this.m_getRes("element").textures["icon-diamond.png"])
                              },
                              pop_fee_question: {
                                content: {
                                  pop_fee_question_main: this.m_tooltip({
                                    content: Sprite.from(this.m_getRes("element").textures["icon-question1.png"])
                                  }, "The fee for selling coins must be paid in diamonds. If the order is not fulfilled, the system will refund the fee when the order is canceled.", {
                                    marginLeft: 500,
                                    fill: true
                                  })
                                }
                              }
                            }
                          }
                        }
                      },
                      pop_sell_btns: this.m_btn({
                        content: {
                          pop_sell_bn: {
                            content: {
                              pop_sell_btns_bg: {
                                content: this.sf_btnBg({
                                  width: _v.screenWidth * 4 - 540,
                                  height: 170
                                }),
                                styles: globalStyles.pop_sell_btns_bg
                              },
                              pop_sell_btns_text: {
                                content: "SELL",
                                styles: globalStyles.pop_sell_btns_text
                              }
                            },
                            styles: globalStyles.pop_sell_bn
                          }
                        }
                      }, () => {
                        this.onMarketAdd();
                      }),
                      pop_load: {
                        content: this.c_btnLoading(),
                        styles: globalStyles.pop_load
                      },
                      pop_sell_close: {
                        content: this.c_btnClose(() => {
                          this.setBtnLoading(false);
                          this.$parent.popCoinSellDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setBtnLoading(bool) {
      const popDom = this.$parent.popCoinSellDom;
      const btnDom = popDom.getChildByID("pop_sell_bn");
      const loadingDom = popDom.getChildByID("pop_load");
      console.log("btnDom", btnDom, loadingDom);
      if (bool) {
        btnDom.visible = false;
        loadingDom.visible = true;
      } else {
        btnDom.visible = true;
        loadingDom.visible = false;
      }
    },
    async onMarketAdd() {
      if (this.ton < 1) {
        return this.$toasted.error(this.$lang("Ton cannot be less than 1"));
      }
      const regex = /^(?!0\d)\d+(\.\d{0,8})?$/;
      if (!regex.test(this.ton)) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("input error"));
        return;
      }
      if (this.amount <= 0) {
        this.$toasted.error(this.$lang("please input coin quantity?"));
        return;
      }
      this.setBtnLoading(true);
      await _v.m_ton();
      if (!this.accountInfo.wallet) {
        await this.bindWallet();
      }
      const params = {
        asset: "coin",
        amount: this.amount,
        ton: this.ton
      };
      const data = await this.marketAdd(params);
      if (!data) {
        this.setBtnLoading(false);
        return;
      }
      this.setBtnLoading(false);
      this.$emit("update:show", false);
      this.$parent.popCoinSellDom.visible = false;
      this.$emit("submit");
    },
    addAmount() {
      const coinBalance = this.accountInfo.free_coin;
      // console.log("coinBalance", this.gameInfo.coinDiv, coinBalance, this.accountInfo.collected_coin ,this.accountInfo.sold_coin);
      const coinDiv = this.gameInfo.coinDiv;
      // console.log("coinBalance", coinBalance, coinDiv);
      this.amount = this.amount + coinDiv;
      if (coinBalance <= this.amount) {
        this.amount = parseInt(coinBalance / coinDiv) * coinDiv;
        this.$toasted.clear();
        this.$toasted.error(this.$lang("Insufficient Free coin!"));
      }
      const pop_sell_count_value = this.$parent.popCoinSellDom.content.getByID("pop_sell_count_value");
      console.log("pop_sell_count_value", pop_sell_count_value);
      pop_sell_count_value.children[1].text = `${this.toPre(this.amount, 0)}`;
    },
    reduceAmount() {
      const coinDiv = this.gameInfo.coinDiv;
      this.amount = this.amount - coinDiv;
      if (this.amount <= 0) {
        this.amount = 0;
        this.$toasted.clear();
        this.$toasted.error(this.$lang("please input coin quantity?"));
      }
      const pop_sell_count_value = this.$parent.popCoinSellDom.content.getByID("pop_sell_count_value");
      pop_sell_count_value.children[1].text = `${this.toPre(this.amount, 0)}`;
    }
  }
};
const globalStyles = {
  pop_task: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_sell_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_sell_con: {
    width: _v.screenWidth - 52,
    height: 385,
    position: "center",
    overflow: "hidden"
  },
  pop_sell_main_bg: {
    position: "center",
    scale: 0.25
  },
  pop_sell_box: {
    position: "center",
    width: "100%",
    height: "100%",
    padding: 25
  },
  pop_sell_main_title: {
    position: "centerTop",
    marginTop: 40,
    fill: "#fff",
    fontSize: 21,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1
  },
  pop_sell_input: {
    marginTop: 170,
    position: "leftTop",
    marginLeft: 55
  },
  pop_sell_input_title: {
    fill: "#868c94",
    fontSize: 15,
    height: 40,
    fontFamily: "pFont",
    position: "leftTop"
  },
  pop_sell_input_main: {
    position: "leftTop",
    marginTop: 40,
    width: _v.screenWidth - 160
  },
  pop_sell_input_main_icon: {
    scale: 0.32,
    position: "leftCenter",
    marginLeft: 5,
    background: "#28313f"
  },
  pop_sell_input_main_input: {
    position: "left",
    width: "60%"
  },
  pop_sell_count: {
    position: "leftTop",
    marginTop: 75,
    marginLeft: 55,
    height: 95
  },
  pop_sell_count_main: {
    overflow: "hidden",
    position: "leftTop",
    marginTop: 40,
    width: _v.screenWidth - 160
  },
  pop_sell_count_info: {
    position: "leftBottom"
  },
  pop_sell_count_info_coin: {
    scale: 0.2,
    width: 20,
    height: 20,
    marginRight: 5,
    marginTop: -1
  },
  pop_sell_count_info_value: {
    fontSize: 14,
    color: "#fff",
    fontFamily: "pFontM"
  },
  pop_sell_count_a: {
    background: "#28313f",
    fontSize: 18,
    color: "#fff",
    width: 30,
    height: 30,
    textAlign: "center",
    borderRadius: 4,
    fontFamily: "pFont",
    position: "leftCenter"
  },
  pop_sell_count_m: {
    background: "#28313f",
    fontSize: 18,
    color: "#fff",
    width: 30,
    height: 30,
    textAlign: "center",
    borderRadius: 4,
    fontFamily: "pFont",
    position: "center",
    marginLeft: 5,
    position: "rightCenter"
  },
  pop_sell_count_value: {
    height: 30,
    width: "70%",
    background: "#28313f",
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
    position: "center",
    fontFamily: "pFont",
    borderRadius: 4
  },
  pop_fee: {
    marginTop: 255,
    position: "leftTop",
    anchorX: 0,
    marginLeft: 55
  },
  pop_fee_text: {
    fontSize: 14,
    color: "#fff",
    fontFamily: "pFontM"
  },
  pop_fee_diamond: {
    scale: 0.18,
    width: 20,
    height: 20
  },
  pop_fee_question: {
    scale: 0.18,
    width: 20,
    height: 20
  },
  pop_sell_btns: {
    position: "centerBottom",
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginBottom: 40
  },
  pop_sell_bn: {
    width: "100%",
    height: "100%"
  },
  pop_sell_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_sell_btns_text: {
    fill: "#fff",
    fontSize: 16,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1,
    position: "center"
  },
  pop_sell_close: {
    position: "rightTop",
    marginRight: -10,
    marginTop: 20
  },
  pop_load: {
    position: "centerBottom",
    marginBottom: 30,
    anchorX: 0,
    visible: false
  }
};