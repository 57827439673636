import { Layout, LayoutSystem } from "@pixi/layout";
import { Spine } from "pixi-spine";
import { Sprite, Assets, AnimatedSprite, Ticker, NineSlicePlane, Point, TilingSprite, Texture } from "pixi.js";
import gsap from "gsap";
import router from "@/router";
import store from "@/store";
let payTimer = null;
export default {
  m_clearCurrentScreen: async (currentScreen) => {
    if (currentScreen) {
      await currentScreen.destroy();
      currentScreen = null;
    }
  },

  m_btn(content, callback, option) {
    const layout = new Layout(content);
    for (const key in option) {
      layout[key] = option[key];
    }
    layout.interactive = true;

    layout.onpointerdown = (e) => {
      // const scaleX = layout.scale._x;
      // const scaleY = layout.scale._y;
      // const initX = layout.x;
      // const initY = layout.y;
      // const initW = layout.width / 2;
      // const initH = layout.height / 2;
      // const initPx = layout.pivot.x;
      // const initPy = layout.pivot.y;
      // gsap.to(layout, { duration: 0.2, x: layout.x + layout.width / 2, y: layout.y + layout.height / 2, pivotX:layout.width / 2, pivotY:layout.height / 2, scaleX: layout.scale._x, scaleY: layout.scale._y});
      // gsap.to(layout.scale, {
      //   duration: 0.2,
      //   x: scaleX - 0.02,
      //   y: scaleY - 0.02,
      //   // scaleX: layout.scale._x,
      //   // scaleY: layout.scale._y,
      //   onUpdate: () => {
      //     console.log("开始");
      //     layout.pivot.set(initW, initH);
      //     layout.x = initX + initW;
      //     layout.y = initY + initH;
      //   },
      //   onComplete: () => {
      //     console.log("完成");
      //     layout.pivot.set(initPx, initPy);
      //     layout.x = initX;
      //     layout.y = initY;
      //     layout.scale.set(scaleX, scaleY)
      //   },
      // });
    };
    layout.onpointerup = (e) => {
      // const {scaleX, scaleY} = setBtnDom()
      // gsap.to(layout.scale, { duration: 0.2, x: scaleX, y: scaleY });
      // option?.gsap && option.gsap && gsap.to(layout.scale, { duration: option?.duration ? option?.duration : 0.2, x: option?.result ? option?.result : 1, y: option?.result ? option?.result : 1 });
      // typeof callback == "function" && callback(e);
      typeof callback == "function" && callback(e);
    };
    if (option?.isGrayscaleFilter) {
      return _v.f_grayscaleFilter(layout);
    }
    return layout;
  },
  m_storeBtn(content, callback, option) {
    const layout = new Layout(content);
    for (const key in option) {
      layout[key] = option[key];
    }
    layout.interactive = true;

    layout.onpointerdown = (e) => {
      const scaleX = layout.scale._x;
      const scaleY = layout.scale._y;
      gsap.to(layout.scale, {
        duration: 0.2,
        x: scaleX - 0.04,
        y: scaleY - 0.04,
        scaleX: layout.scale._x,
        scaleY: layout.scale._y,
      });
    };
    layout.onpointerup = (e) => {
      gsap.to(layout.scale, { duration: 0.2, x: 1, y: 1,  });
      // option?.gsap && option.gsap && gsap.to(layout.scale, { duration: option?.duration ? option?.duration : 0.2, x: option?.result ? option?.result : 1, y: option?.result ? option?.result : 1 });
      // typeof callback == "function" && callback(e);
      typeof callback == "function" && callback(e);
    };
    if (option?.isGrayscaleFilter) {
      return _v.f_grayscaleFilter(layout);
    }
    return layout;
  },

  m_otherBtn(content, callback, option) {
    const layout = new Layout(content);
    for (const key in option) {
      layout[key] = option[key];
    }
    layout.interactive = true;

    layout.on("pointerup", (event) => {
      typeof callback == "function" && callback(event);
      event.stopPropagation();
    });

    return layout;
  },

  m_getRes(file) {
    const _name = Assets.cache.get(file);
    return _name;
  },

  m_setSpineAnim(name, option, callback, isEvent) {
    const animation = Assets.get(name);
    console.log("animation", animation);
    const spine = new Spine(animation.spineData);
    console.log("animation", spine);
    spine.state.setAnimation(0, option?.state ? option.state : "running", option?.loop);
    spine.state.timeScale = option?.speed ? option.speed : 1;
    if (isEvent) {
      spine.state.addListener({
        complete: (entry) => {
          if (isEvent === 1 || isEvent === "1" || isEvent === "complete") typeof callback == "function" && callback("complete", entry);
        },
        dispose: (entry) => {
          if (isEvent === 1 || isEvent === "1" || isEvent === "dispose") typeof callback == "function" && callback("dispose", entry);
        },
        interrupted: (entry) => {
          if (isEvent === 1 || isEvent === "1" || isEvent === "interrupted") typeof callback == "function" && callback("interrupted", entry);
        },
        start: (entry) => {
          if (isEvent === 1 || isEvent === "1" || isEvent === "start") typeof callback == "function" && callback("start", entry);
        },
      });
    } else {
      // spine.state.removeListener
    }
    return spine;
  },
  m_setAnmationsSprite(animName, option = {}, callback) {
    console.log("_v[`a_${animName}Anim`]", _v[`a_${animName}Anim`]);
    // 定义帧数组
    const anim = new AnimatedSprite(_v[`a_${animName}Anim`]);
    if (!anim) return;
    // 设置动画属性
    anim.animationSpeed = option?.animationSpeed ? option.animationSpeed : 1; // 设置动画速度
    anim.loop = option?.loop ? option.loop : false;

    // 自定义播放控制
    anim.onFrameChange = (frame) => {
      if (frame === anim.totalFrames - 1) {
        anim.stop();

        setTimeout(
          () => {
            anim.gotoAndPlay(0);
          },
          option?.wait ? option.wait * 1000 : 10000
        );
      }
    };
    if (typeof callback == "function") {
      anim.onComplete = function () {
        callback(anim);
      };
    }
    option?.isPlay ? anim.play() : anim.stop(); // 播放动画

    return anim;
  },
  m_setSpineAnmationsSprite(animName, option = {}, callback) {
    // 定义帧数组
    const anim = new AnimatedSprite(_v[animName]);
    if (!anim) return;
    // 设置动画属性
    anim.animationSpeed = option?.animationSpeed ? option.animationSpeed : 1; // 设置动画速度
    anim.loop = option?.loop ? option.loop : false;
    callback == "function" && callback(anim);

    option?.isPlay ? anim.play() : anim.stop(); // 播放动画

    return anim;
  },

  m_switchSpine(spine, option) {
    spine.state.setAnimation(0, option?.state ? option.state : "idle", true);
    return spine;
  },

  m_setAnimationsSpriteSheet(animName, option = {}, callback) {
    const animations = Assets.cache.get(animName).animations;
    if (animations && animations[animName]) {
      const anim = new AnimatedSprite(animations[animName]);
      // 设置动画属性
      anim.animationSpeed = option?.animationSpeed ? option.animationSpeed : 1; // 设置动画速度
      anim.loop = option?.loop ? option.loop : false;
      callback == "function" && callback(anim);

      // 自定义播放控制
      anim.onFrameChange = (frame) => {
        if (frame === anim.totalFrames - 1) {
          anim.stop();
          setTimeout(
            () => {
              anim.gotoAndPlay(0);
            },
            option?.wait ? option.wait * 1000 : 10000
          );
        }
      };
      option?.isPlay ? anim.play() : anim.stop(); // 播放动画

      return anim;
    }
  },
  m_spriteFrame(texture, option) {
    let { leftWidth, topHeight, rightWidth, bottomHeight, width, height } = option;
    const slicePlane = new NineSlicePlane(texture, leftWidth, topHeight, rightWidth, bottomHeight);
    slicePlane.width = width;
    slicePlane.height = height;
    return slicePlane;
  },
  m_pageDom(node) {
    const layout = new Layout(node);
    layout.width = _v.screenWidth;
    layout.height = _v.screenHeight;
    layout.sortableChildren = true;
    layout.resize(_v.screenWidth, _v.screenHeight);
    if (window["page"]) {
      window["page"].destroy();
      _v.gameApp.stage.removeChild(window["page"]);
    }
    return layout;
  },
  m_popDom(node) {
    const layout = new Layout(node);
    layout.width = _v.screenWidth;
    layout.height = _v.screenHeight;
    layout.sortableChildren = true;
    layout.position.set(0, 0);
    return layout;
  },
  m_layoutDom(node, option) {
    const layout = new Layout(node);
    layout.width = option.width;
    layout.height = option.height;
    return layout;
  },
  m_renderDom(node, option) {
    const layout = new Layout(node);
    for (const key in layout) {
      layout[key] = option[key];
    }
    layout.width = _v.screenWidth;
    layout.height = _v.screenHeight;
    layout.sortableChildren = true;
    layout.resize(_v.screenWidth, _v.screenHeight);
    return layout;
  },
  m_setAlign(parent, son, center = "xy", offset = 0) {
    const parentW = parent.width / 2;
    const parentH = parent.height / 2;
    const sonW = son.width / 2;
    const sonH = son.height / 2;
    if (center == "x") {
      son.position.x = parentW + offset;
      son.pivot.x = sonW;
    } else if (center == "y") {
      son.position.y = parentH + offset;
      son.pivot.y = sonH;
    } else if (center == "xy") {
      son.position.x = parentW + (offset?.x ? offset.x : 0);
      son.pivot.x = sonW;
      son.position.y = parentH + (offset?.y ? offset.y : 0);
      son.pivot.y = sonH;
    } else if (center == "l") {
      son.position.x = 0 + offset;
      son.pivot.x = son.width;
    } else if (center == "r") {
      son.position.x = parent.width + offset;
      son.pivot.x = son.width;
    } else if (center == "t") {
      son.position.y = 0 + offset;
      son.pivot.y = son.height;
    } else if (center == "b") {
      son.position.y = parent.height + offset;
      son.pivot.y = son.height;
    } else if (center == "lx") {
      son.position.x = 0 + (offset?.x ? offset.x : 0);
      son.pivot.x = son.width;
      son.position.y = parentH + (offset?.y ? offset.y : 0);
      son.pivot.y = sonH;
    } else if (center == "rx") {
      son.position.x = parent.width + (offset?.x ? offset.x : 0);
      son.pivot.x = son.width;
      son.position.y = parentH + (offset?.y ? offset.y : 0);
      son.pivot.y = sonH;
    } else if (center == "bx") {
      son.position.y = parent.height + (offset?.y ? offset.y : 0);
      son.pivot.y = son.height;
      son.position.x = parentW + (offset?.x ? offset.x : 0);
      son.pivot.x = sonW;
    } else if (center == "tx") {
      son.position.x = parentW + (offset?.x ? offset.x : 0);
      son.pivot.x = sonW;
      son.position.y = 0 + (offset?.y ? offset.y : 0);
      son.pivot.y = son.height;
    }
  },
  m_updateDom(callback, option) {
    const speed = option?.speed ? option.speed : 1;
    let currentCallback = (delta) => {
      typeof callback == "function" && callback(delta * speed);
    };
    console.log("router", router);
    _v["tickerCallbacks"].push({
      route: router.currentRoute.name,
      key: option?.key ? option.key : null,
      ticker: currentCallback,
    });
    console.log('_v["tickerCallbacks"]', _v["tickerCallbacks"]);
    _v.gameApp.ticker.add(currentCallback);
  },
  m_removeDom(key, route) {
    const tickerCallbacks = _v["tickerCallbacks"];
    console.log("tickerCallbacks^^^^^", tickerCallbacks);
    //移除所有ticker
    if (!route && !key && tickerCallbacks.length > 0) {
      tickerCallbacks.forEach((item) => {
        _v.gameApp.ticker.remove(item.ticker);
        _v["tickerCallbacks"].length = 0;
      });
    } else if (route && !key && tickerCallbacks.length > 0) {
      //移除单页下所有ticker
      tickerCallbacks.forEach((item, index) => {
        if (route == item.route) {
          _v.gameApp.ticker.remove(item.ticker);
          _v["tickerCallbacks"] = _v["tickerCallbacks"].filter((it) => it?.route && it.route.indexOf(item.route) > 0);
        }
      });
    } else if (key && tickerCallbacks.length > 0) {
      //移除单个ticker
      tickerCallbacks.forEach((item) => {
        if (key == item.key) {
          _v.gameApp.ticker.remove(item.ticker);
          _v["tickerCallbacks"] = _v["tickerCallbacks"].filter((it) => it?.key && it.key.indexOf(item.key) > 0);
        }
      });
    }
  },
  m_clearIntervalFn() {
    console.log("准备清除所有定时器");
    for (let i = 1; i < 30000; i++) {
      clearInterval(i);
    }
  },
  m_getUserShortName: (item) => {
    let str = "";
    if (item.firstname) {
      str += item.firstname.substring(0, 1);
    }
    if (item.lastname) {
      str += item.lastname.substring(0, 1);
    }
    console.log(str);
    return str;
  },
  m_ton: (time = 1000) => {
    return new Promise(async (resolve, reject) => {
      console.log("_v.tonWallet", _v.tonWallet, _v.tonWallet.connected);
      await _v.tonWallet.openOrCloseTonModal(true);
      console.log("_v.tonConnect", _v.tonConnect, _v.tonWallet);
      clearInterval(payTimer);
      payTimer = setInterval(() => {
        if (_v.tonWallet.connected) {
          clearInterval(payTimer);
          resolve();
          payTimer = null;
        }
      }, time);
    });
  },
  m_getGlobalPoint: (dom) => {
    const globalPosition = dom.toGlobal(new Point(0, 0));
    return globalPosition;
  },
  m_getGuideInfo: (dom, SpecifyDom, option) => {
    if (store.state.user.accountInfo.init === 3) {
      return;
    }
    if (option.key === "is") {
      store.commit("game/setGuideInfo", {
        step: option.step,
        stage: option.stage,
        key: option.key,
        value: option.value,
      });
    } else {
      const specify_dom = dom.content.getByID(SpecifyDom);
      const _spcifyDom = { ..._v.m_getGlobalPoint(specify_dom), width: specify_dom.width / 3.32, height: specify_dom.height / 3.32 };
      store.commit("game/setGuideInfo", {
        step: option.step,
        stage: option.stage,
        key: option.key,
        value: _spcifyDom,
      });
    }
  },
  m_tilingSprite: (bgName, option) => {
    const texture = Assets.cache.get("bg5");
    const tilingSprite = new TilingSprite(texture, _v.screenWidth, _v.screenHeight);
    let scale = option?.scale ? option.scale : 0.25;
    tilingSprite.tileScale.set(scale, scale);
    return tilingSprite;
  },

  m_isIOS: () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    // iPadOS detection (since iPadOS 13, user agent contains "Macintosh")
    if (userAgent.includes("Mac") && "ontouchend" in document) {
      return true;
    }

    return false;
  },
};
