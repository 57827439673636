import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import noZoom from "./assets/js/noZoom";
import { guide } from "./mixins/guide";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  mixins: [guide],
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...gameVuex.mapState(["gameApp", "minerList"]),
    ...userVuex.mapState(["accountInfo"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true,
      isTelegramApplets: null,
      totalCoin: 0,
      popGuideShow: false
    };
  },
  watch: {
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("$route.path", newPath, oldPath);
      },
      deep: true
    }
  },
  methods: {
    ...gameVuex.mapMutations(["setGameApp"]),
    ...userVuex.mapActions(["getLoginInfo", "getAccountInfo"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.setGameApp(Vue.prototype.gameApp);
    });

    // if (this.$router.query.debug) {
    //   new vconsole();
    // }
    // this.$nextTick(async () => {
    //   let initData = this.WebApp.initData;
    //   if (initData) {
    //     await this.getLoginInfo([initData]);
    //     await this.getAccountInfo();
    //     console.log("this.accountInfo.init", this.accountInfo.init);
    //     this.isTelegramApplets = true;
    //   } else {
    //     this.isTelegramApplets = false;
    //   }
    // });
  }
};