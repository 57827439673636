import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.loadBundle("mine");
await _v.m_loadSpritesheet("/assets/spriteSheet/", ["head"]);
const isLoading = _v.c_listLoading(80);
const scrollItemWidth = 180;
const scrollItemHeight = 80;
export default {
  name: "popGoMine",
  inject: ["changeMinerDom", "setPageMinerDetailShow", "closePage", "changeScreenDom", "changeStroneDom"],
  props: {
    show: Boolean
  },
  computed: {
    ...gameVuex.mapState(["selectMinerInfo", "myMineList", "myMineListPage"]),
    ...userVuex.mapState(["accountInfo"])
  },
  watch: {
    show: {
      handler(bool) {
        console.log("343434", bool);
        if (bool) {
          console.log("this.btnType", this.btnType);
          this.initPop();
        } else {
          this.isSelectMineFlag = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      popGoMineShow: false,
      selectPosition: 0,
      popGoMineOption: {
        show: false,
        type: "confirm",
        title: "Hint",
        content: "Confirm to mine at this location?",
        btnText: "Confirm",
        isBtnClose: true
      },
      popGoMineDom: null,
      popLeaveWorkDom: null,
      popLeaveWorkshow: false,
      popLeaveWorkOption: {
        title: "STOP MINING",
        content: "Are you sure you want to stop mining?",
        type: "confirm"
      },
      popLeaveWorkIsCallback: true,
      selectMiner: null,
      isSelectMineFlag: true,
      popPromptSwitchTipShow: false,
      popPromptSwitchTipDom: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["changeMinerDefault", "getMyMineList", "subMinerWork", "getMinerList", "subMinerLeaveWork"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapMutations(["setSelectMinerInfo", "setMyMineListPage"]),
    initPop() {
      const pageDom = _v[`page_minerDetail`];
      if (!pageDom) return;
      if (!this.$parent.popGoMineDom) {
        this.$parent.popGoMineDom = this.m_popDom(this.renderDom());
        pageDom.addContent(this.$parent.popGoMineDom);
        this.setZindex();
      }
      this.$parent.popGoMineDom.visible = true;
      this.$parent.popGoMineDom.zIndex = this.gameApp.stage.children.length + 10;
      this.popDom = this.$parent.popGoMineDom;
      this.m_getGuideInfo(this.$parent.popGoMineDom, "pop_prompt_mine_map_mine_item_1", {
        step: "step2",
        stage: 1,
        key: "coordinate"
      });
      this.loadMineList();
      // this.anim_pop(this.$parent.popGoMineDom, null, {y: _v.screenHeight/2 - 500/2});
    },

    setZindex() {
      if (!this.$parent.popGoMineDom) return;
      const pop_main = this.$parent.popGoMineDom.getChildByID("pop_main");
      const pop_prompt_head = this.$parent.popGoMineDom.getChildByID("pop_prompt_head");
      const pop_prompt_content = this.$parent.popGoMineDom.getChildByID("pop_prompt_content");
      const pop_prompt_mine = this.$parent.popGoMineDom.getChildByID("pop_prompt_mine");
      pop_main.sortableChildren = true;
      pop_prompt_head.zIndex = 2;
      pop_prompt_content.zIndex = 1;
      pop_prompt_mine.zIndex = 3;
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.pop_prompt_mine_map_mine_item_pro_star_item
        });
      }
      return list;
    },
    getMineInfo() {
      let mineList = [];
      let cap = this.accountInfo?.mine.cap || 0;
      let miners = this.accountInfo?.miners;
      for (let i = 1; i <= cap; i++) {
        const miner = miners.filter(itm => i === itm.position);
        console.log("minerminerminerminer", miner);
        if (miner.length > 0) {
          mineList[`pop_prompt_mine_map_mine_item_${i}`] = this.m_btn({
            content: this.setMinerPosition(miner[0]),
            styles: globalStyles[`pop_prompt_mine_map_mine_item_${i}`]
          }, () => {
            this.selectMiner = miner[0];
            console.log("this.selectMiner", this.selectMiner);
            if (!this.selectMiner) return;
            this.popLeaveWorkshow = true;
          });
        } else {
          mineList[`pop_prompt_mine_map_mine_item_${i}`] = {
            content: {
              pop_prompt_mine_map_mine_item_add: this.m_btn({
                content: Sprite.from(this.m_getRes("head").textures["head-bg.png"]),
                styles: globalStyles.pop_prompt_mine_map_mine_item_add
              }, () => {
                this.addMiner(i);
              })
            },
            styles: globalStyles[`pop_prompt_mine_map_mine_item_${i}`]
          };
        }
      }
      console.log("mineListmineList======", mineList);
      return {
        ...mineList
      };
    },
    myMineItemDom(item, index) {
      return {
        id: "mine_item",
        content: {
          mine_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollItemWidth * 4,
              height: scrollItemHeight * 4
            }),
            styles: globalStyles.mine_item_bg
          },
          mine_item_head: {
            content: Sprite.from(this.setMine(item.type_id, "thumbnail")),
            styles: globalStyles.mine_item_head
          },
          mine_item_info: {
            content: {
              mine_item_info_id: {
                content: `Id: ${item.id}`,
                styles: globalStyles.mine_item_info_text
              },
              mine_item_info_cap: {
                content: `Cap: ${item.cap}`,
                styles: globalStyles.mine_item_info_text
              },
              mine_item_info_miner: {
                content: `Miner: ${item.miner}`,
                styles: globalStyles.mine_item_info_text
              }
            },
            styles: globalStyles.mine_item_info
          },
          mine_item_info_check: {
            content: Sprite.from(_v.m_getRes("element").textures["icon-ok.png"]),
            styles: globalStyles.mine_item_info_check
          }
        },
        styles: globalStyles.mine_item
      };
    },
    mineListDom() {
      return this.c_scrollBox(this.myMineList.map((it, idx) => {
        return this.m_btn(this.myMineItemDom(it, idx), () => {
          this.selectMine(it);
        }, {
          width: scrollItemWidth + 8,
          height: 100
        });
      }), {
        width: _v.screenWidth - 40,
        height: 160,
        vertPadding: 40,
        horPadding: 12,
        type: "horizontal"
      });
    },
    setListLoading(bool) {
      try {
        const scrollDom = this.$parent.popGoMineDom.content.getByID("mine_list_scroll");
        if (bool) {
          isLoading.show();
          scrollDom.visible = false;
        } else {
          isLoading.hide();
          scrollDom.visible = true;
        }
      } catch (err) {}
    },
    initMyMine() {
      const scrollDom = this.$parent.popGoMineDom.content.getByID("mine_list_scroll").children[1].children[1];
      console.log("scrollDom", scrollDom);
      this.myMineList.map((item, index) => {
        const mine_item_bg = scrollDom.children[index]?.getChildByID("mine_item_bg");
        const mine_item_info_check = scrollDom.children[index]?.getChildByID("mine_item_info_check");
        if (!mine_item_bg) {
          return;
        }
        if (this.myMineList[index].is_default) {
          mine_item_bg.children[0].texture = this.m_getRes("base").textures["mine-default-item-bg.png"];
          mine_item_info_check.visible = true;
        } else {
          mine_item_bg.children[0].texture = this.m_getRes("base").textures["task-item-bg.png"];
          mine_item_info_check.visible = false;
        }
      });
    },
    async loadMineList(loadingDom = true) {
      loadingDom && this.setListLoading(true);
      const popDom = this.$parent.popGoMineDom;
      this.setMyMineListPage({
        perPage: 20
      });
      const res = await this.getMyMineList({
        perPage: this.myMineListPage.perPage,
        page: this.myMineListPage.page,
        status: 1
      });
      if (!res) {
        this.popPromptSwitchTipDom.visible = false;
        this.popPromptSwitchTipShow = false;
        loadingDom && this.setListLoading(false);
        return;
      }
      console.log("resresresresres", res);
      const pop_prompt_mine = popDom.content.getByID("pop_prompt_mine");
      console.log("pop_prompt_minepop_prompt_mine", pop_prompt_mine);
      if (res.length <= 1) {
        pop_prompt_mine.visible = false;
      } else {
        pop_prompt_mine.visible = true;
        console.log(33334444);
      }
      const scrollDom = popDom.content.getByID("mine_list_scroll").content;
      if (!scrollDom) {
        loadingDom && this.setListLoading(false);
        return;
      }
      scrollDom.createContent(this.mineListDom());
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      this.setMapBg();
      this.initMyMine();
      this.setMinerMapDom();
      this.changeScreenDom();
      this.changeMinerDom();
      loadingDom && this.setListLoading(false);
    },
    renderDom() {
      return {
        content: {
          pop_go_mine: {
            content: {
              pop_prompt_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_prompt_head: {
                    content: {
                      pop_prompt_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_prompt_title: {
                        content: {
                          // pop_prompt_title_bg: {
                          //   content: Sprite.from(this.m_getRes("base").textures["title-bg.png"]),
                          // },
                          pop_prompt_title_text: {
                            content: "START MINING"
                          }
                        }
                      },
                      pop_prompt_close: {
                        content: this.c_btnClose(() => {
                          if (this.$parent.popGoMineDom) {
                            this.$parent.popGoMineDom.visible = false;
                          }
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_prompt_content: {
                    content: {
                      // pop_prompt_content_bg: {
                      //   content: this.sf_marketGoods({ width: this.screenWidth * 4 - 20, height: 525 * 4 + 70 }),
                      // },
                      pop_prompt_mine_map: {
                        content: {
                          pop_prompt_mine_map_bg: {
                            content: Sprite.from(this.setMine(this.accountInfo.mine.type_id, "positionBg"))
                          },
                          pop_prompt_mine_map_mine: {
                            content: {
                              pop_prompt_mine_map_mine_label: {
                                content: this.getMineInfo(),
                                styles: {
                                  width: "100%",
                                  height: "100%"
                                }
                              }
                            }
                            // content: {
                            //   pop_prompt_mine_map_mine_item_1: {
                            //     content: {
                            //       pop_prompt_mine_map_mine_item_add: this.m_btn(
                            //         {
                            //           content: Sprite.from(this.m_getRes("head").textures["head-bg.png"]),
                            //           styles: globalStyles.pop_prompt_mine_map_mine_item_add,
                            //         },
                            //         () => {
                            //           this.addMiner(1);
                            //         }
                            //       ),
                            //     },
                            //     styles: globalStyles.pop_prompt_mine_map_mine_item,
                            //   },
                            // },
                          }
                        }
                      }
                    }
                  },

                  pop_prompt_mine: {
                    content: {
                      pop_prompt_mine_bg: {
                        content: {
                          pop_prompt_mine_bg_main: {
                            content: this.sf_startMiningMineBg({
                              width: this.screenWidth * 4 - 160,
                              height: 450
                            })
                          }
                        }
                      },
                      pop_prompt_mine_list: {
                        content: {
                          common_loading: isLoading.render(),
                          mine_list_scroll: {
                            content: " "
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    addMiner(position) {
      this.m_getGuideInfo(null, null, {
        step: "step2",
        stage: 1,
        key: "is",
        value: true
      });
      if (this.accountInfo.init === 2) {
        this.popGoMineOption.isBtnClose = false;
      }
      this.selectPosition = position;
      this.popGoMineOption.show = true;
    },
    setMinerPosition(item) {
      return {
        content: {
          pop_prompt_mine_map_mine_item_pro: {
            content: {
              pop_prompt_mine_map_mine_item_pro_img: {
                content: Sprite.from(this.m_getRes("head").textures[`head-${this.setMiner(item.star, "img")}.png`]),
                styles: globalStyles.pop_prompt_mine_map_mine_item_pro_img
              },
              pop_prompt_mine_map_mine_item_pro_star: {
                content: this.starList({
                  star: item.star
                }),
                styles: globalStyles.pop_prompt_mine_map_mine_item_pro_star
              },
              pop_prompt_mine_map_mine_item_pro_level: {
                content: `Lv.${item.level}`,
                styles: globalStyles.pop_prompt_mine_map_mine_item_pro_level
              },
              pop_prompt_mine_map_mine_item_pro_productivity: {
                content: `Power ${this.toPre(item.power * this.powerTime, 0)}/h`,
                styles: globalStyles.pop_prompt_mine_map_mine_item_pro_productivity
              },
              pop_prompt_mine_map_mine_item_pro_id: {
                content: `ID: ${item.id}`,
                styles: globalStyles.pop_prompt_mine_map_mine_item_pro_id
              }
            },
            styles: globalStyles.pop_prompt_mine_map_mine_item_pro
          }
        }
      };
    },
    setMinerMapDom() {
      if (this.$parent.popGoMineDom) {
        const pop_prompt_mine_map_mine = this.$parent.popGoMineDom.content.getByID("pop_prompt_mine_map_mine").content;
        console.log("pop_prompt_mine_map_mine", pop_prompt_mine_map_mine);
        pop_prompt_mine_map_mine.createContent({
          content: {
            pop_prompt_mine_map_mine_label: {
              content: this.getMineInfo(),
              styles: {
                width: "100%",
                height: "100%"
              }
            }
          }
        });
        pop_prompt_mine_map_mine.removeContent(pop_prompt_mine_map_mine.children.entries().next().value[0]);
      }
    },
    async popGoWorkSubmit(callback) {
      switch (this.popGoMineOption.type) {
        case "confirm":
          this.popGoMineOption.type = "check";
          this.popGoMineOption.btnText = "Check";
          const minerId = this.selectMinerInfo.id;
          const res = await this.subMinerWork({
            id: this.selectMinerInfo.id,
            position: this.selectPosition,
            mine_id: this.accountInfo.mine.id
          });
          if (!res) {
            this.popGoMineOption.type = "confirm";
            this.popGoMineOption.btnText = "Confirm";
            this.popGoMineDom.visible = false;
            this.popGoMineOption.show = false;
            this.$refs.popGoMineRef.setBtnLoading(false);
            return;
          }
          await this.getAccountInfo(res);
          const newMinerInfo = this.accountInfo.miners.filter(item => {
            return item.id === minerId;
          });
          if (newMinerInfo.length > 0) {
            this.setSelectMinerInfo(newMinerInfo[0]);
          }
          this.popGoMineOption.show = false;
          this.$parent.loadMinerDetailInfo();
          await this.loadMineList();
          typeof callback == "function" && callback();
          break;
        case "check":
          this.popGoMineOption.type = "confirm";
          this.popGoMineOption.btnText = "Confirm";
          this.popGoMineOption.show = false;
          this.$emit("update:show", false);
          this.setPageMinerDetailShow(false);
          this.$nextTick(() => {
            this.popGoMineDom.visible = false;
          });
          this.closePage();
      }
    },
    async popGoMineClose() {
      if (this.popGoMineOption.type = "check") {
        this.$parent.loadMinerDetailInfo();
      }
      this.popGoMineOption.type = "confirm";
      this.popGoMineOption.btnText = "Confirm";
    },
    async popLeaveWorkSubmit(callback) {
      const res = await this.subMinerLeaveWork(this.selectMiner.id);
      if (!res) {
        this.$refs.popLeaveWorkRef.setBtnLoading(false);
        return;
      }
      this.popLeaveWorkshow = false;
      if (this.popLeaveWorkDom) {
        this.popLeaveWorkDom.visible = false;
      }
      if (this.selectMinerInfo.id === this.selectMiner.id) {
        this.$parent.loadMinerDetailInfo();
      }
      this.changeMinerDom();
      this.setMinerMapDom();
      typeof callback === "function" && callback();
    },
    setMapBg() {
      const popDom = this.$parent.popGoMineDom;
      const pop_prompt_mine_map_bg = popDom.content.getByID("pop_prompt_mine_map_bg");
      pop_prompt_mine_map_bg.children[0].texture = this.m_getRes(this.setMine(this.accountInfo.mine.type_id, "positionBg"));
    },
    async selectMine(it) {
      if (it.id === this.accountInfo.mine.id) {
        return;
      }
      this.popPromptSwitchTipShow = true;
      const data = await this.changeMinerDefault(it.id);
      if (!data) {
        this.popPromptSwitchTipDom.visible = false;
        this.popPromptSwitchTipShow = false;
        return;
      }
      await this.getAccountInfo(data);
      this.setMyMineListPage({
        perPage: 20
      });
      await this.loadMineList(false);
      this.$toasted.clear();
      this.$toasted.success(this.$lang("Switch successful"));
      this.popPromptSwitchTipDom.visible = false;
      this.popPromptSwitchTipShow = false;
    }
  }
};
const globalStyles = {
  pop_go_mine: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    position: "leftTop",
    marginLeft: 0
  },
  pop_prompt_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "centerTop",
    marginTop: _v.screenHeight / 2 - 280,
    width: _v.screenWidth,
    height: _v.screenWidth * 1.5114755
    // anchorY: 0.5,
    // overflow: "hidden",
  },

  pop_prompt_head: {
    marginTop: 10,
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50
  },
  pop_prompt_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_prompt_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_prompt_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_prompt_title_text: {
    marginTop: 25,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_prompt_close: {
    marginRight: 5,
    marginTop: 12,
    position: "rightTop"
  },
  pop_prompt_content: {
    position: "centerTop",
    marginTop: 20,
    width: _v.screenWidth,
    height: _v.screenWidth * 1.5114755
    // overflow: "hidden",
  },

  pop_prompt_content_bg: {
    position: "centerTop",
    scale: 0.235
  },
  pop_prompt_mine_map: {
    position: "centerTop",
    width: "100%",
    height: "100%"
  },
  pop_prompt_mine_map_bg: {
    // borderRadius: 120,
    position: "centerTop",
    maxWidth: _v.screenWidth - 30,
    border: 20
    // height:
  },

  pop_prompt_mine_map_mine: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_prompt_mine_map_mine_item_pro: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_prompt_mine_map_mine_item_add: {
    scale: 0.25,
    position: "center"
  },
  pop_prompt_mine_map_mine_item_1: {
    marginTop: -90,
    marginLeft: 90,
    position: "center",
    width: 100,
    height: 100
  },
  pop_prompt_mine_map_mine_item_2: {
    marginTop: 40,
    marginLeft: -50,
    position: "center",
    width: 100,
    height: 100
  },
  pop_prompt_mine_map_mine_item_3: {
    marginTop: 40,
    marginLeft: 120,
    position: "center",
    width: 100,
    height: 100
  },
  pop_prompt_mine_map_mine_item_4: {
    marginTop: -50,
    marginLeft: -50,
    position: "center",
    width: 100,
    height: 100
  },
  pop_prompt_mine_map_mine_item_pro: {
    position: "center",
    width: 100,
    height: 95
  },
  pop_prompt_mine_map_mine_item_pro_img: {
    scale: 0.25,
    position: "center"
  },
  pop_prompt_mine_map_mine_item_pro_productivity: {
    ..._v.fs_fontStyle({
      fz: 12
    }),
    position: "centerBottom"
  },
  pop_prompt_mine_map_mine_item_pro_id: {
    color: "#11131e",
    fontSize: 12,
    fontFamily: "pFont",
    position: "centerBottom",
    height: 20,
    marginBottom: -15
  },
  pop_prompt_mine_map_mine_item_pro_star: {
    position: "centerBottom",
    height: 20,
    marginBottom: 10
  },
  pop_prompt_mine_map_mine_item_pro_star_item: {
    scale: 0.16,
    width: 9,
    height: 9
  },
  pop_prompt_mine_map_mine_item_pro_level: {
    ..._v.fs_fontStyle({
      fz: 12,
      fill: "#eeea69"
    }),
    position: "centerTop",
    marginLeft: 5
  },
  pop_prompt_mine: {
    position: "centerBottom",
    width: _v.screenWidth,
    height: 180,
    overflow: "hidden",
    marginBottom: -2,
    visible: false
  },
  pop_prompt_mine_bg: {
    position: "center"
  },
  pop_prompt_mine_bg_main: {
    scale: 0.25,
    position: "center"
  },
  pop_prompt_mine_list: {
    position: "center",
    width: _v.screenWidth,
    height: 180
  },
  mine_list_scroll: {
    position: "center",
    width: _v.screenWidth - 40,
    overflow: "hidden",
    height: 180
  },
  mine_item_bg: {
    position: "center",
    scale: 0.25
  },
  mine_item_main: {},
  mine_item_head: {
    scale: 0.25,
    width: 50,
    height: 50,
    position: "left",
    marginLeft: 10
  },
  mine_item_info: {
    position: "centerRight",
    width: 60,
    height: 50,
    marginRight: 20
  },
  mine_item_info_text: {
    fontSize: 14,
    color: "#111",
    fontFamily: "pFont",
    width: 60,
    height: 15,
    textAlign: "left"
  },
  mine_item_info_check: {
    scale: 0.25,
    position: "rightTop",
    width: 35,
    height: 35,
    marginTop: 15,
    marginRight: 20,
    visible: false
  }
};