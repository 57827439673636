export default {
  sf_popTitleBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["pop-title-bg.png"], {
      width,
      height,
      leftWidth: 120,
      topHeight: 0,
      rightWidth: 120,
      bottomHeight: 0,
    });
  },
  sf_marketGoods: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["market-goods.png"], {
      width,
      height,
      leftWidth: 160,
      topHeight: 160,
      rightWidth: 160,
      bottomHeight: 160,
    });
  },
  sf_minerItemBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["minerItem-bg.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 20,
      rightWidth: 100,
      bottomHeight: 20,
    });
  },
  sf_minerItemElementBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["minerItem-element-bg.png"], {
      width,
      height,
      leftWidth: 25,
      topHeight: 20,
      rightWidth: 25,
      bottomHeight: 20,
    });
  },
  sf_minerDetailInfoBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["minerDetail-info-bg.png"], {
      width,
      height,
      leftWidth: 20,
      topHeight: 20,
      rightWidth: 20,
      bottomHeight: 20,
    });
  },
  sf_btnMainBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["btn-main-bg.png"], {
      width,
      height,
      leftWidth: 60,
      topHeight: 60,
      rightWidth: 60,
      bottomHeight: 60,
    });
  },
  sf_btnSecondBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["btn-second-bg.png"], {
      width,
      height,
      leftWidth: 60,
      topHeight: 60,
      rightWidth: 60,
      bottomHeight: 60,
    });
  },
  sf_btnThreeBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["btn-three-bg.png"], {
      width,
      height,
      leftWidth: 60,
      topHeight: 60,
      rightWidth: 60,
      bottomHeight: 60,
    });
  },
  sf_btnDisableBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["btn-disable-bg.png"], {
      width,
      height,
      leftWidth: 60,
      topHeight: 60,
      rightWidth: 60,
      bottomHeight: 60,
    });
  },
  sf_btnOtherBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["btn-other-bg.png"], {
      width,
      height,
      leftWidth: 40,
      topHeight: 40,
      rightWidth: 40,
      bottomHeight: 40,
    });
  },
  sf_taskItemBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["task-item-bg.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 60,
      rightWidth: 100,
      bottomHeight: 60,
    });
  },
  sf_marketGoods: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["market-goods.png"], {
      width,
      height,
      leftWidth: 160,
      topHeight: 160,
      rightWidth: 160,
      bottomHeight: 160,
    });
  },
  sf_rankingOwnBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["ranking-own-bg.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 80,
      rightWidth: 100,
      bottomHeight: 80,
    });
  },
  sf_rankingOwnNumBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["ranking-own-num-bg.png"], {
      width,
      height,
      leftWidth: 20,
      topHeight: 20,
      rightWidth: 20,
      bottomHeight: 20,
    });
  },
  sf_popTitleBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["pop-title-bg.png"], {
      width,
      height,
      leftWidth: 120,
      topHeight: 0,
      rightWidth: 120,
      bottomHeight: 0,
    });
  },
  sf_taskItemAssetBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["task-item-asset-bg.png"], {
      width,
      height,
      leftWidth: 20,
      topHeight: 20,
      rightWidth: 20,
      bottomHeight: 20,
    });
  },
  sf_taskItemImg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["task-item-img.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 60,
      rightWidth: 100,
      bottomHeight: 60,
    });
  },
  sf_btnBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["btn-bg.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 60,
      rightWidth: 100,
      bottomHeight: 60,
    });
  },
  sf_inviteContentBg: ({width, height})=> {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["invite-content-bg.png"], {
      width,
      height,
      leftWidth: 150,
      topHeight: 130,
      rightWidth: 150,
      bottomHeight: 130,
    })
  },
  sf_goodsItemBg: ({width, height})=> {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["goods-item-bg.png"], {
      width,
      height,
      leftWidth: 20,
      topHeight: 20,
      rightWidth: 20,
      bottomHeight: 20,
    })
  },
  sf_popBg: ({width, height})=> {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["pop-bg.png"], {
      width,
      height,
      leftWidth: 180,
      topHeight: 190,
      rightWidth: 180,
      bottomHeight: 190,
    })
  },
  sf_upgradeItemBg: ({ width, height }) => {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["upgrade_item_bg.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 60,
      rightWidth: 100,
      bottomHeight: 60,
    });
  },
  sf_startMiningMineBg: ({width, height})=> {
    return _v.m_spriteFrame(_v.m_getRes("base").textures["start-mining-mine-bg.png"], {
      width,
      height,
      leftWidth: 100,
      topHeight: 20,
      rightWidth: 100,
      bottomHeight: 80,
    })
  }
};
