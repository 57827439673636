import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.load("bg4");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
await Assets.load("guide-hand");
export default {
  name: "minerDetail",
  inject: ["changeMinerDom", "closePage", "setPageMarketShow"],
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo", "minerList"]),
    ...userVuex.mapState(["accountInfo", "gameInfo"]),
    isRender() {
      return this.$parent.pageMinerDetailShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          if (this.accountInfo.init === 2) {
            await this.getMinerList();
            this.setSelectMinerInfo(this.minerList[0]);
          }
          this.initScreen();
        }
      },
      // deep: true,
      immediate: true
    }
  },
  data() {
    return {
      currentScreen: null,
      pageDom: null,
      popSellShow: false,
      popGoMineShow: false,
      popLeaveWorkshow: false,
      popLeaveWorkOption: {
        title: "STOP MINING",
        content: "Are you sure you want to stop mining?",
        type: "confirm"
      },
      popUpgradeMinerShow: false,
      popLeaveWorkIsCallback: true,
      popUpgradeMierDom: null,
      popLeaveWorkDom: null,
      popGoMineDom: null,
      popSellDom: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["subMinerLeaveWork", "upgradeMiner", "getMinerList"]),
    ...gameVuex.mapMutations(["setSelectMinerInfo"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    initScreen() {
      this.$nextTick(() => {
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        }), this.setScreen();
      });
    },
    async setScreen() {
      if (!_v[`page_minerDetail`]) {
        _v[`page_minerDetail`] = this.m_pageDom(this.renderDom());
        _v[`page_minerDetail`].visible = false;
        this.gameApp.stage.addChild(_v[`page_minerDetail`]);
      }
      _v[`page_minerDetail`].visible = true;
      _v[`page_minerDetail`].zIndex = this.gameApp.stage.children.length + 5;
      this.pageDom = _v[`page_minerDetail`];
      this.m_getGuideInfo(this.pageDom, "minerDetail_btns_up_bg", {
        step: "step2",
        stage: 0,
        key: "coordinate"
      });
      console.log("this.accountInfo.initthis.accountInfo.init", this.accountInfo.init);
      this.loadMinerDetailInfo();
    },
    loadMinerDetailInfo() {
      console.log("this.selectMinerInfo==============", this.selectMinerInfo);
      const pageDom = this.pageDom.content;
      const minerDetail_info_level_value = pageDom.getByID("minerDetail_info_level_value");
      console.log("minerDetail_info_level_value", minerDetail_info_level_value);
      const minerDetail_info_productivity_value = pageDom.getByID("minerDetail_info_productivity_value");
      const minerDetail_info_id_value = pageDom.getByID("minerDetail_info_id_value");
      const minerDetail_miner_main = pageDom.getByID("minerDetail_miner_main");
      const minerDetail_info_name_text = pageDom.getByID("minerDetail_info_name_text");
      const minerDetail_info_name_tag = pageDom.getByID("minerDetail_info_name_tag");
      const minerDetail_btns_up_label = pageDom.getByID("minerDetail_btns_up_label");
      minerDetail_info_level_value.children[0].text = `${this.selectMinerInfo.level}`;
      minerDetail_info_productivity_value.children[0].text = `${this.toPre(this.selectMinerInfo.power * this.powerTime, 0)}/h`;
      minerDetail_info_id_value.children[0].text = `${this.selectMinerInfo.id}`;
      minerDetail_info_name_text.children[0].text = `${this.setMiner(this.selectMinerInfo.star, "name")}`;
      if (this.selectMinerInfo.status == 1) {
        minerDetail_info_name_tag.setStyles({
          marginLeft: 0
        });
        minerDetail_info_name_tag.visible = true;
      } else {
        minerDetail_info_name_tag.setStyles({
          marginLeft: -55
        });
        minerDetail_info_name_tag.visible = false;
      }
      minerDetail_btns_up_label.children[0].text = this.selectMinerInfo.status == 0 ? this.$lang("START MINING") : this.$lang("STOP MINING");
      minerDetail_miner_main.children[0].textures = _v[`${this.setMiner(this.selectMinerInfo.star, "img")}-idle`];
      minerDetail_miner_main.children[0].play();
      const minerDetail_info_star_text_main = pageDom.getByID("minerDetail_info_star_text_main");
      const minerDetail_info_star = pageDom.getByID("minerDetail_info_star");
      minerDetail_info_star_text_main.children.map((item, index) => {
        item.visible = index <= this.selectMinerInfo.star - 1;
      });
      // this.m_setAlign(minerDetail_info_star, minerDetail_info_star_text_main, "x", (this.selectMinerInfo.star - 1) * -12);
    },

    isMinerWorking() {},
    renderDom() {
      return {
        content: {
          minerDetail_page: {
            content: {
              minerDetail_bg: {
                content: Sprite.from("bg4")
              },
              minerDetail_btn_back: this.accountInfo.init !== 2 && {
                content: this.c_btnBack(() => {
                  if (this.$parent.pageMinerEntrance) {
                    this.$parent.pageMinerShow = true;
                    _v[`page_miner`].visible = true;
                    this.$parent.pageMinerEntrance = false;
                  }
                  _v[`page_minerDetail`].visible = false;
                  this.$parent.pageMinerDetailShow = false;
                })
              },
              minerDetail_coin: {
                // content: this.c_columnRender(`${this.toPre(this.accountInfo.coin, 0)}`, { icon: this.m_getRes("element").textures["icon-coin.png"], width: 110 }),
                content: this.$refs.assetsInfo1.renderDom({
                  type: "dom",
                  coin: true,
                  diamond: true
                })
              },
              minerDetail_miner: this.m_btn({
                content: {
                  minerDetail_miner_main: {
                    content: this.m_setSpineAnmationsSprite(`${this.setMiner(1, "img")}-idle`, {
                      loop: true,
                      isPlay: true,
                      animationSpeed: 0.25
                    }),
                    styles: globalStyles.minerDetail_miner_main
                  }
                }
              }, () => {
                this.switchMinerAnmation();
              }),
              minerDetail_info: {
                content: {
                  minerDetail_info_name: {
                    content: {
                      minerDetail_info_name_text: {
                        content: " "
                      },
                      minerDetail_info_name_tag: {
                        content: {
                          minerDetail_info_name_tag_main: {
                            content: "Mining"
                          }
                        }
                      }
                    }
                  },
                  minerDetail_info_main: {
                    content: {
                      minerDetail_info_star: {
                        content: {
                          minerDetail_info_bg: {
                            content: this.sf_minerDetailInfoBg({
                              width: this.screenWidth * 3 / 2 - 100,
                              height: 100
                            })
                          },
                          minerDetail_info_star_text: {
                            content: {
                              minerDetail_info_star_text_main: {
                                content: Array.from({
                                  length: this.gameInfo.maxMinerStar
                                }, () => ({
                                  content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
                                  styles: globalStyles.minerDetail_info_star_label
                                }))
                              }
                            }
                          }
                        }
                      },
                      minerDetail_info_level: {
                        content: {
                          minerDetail_info_bg: {
                            content: this.sf_minerDetailInfoBg({
                              width: this.screenWidth * 3 / 2 - 100,
                              height: 100
                            })
                          },
                          minerDetail_info_level_label: {
                            content: "Lv."
                          },
                          minerDetail_info_level_value: {
                            content: " "
                          }
                        }
                      },
                      minerDetail_info_productivity: {
                        content: {
                          minerDetail_info_bg: {
                            content: this.sf_minerDetailInfoBg({
                              width: this.screenWidth * 3 / 2 - 100,
                              height: 100
                            })
                          },
                          minerDetail_info_productivity_label: {
                            content: "Power"
                          },
                          minerDetail_info_productivity_value: {
                            content: " "
                          }
                        }
                      },
                      minerDetail_info_id: {
                        content: {
                          minerDetail_info_bg: {
                            content: this.sf_minerDetailInfoBg({
                              width: this.screenWidth * 3 / 2 - 100,
                              height: 100
                            })
                          },
                          minerDetail_info_id_label: {
                            content: "ID"
                          },
                          minerDetail_info_id_value: {
                            content: " "
                          }
                        }
                      }
                    }
                  }
                }
              },
              minerDetail_btns: {
                content: {
                  minerDetail_btns_upgrade: this.m_btn({
                    content: {
                      minerDetail_btns_upgrade_bg: {
                        content: this.sf_btnMainBg({
                          width: this.screenWidth * 3 / 2 - 100,
                          height: 220
                        }),
                        styles: globalStyles.minerDetail_btns_upgrade_bg
                      },
                      minerDetail_btns_upgrade_main: {
                        content: {
                          minerDetail_btns_upgrade_label: {
                            content: this.$lang("UPGRADE"),
                            styles: globalStyles.minerDetail_btns_upgrade_label
                          }
                        },
                        styles: globalStyles.minerDetail_btns_upgrade_main
                      }
                    }
                  }, () => {
                    if (this.selectMinerInfo.level < this.gameInfo.maxLevel) {
                      this.popUpgradeMinerShow = true;
                    } else {
                      this.$toasted.error(this.$lang("The Miner is Max Level"));
                    }
                  }),
                  minerDetail_btns_upgrade_question: {
                    content: {
                      minerDetail_btns_upgrade_question_main: this.m_tooltip({
                        content: Sprite.from(this.m_getRes("element").textures["icon-question1.png"])
                      }, "Upgrading miners directly increases\m the number of gold coins produced per hour.", {
                        fill: true
                      })
                    },
                    styles: globalStyles.minerDetail_btns_upgrade_question
                  },
                  minerDetail_btns_upgradeStar: this.m_btn({
                    content: {
                      minerDetail_btns_upgradeStar_bg: {
                        content: this.sf_btnSecondBg({
                          width: this.screenWidth * 3 / 2 - 100,
                          height: 220
                        }),
                        styles: globalStyles.minerDetail_btns_upgradeStar_bg
                      },
                      minerDetail_btns_upgrade_main: {
                        content: {
                          minerDetail_btns_upgrade_label: {
                            content: this.$lang("UPGRADE STAR"),
                            styles: globalStyles.minerDetail_btns_upgrade_label
                          }
                        },
                        styles: globalStyles.minerDetail_btns_upgrade_main
                      }
                    },
                    styles: globalStyles.minerDetail_btns_upgradeStar
                  }, () => {
                    this.onUpgradeMinerStar();
                  }),
                  minerDetail_btns_upgrade_question_ss: {
                    content: {
                      minerDetail_btns_upgrade_question_main: this.m_tooltip({
                        content: Sprite.from(this.m_getRes("element").textures["icon-question1.png"])
                      }, "Upgrading a miner's star level significantly increases the output increment with each upgrade.", {
                        fill: true
                      })
                    },
                    styles: globalStyles.minerDetail_btns_upgrade_question_ss
                  },
                  minerDetail_btns_sell: this.m_btn({
                    content: {
                      minerDetail_btns_sell_bg: {
                        content: this.sf_btnOtherBg({
                          width: this.screenWidth * 3 / 2,
                          height: 170
                        }),
                        styles: globalStyles.minerDetail_btns_sell_bg
                      },
                      minerDetail_btns_sell_main: {
                        content: {
                          minerDetail_btns_sell_icon: {
                            content: Sprite.from(this.m_getRes("element").textures["icon-nav-market.png"]),
                            styles: globalStyles.minerDetail_btns_sell_icon
                          },
                          minerDetail_btns_sell_label: {
                            content: this.$lang("SELL"),
                            styles: globalStyles.minerDetail_btns_sell_label
                          }
                        },
                        styles: globalStyles.minerDetail_btns_sell_main
                      }
                    },
                    styles: globalStyles.minerDetail_btns_sell
                  }, () => {
                    this.popSellShow = true;
                  }),
                  minerDetail_btns_up: this.m_btn({
                    content: {
                      minerDetail_btns_up_bg: {
                        content: this.sf_btnBg({
                          width: this.screenWidth * 3 / 2,
                          height: 170
                        }),
                        styles: globalStyles.minerDetail_btns_up_bg
                      },
                      minerDetail_btns_up_main: {
                        content: {
                          minerDetail_btns_up_label: {
                            content: "START MINING",
                            styles: globalStyles.minerDetail_btns_up_label
                          }
                        },
                        styles: globalStyles.minerDetail_btns_up_main
                      }
                    },
                    styles: globalStyles.minerDetail_btns_up
                  }, () => {
                    if (this.selectMinerInfo.status == 0) {
                      this.popGoMineShow = true;
                    } else if (this.selectMinerInfo.status == 1) {
                      this.popLeaveWorkshow = true;
                    }
                  })
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    onUpgradeMinerStar() {
      if (this.selectMinerInfo.star >= this.gameInfo.maxMinerStar) {
        this.$toasted.error(this.$lang("Miner has reached the maximum level!"));
        return;
      }
      if (this.selectMinerInfo.status == 1) {
        this.$toasted.error(this.$lang("Miner a is Mining!"));
        return;
      }
      console.log(66666);
      this.$parent.pageUpgradeStarShow = true;
      // this.$router.push("/upgrade");
    },

    onMinerItem(item, index) {
      this.$router.push("/mineDetail");
    },
    switchMinerAnmation() {},
    async popLeaveWorkSubmit(callback) {
      const res = await this.subMinerLeaveWork(this.selectMinerInfo.id);
      if (!res) {
        this.$refs.popLeaveWorkRef.setBtnLoading(false);
        return;
      }
      this.setSelectMinerInfo({
        ...this.selectMinerInfo,
        position: 0,
        status: 0
      });
      this.popLeaveWorkshow = false;
      if (this.popLeaveWorkDom) {
        this.popLeaveWorkDom.visible = false;
      }
      console.log("selectMinerInfo", this.selectMinerInfo);
      this.$refs.popGoMineRef.setMinerMapDom();
      this.loadMinerDetailInfo();
      this.changeMinerDom();
      typeof callback === "function" && callback();
    },
    async popSellSubmit() {
      // if (this.$parent.$refs?.pageMinerRef) {
      //   const pageMinerRef = this.$parent.$refs.pageMinerRef;
      //   await this.getMinerList();
      //   pageMinerRef.loadMinerList();
      // }
      this.closePage();
      this.setPageMarketShow(true);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!_v[`page_minerDetail`]) {
        _v[`page_minerDetail`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_minerDetail`]);
      }
      _v[`page_minerDetail`].visible = false;
      _v[`page_minerDetail`].zIndex = this.gameApp.stage.children.length + 5;
      this.pageDom = _v[`page_minerDetail`];
    });
  }
};
const globalStyles = {
  minerDetail_page: {
    position: "letTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren",
    zIndex: 2
  },
  minerDetail_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  minerDetail_coin: {
    position: "topRight",
    marginTop: 15
  },
  minerDetail_miner: {
    // position: "center",
    height: 440,
    width: "100%",
    overflow: "hidden",
    position: "centerTop",
    marginTop: 50
  },
  minerDetail_miner_main: {
    scale: 0.28,
    position: "centerTop",
    height: "100%"
  },
  minerDetail_info: {
    width: "80%",
    position: "centerTop",
    marginTop: 280
  },
  minerDetail_info_name: {
    display: "line",
    position: "centerTop"
  },
  minerDetail_info_name_text: {
    ..._v.fs_fontStyle({
      fz: 26,
      st: 3,
      ds: 1
    })
  },
  minerDetail_info_name_tag: {
    marginTop: 5,
    marginTop: 12,
    marginLeft: 8
  },
  minerDetail_info_name_tag_main: {
    width: 55,
    textAlign: "center",
    background: "#f9af25",
    fontFamily: "pFont",
    height: 20,
    borderRadius: 4,
    ..._v.fs_fontStyle({
      fz: 13,
      st: 1,
      ds: 0
    })
  },
  minerDetail_info_main: {
    marginTop: 60,
    position: "centerTop"
  },
  minerDetail_info_star: {
    height: 30,
    width: "50%",
    overflow: "hidden"
  },
  minerDetail_info_bg: {
    scale: 0.3,
    position: "centerTop"
  },
  minerDetail_info_star_text: {
    position: "leftTop",
    marginTop: 4,
    marginLeft: 15
  },
  minerDetail_info_star_text_main: {
    position: "centerTop"
  },
  minerDetail_info_star_label: {
    scale: 0.25,
    width: 20
  },
  minerDetail_info_level: {
    height: 40,
    width: "50%"
  },
  minerDetail_info_level_label: {
    fontSize: 18,
    fontFamily: "pFont",
    color: "#7f83a5",
    position: "left",
    marginLeft: 12,
    marginTop: 2
  },
  minerDetail_info_level_value: {
    fontSize: 16,
    fontFamily: "pFont",
    color: "#fff",
    position: "right",
    marginRight: 12,
    marginTop: 4
  },
  minerDetail_info_productivity: {
    height: 40,
    width: "50%",
    position: "left",
    marginTop: 40
  },
  minerDetail_info_productivity_label: {
    fontSize: 16,
    fontFamily: "pFont",
    color: "#7f83a5",
    position: "leftTop",
    marginLeft: 12,
    marginTop: 4
  },
  minerDetail_info_productivity_value: {
    fontSize: 16,
    fontFamily: "pFont",
    color: "#fff",
    position: "right",
    marginRight: 12,
    marginTop: 4
  },
  minerDetail_info_id: {
    height: 40,
    width: "50%",
    position: "right",
    marginTop: 40
  },
  minerDetail_info_id_label: {
    fontSize: 16,
    fontFamily: "pFont",
    color: "#7f83a5",
    position: "leftTop",
    marginLeft: 12,
    marginTop: 4
  },
  minerDetail_info_id_value: {
    fontSize: 16,
    fontFamily: "pFont",
    color: "#fff",
    position: "right",
    marginRight: 12,
    marginTop: 4
  },
  minerDetail_btns: {
    width: "80%",
    position: "centerTop",
    marginTop: 440
  },
  minerDetail_btns_upgrade: {
    position: "right",
    width: "50%",
    height: 65,
    // overflow: "hidden",
    position: "left"
  },
  minerDetail_btns_upgrade_bg: {
    scale: 0.3,
    position: "center"
  },
  minerDetail_btns_upgrade_main: {
    position: "center",
    textAlign: "center"
  },
  minerDetail_btns_upgrade_label: {
    position: "center",
    // marginTop: -12,
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  minerDetail_btns_upgrade_question: {
    position: "left",
    marginLeft: 0,
    marginTop: -10,
    scale: 0.28
  },
  minerDetail_btns_upgrade_question_ss: {
    position: "right",
    marginRight: -5,
    marginTop: -10,
    scale: 0.28
  },
  minerDetail_btns_upgrade_value: {
    marginTop: 8,
    position: "center",
    height: 25,
    marginLeft: 50
  },
  minerDetail_btns_upgrade_value_label: {
    ..._v.fs_fontStyle({
      fz: 21,
      st: 2,
      ds: 1,
      fill: "#eeea69"
    })
  },
  minerDetail_btns_upgrade_icon: {
    scale: 0.22,
    marginTop: 2
  },
  minerDetail_btns_upgradeStar: {
    position: "right",
    width: "50%",
    height: 65
    // overflow: "hidden",
  },

  minerDetail_btns_upgradeStar_bg: {
    scale: 0.3,
    position: "center"
  },
  minerDetail_btns_upgradeStar_label: {
    position: "center",
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  minerDetail_btns_sell: {
    marginTop: 65,
    position: "right",
    width: "50%",
    height: 65,
    overflow: "hidden"
  },
  minerDetail_btns_sell_bg: {
    scale: 0.25,
    position: "center"
  },
  minerDetail_btns_sell_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  minerDetail_btns_sell_icon: {
    scale: 0.28,
    width: 45,
    height: 45,
    position: "left",
    marginLeft: 15,
    marginTop: 10
  },
  minerDetail_btns_sell_label: {
    ..._v.fs_fontStyle({
      fz: 17,
      st: 2,
      ds: 1
    }),
    position: "center",
    marginLeft: 15
  },
  minerDetail_btns_up: {
    marginTop: 65,
    position: "left",
    width: "50%",
    height: 65,
    overflow: "hidden"
  },
  minerDetail_btns_up_bg: {
    scale: 0.25,
    position: "center"
  },
  minerDetail_btns_up_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  minerDetail_btns_up_label: {
    ..._v.fs_fontStyle({
      fz: 17,
      st: 2,
      ds: 1
    }),
    position: "center",
    textAlign: "center"
  }
};