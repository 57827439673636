var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-bind-wallet', {
    ref: "popBindWalletRef",
    attrs: {
      "parentDomName": "popDom",
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      },
      "submit": _vm.popBindSubmint
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };