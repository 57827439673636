import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.load("bg3");
await Assets.load("g");
await Assets.load("select-bg");
import gsap from "gsap";
// await Assets.loadBundle("upgrade");
const scrollItemWidth = _v.screenWidth / 2 - 60;
const scrollItemHeight = 170;
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
await Assets.load("upgrade_item_ad1");
await Assets.load("upgrade_item_ad");
export default {
  name: "upgrade",
  inject: ["closePage", "setPageMinerDetailShow", "setPageMinerShow"],
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo"]),
    ...userVuex.mapState(["accountInfo"]),
    isRender() {
      return this.$parent.pageUpgradeStarShow;
    }
  },
  watch: {
    isRender: {
      handler(bool) {
        if (bool) {
          this.initScreen();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      currentScreen: null,
      pageDom: null,
      detail: {
        miner: "chaigong_1",
        star: 4
      },
      minerLeft: {
        miner: "chaigong_2-idle",
        star: 4
      },
      popSelectShow: false,
      selectMiner: null,
      resultMiner: null,
      successRate: 0,
      popFailOption: {
        show: false,
        content: ""
      },
      popFailDom: null,
      popSelectDom: null,
      popStartConfirmShow: false,
      popStartConfirmDom: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["combineMiner", "simulateMiner", "getMinerList"]),
    ...gameVuex.mapMutations(["setSelectMinerInfo"]),
    async initScreen() {
      await this.setScreen();
    },
    async setScreen() {
      if (!_v[`page_upgrade`]) {
        _v[`page_upgrade`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_upgrade`]);
      }
      _v[`page_upgrade`].visible = true;
      _v[`page_upgrade`].zIndex = this.gameApp.stage.children.length + 6;
      this.loadMinerInfo();
    },
    setDom() {
      this.pageDom.sortableChildren = true;
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.miner_item_star_label
        });
      }
      return {
        ...list
      };
    },
    loadMinerInfo(type = "l") {
      const pageDom = _v[`page_upgrade`].content;
      if (this.selectMinerInfo && type === "l") {
        const miner_item_id = pageDom.getByID("miner_item_id");
        const miner_item_star = pageDom.getByID("miner_item_star").content;
        const miner_item_name = pageDom.getByID("miner_item_name");
        const minerDetail_info_productivity = pageDom.getByID("minerDetail_info_productivity");
        const miner_item_level = pageDom.getByID("miner_item_level");
        const miner_item_mine_main = pageDom.getByID("miner_item_mine_main");
        miner_item_level.children[0].text = `Lv.${this.selectMinerInfo.level}`;
        miner_item_name.children[0].text = `${this.setMiner(this.selectMinerInfo.star, "name")}`;
        minerDetail_info_productivity.children[0].text = `Power:${this.toPre(this.selectMinerInfo.power * this.powerTime, 0)}/h`;
        miner_item_id.children[0].text = `ID:${this.selectMinerInfo.id}`;
        miner_item_mine_main.children[0].textures = _v[`${this.setMiner(this.selectMinerInfo.star, "img")}-idle`];
        miner_item_mine_main.children[0].play();
        let timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          miner_item_star.createContent({
            content: {
              miner_item_star_main: {
                content: this.starList(this.selectMinerInfo)
              }
            }
          });
          miner_item_star.removeContent(miner_item_star.children.entries().next().value[0]);
        }, 500);
      }
      const miner_item_add = pageDom.getByID("miner_item_add");
      const miner_item_main_2 = pageDom.getByID("miner_item_main_2");
      miner_item_main_2.visible = false;
      miner_item_add.visible = true;
      if (this.selectMiner && type === "r") {
        miner_item_main_2.visible = true;
        miner_item_add.visible = false;
        const r_miner_item_id = pageDom.getByID("r_miner_item_id");
        const r_miner_item_star = pageDom.getByID("r_miner_item_star").content;
        const r_miner_item_name = pageDom.getByID("r_miner_item_name");
        const r_minerDetail_info_productivity = pageDom.getByID("r_minerDetail_info_productivity");
        const r_miner_item_level = pageDom.getByID("r_miner_item_level");
        const r_miner_item_mine_main = pageDom.getByID("r_miner_item_mine_main");
        r_miner_item_level.children[0].text = `Lv.${this.selectMiner.level}`;
        r_miner_item_name.children[0].text = `${this.setMiner(this.selectMiner.star, "name")}`;
        r_minerDetail_info_productivity.children[0].text = `Power:${this.toPre(this.selectMiner.power * this.powerTime, 0)}/h`;
        r_miner_item_id.children[0].text = `ID:${this.selectMiner.id}`;
        r_miner_item_mine_main.children[0].textures = _v[`${this.setMiner(this.selectMiner.star, "img")}-idle`];
        r_miner_item_mine_main.children[0].play();
        let timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          r_miner_item_star.createContent({
            content: {
              miner_item_star_main: {
                content: this.starList(this.selectMiner)
              }
            }
          });
          r_miner_item_star.removeContent(r_miner_item_star.children.entries().next().value[0]);
        }, 500);
      }
    },
    setMinerResultDom(item) {
      return {
        miner_item_main_result: {
          content: {
            miner_item_bg: {
              content: this.sf_upgradeItemBg({
                width: scrollItemWidth * 4 - 30,
                height: scrollItemHeight * 4
              }),
              styles: globalStyles.miner_item_bg
            },
            miner_item_box: {
              content: {
                miner_item_miner_bg: {
                  content: {
                    miner_item_miner_bg_main: {
                      content: this.sf_taskItemImg({
                        width: scrollItemWidth * 4 - 100,
                        height: 100 * 4 + 15
                      }),
                      styles: globalStyles.miner_item_miner_bg_main
                    }
                  },
                  styles: globalStyles.miner_item_miner_bg
                },
                miner_item_mine: {
                  content: {
                    miner_item_mine_main: {
                      content: this.m_setSpineAnmationsSprite(`${this.setMiner(item.star, "img")}-idle`, {
                        loop: true,
                        isPlay: true,
                        animationSpeed: 0.25
                      }),
                      styles: globalStyles.miner_item_mine_main
                    }
                  },
                  styles: globalStyles.miner_item_mine
                },
                miner_item_star: {
                  content: this.starList(item),
                  styles: globalStyles.miner_item_star
                },
                miner_item_level: {
                  content: `Lv.${item.level}`,
                  styles: globalStyles.miner_item_level
                }
              },
              styles: globalStyles.miner_item_box
            },
            miner_item_info: {
              content: {
                miner_item_name: {
                  content: `${this.setMiner(item.star, "name")}`,
                  styles: globalStyles.miner_item_name
                },
                minerDetail_info_productivity: {
                  content: `Power:${this.toPre(item.power * this.powerTime, 0)}/h`,
                  styles: globalStyles.minerDetail_info_productivity
                },
                miner_item_id: {
                  content: `ID:${this.selectMinerInfo.id}`,
                  styles: globalStyles.miner_item_name
                }
              },
              styles: globalStyles.miner_item_info
            }
          },
          styles: globalStyles.miner_item_main
        }
      };
    },
    renderDom() {
      console.log("this.successRate", this.successRate);
      return {
        content: {
          upgrade_page: {
            content: {
              upgrade_bg: {
                content: Sprite.from("bg3")
              },
              upgrade_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_upgrade`].visible = false;
                  this.$emit("update:show", false);
                })
              },
              upgrade_main: {
                content: {
                  upgrade_title: {
                    content: {
                      upgrade_title_bg: {
                        content: {
                          upgrade_title_bg_label: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg-1.png"])
                          }
                        }
                      },
                      upgrade_title_text: {
                        content: "UPGRADE STAR"
                      }
                    }
                  },
                  upgrade_info: {
                    content: {
                      upgrade_info_item: {
                        content: "Two miners of the same star level ",
                        styles: globalStyles.upgrade_info_item
                      },
                      upgrade_info_item_1: {
                        content: "have a chance to upgrade or will",
                        styles: globalStyles.upgrade_info_item
                      },
                      upgrade_info_item_2: {
                        content: " get a miner with origin star if it fails",
                        styles: globalStyles.upgrade_info_item
                      }
                    }
                  },
                  upgrade_miner: {
                    content: {
                      miner_item_1: {
                        content: {
                          miner_item_main_1: {
                            content: {
                              miner_item_bg: {
                                content: this.sf_upgradeItemBg({
                                  width: scrollItemWidth * 4,
                                  height: scrollItemHeight * 4 + 10
                                }),
                                styles: globalStyles.miner_item_bg
                              },
                              miner_item_box: {
                                content: {
                                  miner_item_miner_bg: {
                                    content: {
                                      miner_item_miner_bg_main: {
                                        content: this.sf_taskItemImg({
                                          width: scrollItemWidth * 4 - 80,
                                          height: 100 * 4 + 15
                                        }),
                                        styles: globalStyles.miner_item_miner_bg_main
                                      }
                                    },
                                    styles: globalStyles.miner_item_miner_bg
                                  },
                                  miner_item_mine: {
                                    content: {
                                      miner_item_mine_main: {
                                        content: this.m_setSpineAnmationsSprite(`${this.setMiner(1, "img")}-idle`, {
                                          loop: true,
                                          isPlay: true,
                                          animationSpeed: 0.25
                                        }),
                                        styles: globalStyles.miner_item_mine_main
                                      }
                                    },
                                    styles: globalStyles.miner_item_mine
                                  },
                                  miner_item_star: {
                                    content: {
                                      miner_item_star_main: {
                                        content: this.starList({
                                          star: 1
                                        }),
                                        styles: globalStyles.miner_item_star_main
                                      }
                                    },
                                    styles: globalStyles.miner_item_star
                                  },
                                  miner_item_level: {
                                    content: `Lv.${this.selectMinerInfo.level}`,
                                    styles: globalStyles.miner_item_level
                                  }
                                },
                                styles: globalStyles.miner_item_box
                              },
                              miner_item_info: {
                                content: {
                                  miner_item_name: {
                                    content: `${this.setMiner(this.selectMinerInfo.star, "name")}`,
                                    styles: globalStyles.miner_item_name
                                  },
                                  minerDetail_info_productivity: {
                                    content: `Power:${this.toPre(this.selectMinerInfo.power * this.powerTime, 0)}/h`,
                                    styles: globalStyles.minerDetail_info_productivity
                                  },
                                  miner_item_id: {
                                    content: `ID:${this.selectMinerInfo.id}`,
                                    styles: globalStyles.miner_item_name
                                  }
                                },
                                styles: globalStyles.miner_item_info
                              }
                            },
                            styles: globalStyles.miner_item_main
                          }
                        },
                        styles: globalStyles.miner_item
                      },
                      miner_item_2: {
                        content: {
                          miner_item_main_2: {
                            content: this.m_btn({
                              content: {
                                miner_item_bg: {
                                  content: this.sf_upgradeItemBg({
                                    width: scrollItemWidth * 4,
                                    height: scrollItemHeight * 4
                                  }),
                                  styles: globalStyles.miner_item_bg
                                },
                                miner_item_box: {
                                  content: {
                                    miner_item_miner_bg: {
                                      content: {
                                        miner_item_miner_bg_main: {
                                          content: this.sf_taskItemImg({
                                            width: scrollItemWidth * 4 - 100,
                                            height: 100 * 4 + 15
                                          }),
                                          styles: globalStyles.miner_item_miner_bg_main
                                        }
                                      },
                                      styles: globalStyles.miner_item_miner_bg
                                    },
                                    miner_item_mine: {
                                      content: {
                                        r_miner_item_mine_main: {
                                          content: this.m_setSpineAnmationsSprite(`${this.setMiner(1, "img")}-idle`, {
                                            loop: true,
                                            isPlay: true,
                                            animationSpeed: 0.25
                                          }),
                                          styles: globalStyles.miner_item_mine_main
                                        }
                                      },
                                      styles: globalStyles.miner_item_mine
                                    },
                                    r_miner_item_star: {
                                      content: {
                                        miner_item_star_main: {
                                          content: this.starList({
                                            star: 1
                                          }),
                                          styles: globalStyles.miner_item_star_main
                                        }
                                      },
                                      styles: globalStyles.miner_item_star
                                    },
                                    r_miner_item_level: {
                                      content: `Lv.1`,
                                      styles: globalStyles.miner_item_level
                                    }
                                  },
                                  styles: globalStyles.miner_item_box
                                },
                                miner_item_info: {
                                  content: {
                                    r_miner_item_name: {
                                      content: `${this.setMiner(1, "name")}`,
                                      styles: globalStyles.miner_item_name
                                    },
                                    r_minerDetail_info_productivity: {
                                      content: `Power:0/h`,
                                      styles: globalStyles.minerDetail_info_productivity
                                    },
                                    r_miner_item_id: {
                                      content: `ID:0`,
                                      styles: globalStyles.miner_item_name
                                    }
                                  },
                                  styles: globalStyles.miner_item_info
                                },
                                miner_item_again: {
                                  content: {
                                    miner_item_again_icon: {
                                      content: Sprite.from(this.m_getRes("element").textures["btn-refresh.png"]),
                                      styles: globalStyles.miner_item_again_icon
                                    }
                                  },
                                  styles: globalStyles.miner_item_again
                                }
                              },
                              styles: globalStyles.miner_item_main
                            }, () => {
                              this.popSelectShow = true;
                            })
                          },
                          miner_item_add: {
                            content: {
                              miner_item_bg: {
                                content: this.sf_upgradeItemBg({
                                  width: scrollItemWidth * 4,
                                  height: scrollItemHeight * 4 + 10
                                }),
                                styles: globalStyles.miner_item_bg
                              },
                              miner_item_ad: this.m_btn({
                                content: {
                                  miner_item_ad_1: {
                                    content: Sprite.from("upgrade_item_ad1"),
                                    styles: globalStyles.miner_item_ad_1
                                  }
                                  // miner_item_ad_2: {
                                  //   content: {
                                  //     miner_item_ad_2_1: {
                                  //       content: Sprite.from("upgrade_item_ad"),
                                  //       styles: globalStyles.miner_item_ad_2_1,
                                  //     },
                                  //     miner_item_ad_2_2: {
                                  //       content: Sprite.from("upgrade_item_ad"),
                                  //       styles: globalStyles.miner_item_ad_2_2,
                                  //     },
                                  //     miner_item_ad_2_3: {
                                  //       content: Sprite.from("upgrade_item_ad"),
                                  //       styles: globalStyles.miner_item_ad_2_3,
                                  //     },
                                  //     miner_item_ad_2_4: {
                                  //       content: Sprite.from("upgrade_item_ad"),
                                  //       styles: globalStyles.miner_item_ad_2_4,
                                  //     },
                                  //   },
                                  // },
                                },

                                styles: globalStyles.miner_item_ad
                              }, () => {
                                this.popSelectShow = true;
                              })
                            }
                          }
                        },
                        styles: globalStyles.miner_item
                      },
                      miner_item_result: {
                        content: " ",
                        styles: globalStyles.miner_item
                      }
                    }
                  },
                  upgrade_btns_info: {
                    content: {
                      upgrade_btns_info_value: {
                        content: `0%`,
                        styles: globalStyles.upgrade_btns_info_value
                      },
                      upgrade_btns_info_label: {
                        content: "success rate"
                      },
                      upgrade_btns_info_message: {
                        content: "Upgrading the miner level increases\n the success rate of star upgrades."
                      }
                    }
                  },
                  upgrade_btns_check: {
                    content: this.m_btn({
                      content: {
                        upgrade_btns_upgrade: {
                          content: {
                            upgrade_btns_bg: {
                              content: {
                                upgrade_btns_bg_label: {
                                  content: this.sf_btnBg({
                                    width: this.screenWidth * 2.5,
                                    height: 200
                                  }),
                                  styles: globalStyles.upgrade_btns_bg_label
                                }
                              },
                              styles: globalStyles.upgrade_btns_bg
                            },
                            upgrade_btns_label: {
                              content: "CHECK",
                              styles: globalStyles.upgrade_btns_label
                            }
                          },
                          styles: globalStyles.upgrade_btns_upgrade
                        }
                      },
                      styles: globalStyles.upgrade_btns
                    }, async () => {
                      this.setSelectMinerInfo(this.resultMiner.data);
                      // if (this.$parent.$refs?.pageMinerRef) {
                      //   const pageMinerRef = this.$parent.$refs.pageMinerRef;
                      //   await this.getMinerList();
                      //   console.log("pageMinerRef", pageMinerRef);
                      //   pageMinerRef.loadMinerList();
                      // }
                      if (this.$parent.$refs?.pageMinerDetailRef) {
                        const pageMinerDetailRef = this.$parent.$refs.pageMinerDetailRef;
                        await this.getMinerList();
                        console.log("pageMinerRef", pageMinerDetailRef);
                        pageMinerDetailRef.loadMinerDetailInfo();
                      }
                      const pageDom = _v[`page_upgrade`].content;
                      const upgrade_btn_back = pageDom.getByID("upgrade_btn_back");
                      const miner_item_again = pageDom.getByID("miner_item_again");
                      const upgrade_btns_check = pageDom.getByID("upgrade_btns_check");
                      upgrade_btn_back.visible = true;
                      miner_item_again.visible = true;
                      upgrade_btns_check.visible = true;
                      this.closePage();
                      this.setPageMinerDetailShow(true);
                      console.log("_v[`page_upgrade`]", _v[`page_upgrade`]);
                      _v[`page_upgrade`].destroy();
                      _v[`page_upgrade`] = null;
                      this.$emit("update:show", false);
                    })
                  },
                  upgrade_btns: {
                    content: this.m_btn({
                      content: {
                        upgrade_btns_upgrade: {
                          content: {
                            upgrade_btns_bg: {
                              content: {
                                upgrade_btns_bg_label: {
                                  content: this.sf_btnBg({
                                    width: this.screenWidth * 2.5,
                                    height: 200
                                  }),
                                  styles: globalStyles.upgrade_btns_bg_label
                                }
                              },
                              styles: globalStyles.upgrade_btns_bg
                            },
                            upgrade_btns_label: {
                              content: "START",
                              styles: globalStyles.upgrade_btns_label
                            }
                          },
                          styles: globalStyles.upgrade_btns_upgrade
                        }
                      },
                      styles: globalStyles.upgrade_btns
                    }, () => {
                      this.onUpgrade();
                    })
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    async onSelect(item) {
      this.selectMiner = item;
      this.loadMinerInfo("r");
      this.popSelectDom.visible = false;
      const data = await this.simulateMiner([this.selectMinerInfo.id, item.id]);
      const pageDom = _v[`page_upgrade`].content;
      if (!data) {
        return;
      }
      const upgrade_btns_info = pageDom.getByID("upgrade_btns_info");
      const upgrade_btns_info_value = pageDom.getByID("upgrade_btns_info_value");
      upgrade_btns_info.visible = true;
      upgrade_btns_info_value.children[0].text = `${this.toPre(data * 100, 4)}%`;
      const successRate = parseInt(data * 100);
      if (successRate >= 80) {
        upgrade_btns_info_value.children[0].style.fill = "#00b42a";
      } else if (successRate < 80 && successRate >= 60) {
        upgrade_btns_info_value.children[0].style.fill = "#ff7d00";
      } else if (successRate < 60) {
        upgrade_btns_info_value.children[0].style.fill = "#f53f3f";
      }
      const upgrade_btns = pageDom.getByID("upgrade_btns");
      upgrade_btns.visible = true;
      upgrade_btns.children[0].visible = true;
      console.log("upgrade_btns", upgrade_btns);
    },
    onUpgrade() {
      this.popStartConfirmShow = true;
    },
    async popFailSubmit() {
      this.setSelectMinerInfo(this.resultMiner.data);

      // if (this.$parent.$refs?.pageMinerRef) {
      //   const pageMinerRef = this.$parent.$refs.pageMinerRef;
      //   await this.getMinerList();
      //   pageMinerRef.loadMinerList();
      // }
      const pageDom = _v[`page_upgrade`].content;
      const upgrade_btn_back = pageDom.getByID("upgrade_btn_back");
      const miner_item_again = pageDom.getByID("miner_item_again");
      const upgrade_btns_check = pageDom.getByID("upgrade_btns_check");
      upgrade_btn_back.visible = true;
      miner_item_again.visible = true;
      upgrade_btns_check.visible = true;
      this.closePage();
      this.setPageMinerShow(true);
      console.log("_v[`page_upgrade`]", _v[`page_upgrade`]);
      _v[`page_upgrade`].destroy();
      _v[`page_upgrade`] = null;
      this.$emit("update:show", false);
    },
    async popStartConfirm() {
      this.popStartConfirmDom.visible = false;
      this.popStartConfirmShow = false;
      const res = await this.combineMiner({
        a: this.selectMinerInfo.id,
        b: this.selectMiner.id
      });
      // const res = {
      //   code: 0,
      //   message: "combine success",
      //   data: null,
      // };
      if (!res) {
        return;
      }
      const data = res;
      const pageDom = _v[`page_upgrade`].content;
      this.resultMiner = data;
      const that = this;
      const miner_item_1 = pageDom.getByID("miner_item_1");
      const miner_item_2 = pageDom.getByID("miner_item_2");
      const upgrade_btns = pageDom.getByID("upgrade_btns");
      const upgrade_btn_back = pageDom.getByID("upgrade_btn_back");
      const miner_item_again = pageDom.getByID("miner_item_again");
      const upgrade_btns_check = pageDom.getByID("upgrade_btns_check");
      const upgrade_btns_info = pageDom.getByID("upgrade_btns_info");
      upgrade_btns_check.visible = false;
      upgrade_btns_check.children[0].visible = true;
      upgrade_btns.visible = false;
      upgrade_btns.children[0].visible = true;
      miner_item_again.visible = false;
      upgrade_btn_back.visible = false;
      upgrade_btns_info.visible = false;
      const moveDistance = 50;
      const slowDuration = 0.5;
      const fastDuration = 0.4;
      const center = dom => _v.screenWidth / 2 - dom.width / 2;
      // 左右移动的动画
      gsap.to(miner_item_1, {
        x: miner_item_1.x - moveDistance,
        duration: slowDuration,
        ease: "power1.inOut",
        onComplete: () => {
          gsap.to(miner_item_1, {
            x: center(miner_item_1),
            duration: fastDuration,
            ease: "power3.inOut",
            onComplete: showCenterSprite
          });
        }
      });
      gsap.to(miner_item_2, {
        x: miner_item_2.x + moveDistance,
        duration: slowDuration,
        ease: "power1.inOut",
        onComplete: () => {
          gsap.to(miner_item_2, {
            x: center(miner_item_2),
            duration: fastDuration,
            ease: "power3.inOut",
            onComplete: showCenterSprite
          });
        }
      });
      function showCenterSprite() {
        if (that.resultMiner.data) {
          const pageDom = _v[`page_upgrade`];
          const resultDom = that.setMinerResultDom(that.resultMiner.data);
          const miner_item_result = pageDom.content.getByID("miner_item_result");
          miner_item_result.visible = false;
          miner_item_result.content.createContent(resultDom);
          miner_item_result.content.removeContent(miner_item_result.content.children.entries().next().value[0]);
          miner_item_1.visible = false;
          miner_item_2.visible = false;
          miner_item_result.visible = true;
          gsap.to(miner_item_result, {
            alpha: 1,
            // 从透明到不透明
            duration: 1,
            ease: "power1.inOut",
            onComplete: () => {
              upgrade_btns_check.visible = true;
            }
          });
        } else {
          that.popFailOption.content = that.resultMiner.message;
          that.popFailOption.show = true;
        }
      }
    }
  }
};
const globalStyles = {
  upgrade_page: {
    position: "letTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren",
    zIndex: 2
  },
  upgrade_bg: {
    maxWidth: "100%",
    position: "center"
  },
  upgrade_title: {
    width: 300,
    height: 80,
    position: "centerTop",
    overflow: "hidden"
  },
  upgrade_title_bg: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  upgrade_title_bg_label: {
    scale: 0.3
  },
  upgrade_title_text: {
    position: "centerTop",
    marginTop: 8,
    ..._v.fs_fontStyle({
      fz: 23,
      st: 3,
      ds: 1
    })
  },
  upgrade_info: {
    marginTop: 80,
    position: "centerTop"
  },
  upgrade_info_item: {
    fontSize: 14,
    fontFamily: "pFont",
    textAlign: "center",
    width: "100%",
    color: "#acb0b4"
  },
  upgrade_btns: {
    width: _v.screenWidth / 1.5,
    position: "centerBottom",
    height: 60,
    visible: false
  },
  upgrade_btns_info: {
    position: "centerBottom",
    marginBottom: 110,
    visible: false
  },
  upgrade_btns_info_value: {
    fontSize: 28,
    color: "#fff",
    fontFamily: "pFont",
    textAlign: "center",
    width: "100%"
  },
  upgrade_btns_info_value_success: {
    ..._v.fs_fontStyle({
      fz: 28,
      st: 1,
      ds: 1,
      fill: "00b42a"
    })
  },
  upgrade_btns_info_value_wait: {
    ..._v.fs_fontStyle({
      fz: 28,
      st: 1,
      ds: 1,
      fill: "ff7d00"
    })
  },
  upgrade_btns_info_value_fail: {
    ..._v.fs_fontStyle({
      fz: 28,
      st: 1,
      ds: 1,
      fill: "f53f3f"
    })
  },
  upgrade_btns_info_label: {
    marginTop: -5,
    fontSize: 15,
    color: "#fff",
    opacity: 0.5,
    fontFamily: "pFontM",
    textAlign: "center",
    width: "100%"
  },
  upgrade_btns_info_message: {
    marginTop: -15,
    fontSize: 11,
    color: "#fff",
    // opacity: 0.5,
    fontFamily: "pFontM",
    textAlign: "center",
    width: "100%"
  },
  upgrade_btns_check: {
    width: _v.screenWidth / 1.5,
    position: "centerBottom",
    height: 60,
    visible: false
  },
  upgrade_btns_upgrade: {
    height: 65,
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  upgrade_btns_bg: {
    position: "center",
    width: "100%"
  },
  upgrade_btns_bg_label: {
    position: "center",
    scale: 0.25
  },
  upgrade_btns_main: {
    position: "center"
  },
  upgrade_btns_label: {
    width: "100%",
    textAlign: "center",
    position: "center",
    ..._v.fs_fontStyle({
      fz: 16
    })
  },
  upgrade_main: {
    position: "center",
    // marginTop: 120,
    width: "100%",
    height: 500,
    overflow: "hidden"
  },
  upgrade_miner: {
    height: scrollItemHeight,
    width: "100%",
    position: "center"
    // overflow: "hidden",
  },

  miner_item: {
    height: "100%",
    width: scrollItemWidth + 40
    // overflow: "hidden",
  },

  miner_item_1: {
    position: "center",
    marginLeft: -scrollItemHeight / 2 - 15
  },
  miner_item_2: {
    position: "center",
    marginLeft: scrollItemHeight / 2
  },
  miner_item_main_2: {
    visible: false,
    position: "center",
    height: "100%",
    width: scrollItemWidth,
    anchor: 0.5
  },
  miner_item_result: {
    position: "center"
  },
  miner_item_main: {
    position: "center",
    height: "100%",
    width: scrollItemWidth,
    anchor: 0.5
  },
  miner_item_add: {
    position: "center",
    height: "100%",
    width: scrollItemWidth
  },
  miner_item_bg: {
    position: "center",
    scale: 0.25
  },
  miner_item_again: {
    position: "rightTop",
    width: 25,
    height: 25,
    marginTop: -5,
    marginRight: 3
  },
  miner_item_again_icon: {
    scale: 0.25,
    width: "100%",
    height: "100%"
  },
  miner_item_box: {
    position: "centerTop",
    width: scrollItemWidth - 10,
    height: scrollItemHeight * 0.6,
    // overflow:'hidden',
    marginTop: scrollItemHeight * 0.07 - 3
  },
  miner_item_miner_bg: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  miner_item_miner_bg_main: {
    scale: 0.25,
    position: "center"
  },
  miner_item_mine: {
    width: "100%",
    height: "100%",
    position: "center"
  },
  miner_item_mine_main: {
    scale: 0.095,
    position: "center"
  },
  miner_item_star: {
    height: 8,
    position: "leftBottom",
    marginBottom: 12,
    marginLeft: 12
  },
  miner_item_star_label: {
    scale: 0.18,
    width: 12,
    height: 12
  },
  miner_item_name: {
    width: "100%",
    textAlign: "center",
    position: "centerTop",
    marginTop: 130,
    ..._v.fs_fontStyle({
      fz: 14,
      st: 1,
      ds: 1
    })
  },
  miner_item_level: {
    position: "rightBottom",
    marginRight: 12,
    marginBottom: 4,
    fontSize: 13,
    fontFamily: "pFont",
    color: "#fff"
  },
  miner_item_info: {
    position: "centerBottom",
    width: "100%",
    height: 40,
    marginBottom: 15
  },
  miner_item_name: {
    fontSize: 13,
    fontFamily: "pFont",
    color: "#2e3443",
    width: "100%",
    textAlign: "center"
  },
  minerDetail_info_productivity: {
    fontSize: 12,
    fontFamily: "pFont",
    color: "#7f83a5",
    width: "100%",
    textAlign: "center"
  },
  miner_item_id: {
    fontSize: 12,
    fontFamily: "pFont",
    color: "#11131e",
    width: "100%",
    textAlign: "center"
  },
  miner_item_tag: {
    position: "leftTop",
    background: "#232b36",
    color: "#676b71",
    fontSize: 13,
    fontFamily: "pFont",
    marginLeft: 15,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    marginTop: 10
  },
  miner_item_ad: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  miner_item_ad_1: {
    position: "center",
    scale: 0.25
  },
  miner_item_ad_2: {
    marginTop: -2,
    position: "center",
    width: "90%",
    height: "92%"
  },
  miner_item_ad_2_1: {
    scale: 0.25,
    position: "leftTop"
  },
  miner_item_ad_2_2: {
    scaleX: -0.25,
    scaleY: 0.25,
    position: "rightTop",
    anchor: 0
  },
  miner_item_ad_2_3: {
    scaleX: 0.25,
    scaleY: -0.25,
    anchor: 0,
    position: "leftBottom"
  },
  miner_item_ad_2_4: {
    scale: -0.25,
    position: "rightBottom",
    anchor: 0
  }
};