import "@pixi/events";
import { Application, Assets } from "pixi.js";
import { Spine } from "pixi-spine";

const app = new Application({
  resolution: Math.max(window.devicePixelRatio, 2),
  backgroundColor: "#111",
});
app.stage.sortableChildren = true;
app.stage.interactive = true;
globalThis.__PIXI_APP__ = app;

function m_resize() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  app.renderer.view.style.width = `${windowWidth}px`;
  app.renderer.view.style.height = `${windowHeight}px`;
  window.scrollTo(0, 0);
  app.renderer.resize(windowWidth, windowHeight);
}

function createGameDom() {
  const div = document.createElement("div");
  div.setAttribute("id", "game");
  console.dir(div);
  div.appendChild(app.view);
  document.body.appendChild(div);
}

function m_init() {
  return new Promise(async (resolve, reject) => {
    createGameDom();
    window.addEventListener("resize", m_resize);
    m_resize();
    await _v.m_initAssets(app);
    resolve(app);
  });
}

// window['m_init'] = m_init
export default m_init;
