var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-prompt', {
    ref: "buyMineRef",
    attrs: {
      "domName": "popBuyMineDom",
      "show": _vm.popBuyPromptOption.show,
      "content": "Are you sure you want to buy mine?",
      "btn-type": _vm.popBuyPromptOption.type,
      "title": _vm.popBuyPromptOption.title,
      "content": _vm.popBuyPromptOption.content,
      "btnText": _vm.popBuyPromptOption.btnText
    },
    on: {
      "close": _vm.popBuyPromptClose,
      "update:show": function ($event) {
        return _vm.$set(_vm.popBuyPromptOption, "show", $event);
      },
      "submit": _vm.popBuyPromptSubmit
    }
  }), _c('pop-prompt', {
    ref: "switchRef",
    attrs: {
      "domName": "popSwitchMineDom",
      "btn-type": _vm.popSwitchSubmitOption.type,
      "show": _vm.popSwitchSubmitOption.show,
      "title": _vm.popSwitchSubmitOption.title,
      "content": _vm.popSwitchSubmitOption.content,
      "btnText": _vm.popSwitchSubmitOption.btnText
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.popSwitchSubmitOption, "show", $event);
      },
      "submit": _vm.popSwitchPromptSubmit
    }
  }), _c('assets-info-e', {
    ref: "assetsInfo1"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };