var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('layout')], 1), _c('pop-guide', {
    ref: "guideRef",
    attrs: {
      "show": _vm.popGuideShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGuideShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };