import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.loadBundle("ranking");
const ratio = 0.9;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 210;
const scrollItemWidth = scrollWidth;
const scrollItemHeight = 70;
const isLoading = _v.c_listLoading();
export default {
  name: "popRanking",
  props: {
    show: Boolean
  },
  computed: {
    ...gameVuex.mapState(["rankList"]),
    ...userVuex.mapState(["token", "accountInfo"])
  },
  watch: {
    "$parent.popRankingShow": {
      async handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      immediate: true,
      deep: true
    },
    "selectTabInfo.text": {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.scrollBox) {
            this.scrollBox.scrollTop();
          }
          this.loadRankingInfo();
        }
      },
      deep: true
    }
  },
  data() {
    const tabList = [{
      label: "MINED",
      text: "coin",
      value: 0
    }, {
      label: "POWER",
      text: "power",
      value: 1
    }, {
      label: "GME",
      text: "gme",
      value: 2
    }];
    return {
      popDom: null,
      tabList,
      isTab: tabList[0].value,
      selectTabInfo: tabList[0],
      ownRank: null,
      initPopBgDom: null,
      scrollBox: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["getRankList"]),
    setListLoading(bool) {
      const scrollDom = this.$parent.popRankingDom.content.getByID("pop_ranking_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    checkOwnRank() {},
    async initPop() {
      if (!this.$parent.popRankingDom) {
        this.$parent.popRankingDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popRankingDom);
      }
      this.$parent.popRankingDom.visible = true;
      this.$parent.popRankingDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popRankingDom.position.set(0, 0);
      this.setZindex();
      this.setListLoading(true);
      this.anim_pop(this.$parent.popRankingDom, async () => {
        await this.loadRankingInfo();
      });
    },
    setZindex() {
      const popDom = this.$parent.popRankingDom;
      const pop_ranking_main = popDom.getChildByID("pop_ranking_main");
      const pop_ranking_head = popDom.getChildByID("pop_ranking_head");
      const pop_ranking_list = popDom.getChildByID("pop_ranking_list");
      pop_ranking_main.sortableChildren = true;
      pop_ranking_head.zIndex = 2;
      pop_ranking_list.zIndex = 1;
      pop_ranking_head.sortableChildren = true;
      const pop_ranking_close = popDom.getChildByID("pop_ranking_close");
      const pop_ranking_head_ears = popDom.getChildByID("pop_ranking_head_ears");
      pop_ranking_close.zIndex = 5;
      pop_ranking_head_ears.zIndex = 2;
      pop_ranking_list.sortableChildren = true;
      const pop_ranking_list_own = popDom.getChildByID("pop_ranking_list_own");
      pop_ranking_list_own.zIndex = 6;
    },
    minerItemDom() {
      return {
        id: "pop_ranking_item",
        content: {
          pop_ranking_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollWidth * 4,
              height: scrollItemHeight * 4
            })
          },
          pop_ranking_item_main: {
            content: {
              pop_ranking_item_main_head: {
                content: {
                  pop_ranking_item_main_head_rank: {
                    content: {
                      // pop_ranking_item_main_head_rank_bg: {
                      //   content: Sprite.from(this.m_getRes("base").textures[index == 0 ? "ranking-icon-1-bg.png" : index == 1 ? "ranking-icon-2-bg.png" : index == 2 ? "ranking-icon-3-bg.png" : "ranking-icon-4-bg.png"]),
                      // },
                      // pop_ranking_item_main_head_rank_text: {
                      //   content: String(Number(index) + 1),
                      // },
                      pop_ranking_item_main_head_rank_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["ranking-icon-1-bg.png"])
                      },
                      pop_ranking_item_main_head_rank_text: {
                        content: "--"
                      }
                    }
                  },
                  // pop_ranking_item_main_head_name: {
                  //   content: `${item.firstname} ${item.lastname}`,
                  // },
                  pop_ranking_item_main_head_name: {
                    content: "--"
                  }
                }
              },
              pop_ranking_item_main_info: {
                content: {
                  pop_ranking_item_main_info_bg: {
                    content: this.sf_taskItemAssetBg({
                      width: 400,
                      height: 100
                    })
                  },
                  // pop_ranking_item_main_info_num: {
                  //   content: this.selectTabInfo.text == "coin" ? `${this.toPre(item.total_coin, 0)}` : this.selectTabInfo.text == "power" ? `${this.toPre(item.power * this.powerTime, 0)}/h` : this.selectTabInfo.text == "gme" ? `${this.toPre(item.total_gme, 2)}` : "0",
                  // },
                  // pop_ranking_item_main_info_icon: {
                  //   content: Sprite.from(_v.m_getRes("element").textures[this.selectTabInfo.text == "coin" ? "icon-coin.png" : this.selectTabInfo.text == "power" ? "icon-power.png" : this.selectTabInfo.text == "gme" ? "icon-gem.png" : " "]),
                  // },
                  pop_ranking_item_main_info_num: {
                    content: "--"
                  },
                  pop_ranking_item_main_info_icon: {
                    content: Sprite.from(_v.m_getRes("element").textures["icon-coin.png"])
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    minerListDom(init = 100) {
      let list = [];
      if (this.rankLint) {
        list = this.rankLint;
      } else {
        list = Array.from({
          length: init
        }, (_, index) => index);
      }
      const _list = list.map(it => {
        return this.m_btn(this.minerItemDom(), null, {
          width: scrollItemWidth,
          height: scrollItemHeight
        });
      });
      this.scrollBox = this.c_scrollBox(_list, {
        width: scrollWidth,
        height: scrollHeight,
        itemWidth: scrollItemWidth,
        itemHeight: scrollItemHeight,
        scrollItemHeight: scrollItemHeight,
        // itemDom: this.minerItemDom(),
        vertPadding: 40
      });
      // scrollBox.addItems(_list);
      return this.scrollBox;
    },
    loadCommonRankingInfo() {
      const popDom = this.$parent.popRankingDom;
      const scrollDom = popDom.content.getByID("pop_ranking_list_scroll");
      const listDom = scrollDom.children[0].children[1].children;
      const list = this.rankList;
      if (!list) return;
      listDom.map((itm, index) => {
        if (index >= list.length) {
          itm.visible = false;
          return;
        }
        itm.visible = true;
        const item = list[index];
        if (!item) return;
        const pop_ranking_item_main_head_rank_bg = itm.content.getByID("pop_ranking_item_main_head_rank_bg");
        pop_ranking_item_main_head_rank_bg.children[0].texture = this.m_getRes("base").textures[index == 0 ? "ranking-icon-1-bg.png" : index == 1 ? "ranking-icon-2-bg.png" : index == 2 ? "ranking-icon-3-bg.png" : "ranking-icon-4-bg.png"];
        const pop_ranking_item_main_head_rank_text = itm.content.getByID("pop_ranking_item_main_head_rank_text");
        pop_ranking_item_main_head_rank_text.children[0].text = String(Number(index) + 1);
        const pop_ranking_item_main_head_name = itm.content.getByID("pop_ranking_item_main_head_name");
        pop_ranking_item_main_head_name.children[0].text = `${item.firstname} ${item.lastname}`;
        const pop_ranking_item_main_info_num = itm.content.getByID("pop_ranking_item_main_info_num");
        pop_ranking_item_main_info_num.children[0].text = this.selectTabInfo.text == "coin" ? `${this.toPre(item.total_coin, 0)}` : this.selectTabInfo.text == "power" ? `${this.toPre(item.power * this.powerTime, 0)}/h` : this.selectTabInfo.text == "gme" ? `${this.toPre(item.total_gme, 2)}` : "0";
        const pop_ranking_item_main_info_icon = itm.content.getByID("pop_ranking_item_main_info_icon");
        pop_ranking_item_main_info_icon.children[0].texture = _v.m_getRes("element").textures[this.selectTabInfo.text == "coin" ? "icon-mined-coin.png" : this.selectTabInfo.text == "power" ? "icon-power.png" : this.selectTabInfo.text == "gme" ? "icon-gem.png" : " "];
      });
    },
    async loadOwnRankingInfo() {
      const popDom = this.$parent.popRankingDom;
      const own = this.rankList.findIndex(it => {
        return it.id === this.accountInfo.id;
      });
      this.ownRank = own < 0 ? "50+" : own + 1;
      const pop_ranking_list_own_main_head_rank_text = popDom.content.getByID("pop_ranking_list_own_main_head_rank_text");
      const pop_ranking_list_own_main_head_name = popDom.content.getByID("pop_ranking_list_own_main_head_name");
      const pop_ranking_list_own_main_info_num = popDom.content.getByID("pop_ranking_list_own_main_info_num");
      const pop_ranking_list_own_main_info_icon = popDom.content.getByID("pop_ranking_list_own_main_info_icon");
      pop_ranking_list_own_main_head_rank_text.children[0].text = `${this.ownRank}`;
      pop_ranking_list_own_main_head_name.children[0].text = `${this.accountInfo.firstname} ${this.accountInfo.lastname}`;
      pop_ranking_list_own_main_info_num.children[0].text = this.selectTabInfo.text == "coin" ? `${this.toPre(this.accountInfo.collected_coin, 0)}` : this.selectTabInfo.text == "power" ? `${this.toPre(this.accountInfo.power * this.powerTime, 0)}/h` : this.selectTabInfo.text == "gme" ? `${this.toPre(this.accountInfo.total_gme, 2)}` : "0";
      pop_ranking_list_own_main_info_icon.children[0].texture = _v.m_getRes("element").textures[this.selectTabInfo.text == "coin" ? "icon-mined-coin.png" : this.selectTabInfo.text == "power" ? "icon-power.png" : this.selectTabInfo.text == "gme" ? "icon-gem.png" : " "];
    },
    async loadRankingInfo() {
      this.setListLoading(true);
      await this.getRankList(this.selectTabInfo.text);
      this.loadOwnRankingInfo();
      this.loadCommonRankingInfo();
      this.setListLoading(false);
    },
    renderDom() {
      return {
        content: {
          pop_task: {
            content: {
              pop_ranking_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_ranking_adroin: {
                    content: Sprite.from("ranking-dec")
                  },
                  pop_ranking_main: {
                    content: {
                      pop_ranking_head: {
                        content: {
                          pop_ranking_head_bg: {
                            content: this.sf_popTitleBg({
                              width: _v.screenWidth * 3.3 - 100,
                              height: 200
                            })
                          },
                          pop_ranking_head_ears: {
                            content: {
                              pop_ranking_head_ears_item_1: {
                                content: Sprite.from("wheatEars")
                              },
                              pop_ranking_head_ears_item_2: {
                                content: Sprite.from("wheatEars")
                              }
                            }
                          },
                          tab_dom: this.c_tab(this.tabList, this.isTab, item => {
                            this.isTab = item.value;
                            this.selectTabInfo = item;
                          }),
                          pop_ranking_close: {
                            content: this.c_btnClose(() => {
                              console.log("关闭");
                              this.$parent.popRankingDom.visible = false;
                              this.$emit("update:show", false);
                            }, {
                              maxWidth: 40
                            })
                          }
                        }
                      },
                      pop_ranking_list: {
                        content: {
                          pop_ranking_list_bg: {
                            content: this.sf_marketGoods({
                              width: scrollWidth * 4,
                              height: scrollHeight * 4 + 240
                            })
                          },
                          common_loading: isLoading.render(),
                          pop_ranking_list_scroll: {
                            content: this.minerListDom(50)
                          },
                          pop_ranking_list_own: {
                            content: {
                              pop_ranking_list_own_bg: {
                                content: this.sf_rankingOwnBg({
                                  width: this.screenWidth * 4 - 200,
                                  height: 290
                                })
                              },
                              pop_ranking_list_own_main: {
                                content: {
                                  pop_ranking_list_own_main_head: {
                                    content: {
                                      pop_ranking_list_own_main_head_rank: {
                                        content: {
                                          pop_ranking_list_own_main_head_rank_bg: {
                                            content: Sprite.from(this.m_getRes("base").textures["ranking-icon-4-bg.png"])
                                          },
                                          pop_ranking_list_own_main_head_rank_text: {
                                            content: "--"
                                          }
                                        }
                                      },
                                      pop_ranking_list_own_main_head_name: {
                                        content: "--"
                                      }
                                    }
                                  },
                                  pop_ranking_list_own_main_info: {
                                    content: {
                                      pop_ranking_list_own_main_info_bg: {
                                        content: this.sf_rankingOwnNumBg({
                                          width: 420,
                                          height: 100
                                        })
                                      },
                                      pop_ranking_list_own_main_info_num: {
                                        content: "--"
                                      },
                                      pop_ranking_list_own_main_info_icon: {
                                        content: Sprite.from(_v.m_getRes("element").textures[this.selectTabInfo.text == "coin" ? "icon-mined-coin.png" : this.selectTabInfo.text == "power" ? "icon-power.png" : this.selectTabInfo.text == "gme" ? "icon-gem.png" : " "])
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    }
  }
};
const globalStyles = {
  pop_task: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "left"
  },
  pop_ranking_mask: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_main: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_ranking_main: {},
  pop_ranking_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50,
    marginTop: 100
  },
  pop_ranking_head_bg: {
    maxWidth: "100%",
    scale: 0.3,
    position: "center"
  },
  pop_ranking_adroin: {
    scale: 0.2,
    position: "centerTop",
    marginTop: -110
  },
  pop_ranking_head_ears: {
    position: "centerTop",
    width: "100%",
    marginTop: -20
  },
  pop_ranking_head_ears_item_1: {
    position: "left",
    scale: 0.25,
    width: 120,
    marginLeft: 0
  },
  pop_ranking_head_ears_item_2: {
    scaleX: -0.25,
    width: 120,
    scaleY: 0.25,
    marginRight: 30,
    position: "right"
  },
  pop_ranking_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_ranking_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_ranking_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop"
  },
  pop_ranking_close: {
    position: "rightTop",
    marginTop: -5,
    marginRight: -5
  },
  pop_ranking_list: {
    position: "centerTop",
    marginTop: 120
  },
  pop_ranking_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  pop_ranking_list_scroll: {
    position: "centerTop"
  },
  pop_ranking_item_bg: {
    position: "center",
    scale: 0.22
  },
  pop_ranking_item_main: {
    position: "center",
    width: scrollItemWidth - 60,
    marginTop: -2
  },
  pop_ranking_item_main_head: {},
  pop_ranking_item_main_head_rank: {
    position: "left",
    width: 34,
    height: 34,
    overflow: "hidden"
  },
  pop_ranking_item_main_head_rank_bg: {
    scale: 0.28,
    position: "center"
  },
  pop_ranking_item_main_head_rank_text: {
    marginTop: -1,
    marginLeft: 1,
    position: "center",
    width: 34,
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_ranking_item_main_head_name: {
    fill: "#2e3443",
    fontSize: 17,
    fontFamily: "pFont",
    position: "leftCenter",
    // marginTop: 6,
    marginLeft: 40,
    width: 120,
    // wordWrap: true,
    // wordWrapWidth: 100,
    overflow: "hidden"
  },
  pop_ranking_item_main_info: {
    width: 400,
    height: 60,
    position: "rightCenter",
    marginRight: 5
  },
  pop_ranking_item_main_info_bg: {
    scale: 0.25,
    position: "rightCenter",
    width: "100%"
  },
  pop_ranking_item_main_info_num: {
    position: "rightCenter",
    paddingTop: 5,
    fontSize: 16,
    fontFamily: "pFont",
    color: "#cd5d0a",
    marginTop: -2,
    marginRight: 5,
    width: 100,
    textAlign: "right"
  },
  pop_ranking_item_main_info_icon: {
    position: "rightCenter",
    marginTop: -12,
    marginRight: 100,
    width: 28,
    height: 28,
    scale: 0.3
  },
  pop_ranking_list_own: {
    position: "centerTop",
    height: 75,
    width: _v.screenWidth - 50,
    overflow: "hidden",
    marginTop: _v.screenHeight - 230
  },
  pop_ranking_list_own_bg: {
    scale: 0.25
  },
  pop_ranking_list_own_main: {
    position: "center",
    width: "100%",
    height: 30,
    marginTop: -2
  },
  pop_ranking_list_own_main_head: {
    position: "leftCenter",
    marginLeft: 20
  },
  pop_ranking_list_own_main_head_rank: {
    position: "left",
    width: 34,
    height: 34
    // overflow: "hidden",
  },

  pop_ranking_list_own_main_head_rank_bg: {
    scale: 0.28,
    position: "center"
  },
  pop_ranking_list_own_main_head_rank_text: {
    position: "center",
    marginLeft: 1,
    width: 34,
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_ranking_list_own_main_head_name: {
    fill: "#2e3443",
    fontSize: 17,
    fontFamily: "pFont",
    position: "leftCenter",
    // marginTop: 6,
    marginLeft: 40,
    width: 120,
    overflow: "hidden"
  },
  pop_ranking_list_own_main_info: {
    width: 360,
    height: 60,
    position: "rightCenter",
    marginRight: 40
  },
  pop_ranking_list_own_main_info_bg: {
    scale: 0.25,
    position: "rightCenter",
    width: "100%"
  },
  pop_ranking_list_own_main_info_num: {
    position: "rightCenter",
    paddingLeft: 10,
    paddingTop: 5,
    fontSize: 15,
    fontFamily: "pFont",
    color: "#ffec1a",
    marginTop: -2,
    width: 380,
    marginRight: -10,
    textAlign: "right"
  },
  pop_ranking_list_own_main_info_icon: {
    position: "rightCenter",
    marginTop: -11,
    marginRight: 90,
    width: 28,
    height: 28,
    scale: 0.3
  }
};