var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('pop-sell', {
    attrs: {
      "asset": {
        ..._vm.selectMinerInfo,
        type: 'miner'
      },
      "show": _vm.popSellShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSellShow = $event;
      },
      "submit": _vm.popSellSubmit
    }
  }), _c('pop-go-mine', {
    ref: "popGoMineRef",
    attrs: {
      "show": _vm.popGoMineShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGoMineShow = $event;
      }
    }
  }), _c('pop-prompt', {
    ref: "popLeaveWorkRef",
    attrs: {
      "parentDomName": "pageDom",
      "domName": "popLeaveWorkDom",
      "isCallback": _vm.popLeaveWorkIsCallback,
      "show": _vm.popLeaveWorkshow,
      "title": _vm.popLeaveWorkOption.title,
      "content": _vm.popLeaveWorkOption.content
    },
    on: {
      "update:show": function ($event) {
        _vm.popLeaveWorkshow = $event;
      },
      "submit": _vm.popLeaveWorkSubmit
    }
  }), _c('pop-upgrade-miner', {
    attrs: {
      "show": _vm.popUpgradeMinerShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpgradeMinerShow = $event;
      }
    }
  }), _c('assets-info-x', {
    ref: "assetsInfo1"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };