import * as filters from "pixi-filters";
import { BlurFilter } from "@pixi/filter-blur";

export default {
  //颜色叠加
  f_colorOverlayFilter: (element, option) => {
    const colorOverlayFilter = new filters.ColorOverlayFilter(option.color, option.alpha);
    element.filters = [colorOverlayFilter];
    return element;
  },
  //灰度
  f_grayscaleFilter: (element, option) => {
    const grayscaleFilter = new filters.GrayscaleFilter();
    element.filters = [grayscaleFilter];
    return element;
  },
  //模糊
  f_blurFilter: (element, option) => {
    let blurFilter = new BlurFilter();
    blurFilter.blur = option?.blur ? option.blur : 10; // 调整模糊程度
    element.filters = [blurFilter];
    return element;
  },
  //阴影
  f_dropShadowFilter: (element, option) => {
    let dropShadowFilter = new filters.DropShadowFilter();
    dropShadowFilter.alpha = 0.5; // 阴影透明度
    dropShadowFilter.blur = 4; // 阴影模糊程度
    dropShadowFilter.distance = 10; // 阴影距离
    dropShadowFilter.rotation = 45; // 阴影角度
    element.filters = [dropShadowFilter];
    return element;
  },
};
