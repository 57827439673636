export default {
  name: "popRecruitInfo",
  computed: {
    isRender() {
      return this.$parent.popRecruitInfoShow;
    }
  },
  watch: {
    isRender: {
      handler(val) {
        if (val) {
          this.initPop();
        }
      },
      immediate: true
    }
  },
  methods: {
    initPop() {
      if (!this.$parent.popRecruitInfoDom) {
        this.$parent.popRecruitInfoDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popRecruitInfoDom);
      }
      this.$parent.popRecruitInfoDom.visible = true;
      this.$parent.popRecruitInfoDom.zIndex = this.gameApp.stage.children.length + 11;
      this.anim_pop(this.$parent.popRecruitInfoDom);
    },
    renderDom() {
      return {
        content: {
          pop_prompt: {
            content: {
              pop_prompt_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_prompt_con: {
                    content: {
                      pop_prompt_main_bg: {
                        content: this.sf_popBg({
                          width: this.screenWidth * 4 - 300,
                          height: 1300
                        })
                      },
                      pop_prompt_box: {
                        content: {
                          pop_prompt_main_title: {
                            content: "Recruit Information"
                          },
                          pop_prompt_main_content: {
                            content: {
                              text0: {
                                content: "Coin Recruit:"
                              },
                              text1: {
                                content: "Recruit a 1-star miner with coins for a chance to get a 2-star or 3-star miner."
                              },
                              textA: {
                                content: " 1"
                              },
                              text2: {
                                content: "Diamond Recruit:"
                              },
                              text3: {
                                content: "Recruit a 2-star miner with diamonds for a chance to get a 3-star or 4-star miner. "
                              }
                            }
                          }
                        }
                      },
                      pop_prompt_btns: this.m_btn({
                        id: "pop_prompt_btns",
                        content: {
                          pop_prompt_btns_main: {
                            content: {
                              pop_prompt_box_btn: {
                                content: {
                                  pop_prompt_btns_bg: {
                                    content: this.sf_btnBg({
                                      width: this.screenWidth * 4 - 540,
                                      height: 170
                                    }),
                                    styles: globalStyles.pop_prompt_btns_bg
                                  },
                                  pop_prompt_btns_text: {
                                    content: "OK",
                                    styles: globalStyles.pop_prompt_btns_text
                                  }
                                },
                                styles: globalStyles.pop_prompt_box_btn
                              }
                            }
                          }
                        },
                        styles: globalStyles.pop_prompt_btns
                      }, () => {
                        this.$parent.popRecruitInfoDom.visible = false;
                        this.$emit("update:show", false);
                      })
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    }
  }
};
const globalStyles = {
  pop_prompt: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    position: "leftTop",
    marginLeft: 0,
    disply: "block"
  },
  pop_prompt_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_prompt_con: {
    width: _v.screenWidth - 82,
    height: 320,
    position: "center"
    // overflow: "hidden",
  },

  pop_prompt_main_bg: {
    position: "center",
    scale: 0.25
  },
  pop_prompt_box: {
    width: "100%",
    height: "100%",
    padding: 25
  },
  pop_prompt_main_title: {
    position: "centerTop",
    marginTop: 18,
    ..._v.fs_fontStyle({
      fz: 21,
      st: 2,
      ds: 1
    })
  },
  pop_prompt_main_content: {
    marginTop: 70,
    position: "centerTop",
    width: _v.screenWidth - 150
  },
  text0: {
    fontSize: 16,
    fontFamily: "pFont",
    fill: "#fff",
    wordWrap: true,
    wordWrapWidth: _v.screenWidth - 150,
    display: "line",
    paddingBottom: 3
  },
  text1: {
    fontSize: 15,
    fontFamily: "pFontM",
    fill: "#c4c4c4",
    wordWrap: true,
    wordWrapWidth: _v.screenWidth - 150,
    display: "line",
    lineHeight: 15
  },
  textA: {
    height: 16,
    display: "line",
    opacity: 0
  },
  text2: {
    fontSize: 15,
    fontFamily: "pFont",
    fill: "#fff",
    wordWrap: true,
    wordWrapWidth: _v.screenWidth - 150,
    display: "line",
    lineHeight: 15,
    paddingBottom: 3
  },
  text3: {
    fontSize: 16,
    fontFamily: "pFontM",
    fill: "#c4c4c4",
    wordWrap: true,
    wordWrapWidth: _v.screenWidth - 150,
    display: "line"
  },
  pop_prompt_btns: {
    position: "centerBottom",
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginBottom: 20
  },
  pop_prompt_box_btn: {
    width: "100%",
    height: "100%",
    position: "center"
  },
  pop_prompt_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_prompt_btns_text: {
    marginTop: -3,
    position: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  }
};