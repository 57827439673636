import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.load("up_star_info_bg");
export default {
  inject: ["changeMinerDom"],
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo"]),
    ...userVuex.mapState(["accountInfo", "gameInfo"]),
    isRender() {
      return this.$parent.popUpgradeMinerShow;
    }
  },
  name: "popUpgradeMiner",
  watch: {
    isRender: {
      handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      isUpgrade: false
    };
  },
  methods: {
    ...gameVuex.mapActions(["upgradeMiner"]),
    ...gameVuex.mapMutations(["setSelectMinerInfo"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    initPop() {
      if (!this.$parent.popUpgradeMierDom) {
        this.$parent.popUpgradeMierDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popUpgradeMierDom);
      }
      this.$parent.popUpgradeMierDom.visible = true;
      this.$parent.popUpgradeMierDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popUpgradeMierDom.position.set(0, 0);
      this.setZindex();
      this.anim_pop(this.$parent.popUpgradeMierDom, async () => {
        this.loadMinerInfo();
      }, {
        y: _v.screenHeight / 2 - 640 / 2
      });
    },
    setZindex() {
      if (!this.$parent.popUpgradeMierDom) return;
      const popDom = this.$parent.popUpgradeMierDom;
      const pop_main = popDom.getChildByID("pop_main");
      const pop_head = popDom.getChildByID("pop_head");
      const pop_con = popDom.getChildByID("pop_con");
      pop_main.sortableChildren = true;
      pop_head.zIndex = 2;
      pop_con.zIndex = 1;
    },
    loadMinerInfo() {
      const popDom = this.$parent.popUpgradeMierDom.content;
      const pop_info_item_title_current = popDom.getByID("pop_info_item_title_current");
      pop_info_item_title_current.children[0].text = `Lv.${this.selectMinerInfo.level}`;
      const pop_info_item_title_next = popDom.getByID("pop_info_item_title_next");
      pop_info_item_title_next.children[0].text = this.selectMinerInfo.level < this.gameInfo.maxLevel ? `Lv.${this.selectMinerInfo.level + 1}` : `Max`;
      const pop_info_item_title_power_current = popDom.getByID("pop_info_item_title_power_current");
      pop_info_item_title_power_current.children[0].text = `${this.toPre(this.selectMinerInfo.power * this.powerTime, 0)}/h`;
      const pop_info_item_title_power_next = popDom.getByID("pop_info_item_title_power_next");
      pop_info_item_title_power_next.children[0].text = `${this.toPre(this.selectMinerInfo.next_power * this.powerTime, 0)}/h`;
      const pop_upgrade_star_box_miner_main_label = popDom.getByID("pop_upgrade_star_box_miner_main_label");
      pop_upgrade_star_box_miner_main_label.children[0].textures = _v[`${this.setMiner(this.selectMinerInfo.star, "img")}-idle`];
      pop_upgrade_star_box_miner_main_label.children[0].play();
      const pop_upgrade_star_info_star = popDom.getByID("pop_upgrade_star_info_star");
      const pop_upgrade_star_info = popDom.getByID("pop_upgrade_star_info");
      const pop_upgrade_star_box_miner_name = popDom.getByID("pop_upgrade_star_box_miner_name");
      pop_upgrade_star_box_miner_name.children[0].text = `${this.setMiner(this.selectMinerInfo.star, "name")}`;
      const pop_btns_upgrade_value_label = popDom?.getByID("pop_btns_upgrade_value_label");
      pop_btns_upgrade_value_label.children[0].text = `${this.toPre(this.selectMinerInfo.next_fee, 0)}`;
      const pop_btns_upgrade_value = popDom?.getByID("pop_btns_upgrade_value").layout;
      pop_btns_upgrade_value.updateParents();
      pop_upgrade_star_info_star.children.map((item, index) => {
        item.visible = index <= this.selectMinerInfo.star - 1;
      });
      pop_upgrade_star_info_star.width = this.selectMinerInfo.star * 100;
      this.m_setAlign(pop_upgrade_star_info, pop_upgrade_star_info_star, "x");
    },
    renderDom() {
      return {
        content: {
          pop_upgradeWarehouse: {
            content: {
              pop_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_head: {
                    content: {
                      pop_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_title: {
                        content: {
                          pop_title_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                          },
                          pop_title_text: {
                            content: "Upgrade Level"
                          }
                        }
                      },
                      pop_close: {
                        content: this.c_btnClose(async () => {
                          if (this.isUpgrade) {
                            this.isUpgrade = false;
                            this.$parent.$refs.popGoMineRef.setMinerMapDom();
                            this.$parent.loadMinerDetailInfo();
                            this.changeMinerDom();
                          }
                          this.$parent.popUpgradeMierDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_con: {
                    content: {
                      pop_main_bg: {
                        content: {
                          pop_main_bg_label: {
                            content: this.sf_marketGoods({
                              width: this.screenWidth * 4 - 140,
                              height: 500 * 4
                            })
                          }
                        }
                      },
                      pop_miner_info: {
                        content: {
                          pop_upgrade_star_box_miner: {
                            content: {
                              pop_upgrade_star_box_miner_main: {
                                content: {
                                  pop_upgrade_star_box_miner_main_label: {
                                    content: this.m_setSpineAnmationsSprite(`${this.setMiner(1, "img")}-idle`, {
                                      loop: true,
                                      isPlay: true,
                                      animationSpeed: 0.25
                                    })
                                  }
                                }
                              },
                              pop_upgrade_star_box_miner_name: {
                                content: `${this.setMiner(1, "name")}`
                              }
                            }
                          },
                          pop_upgrade_star_info: {
                            content: {
                              pop_upgrade_star_info_bg: {
                                content: Sprite.from("up_star_info_bg")
                              },
                              pop_upgrade_star_info_star: {
                                content: Array.from({
                                  length: this.gameInfo.maxMinerStar
                                }, () => ({
                                  content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
                                  styles: globalStyles.pop_upgrade_star_info_star_label
                                }))
                              }
                            }
                          }
                        }
                      },
                      pop_info: {
                        content: {
                          pop_info_title: {
                            content: "Level"
                          },
                          pop_info_main: {
                            content: {
                              pop_info_bg: {
                                content: {
                                  pop_info_bg_label: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: _v.screenWidth * 2.5,
                                      height: 130
                                    })
                                  }
                                }
                              },
                              pop_info_box: {
                                content: {
                                  pop_info_item_1: {
                                    content: {
                                      pop_info_item_title_current: {
                                        content: `Lv.1`,
                                        styles: globalStyles.pop_info_item_title
                                      }
                                    },
                                    styles: globalStyles.pop_info_item_1
                                  },
                                  pop_info_item_arrow: {
                                    content: Sprite.from(this.m_getRes("element").textures["level-arrow.png"])
                                  },
                                  pop_info_item_2: {
                                    content: {
                                      pop_info_item_title_next: {
                                        content: "Lv.1",
                                        styles: {
                                          ...globalStyles.pop_info_item_title,
                                          ...globalStyles.pop_info_item_title_2
                                        }
                                      }
                                    },
                                    styles: globalStyles.pop_info_item_2
                                  }
                                }
                              }
                            },
                            styles: globalStyles.pop_info_main
                          }
                        },
                        styles: globalStyles.pop_info
                      },
                      pop_info_2: {
                        content: {
                          pop_info_title: {
                            content: "Power"
                          },
                          pop_info_main: {
                            content: {
                              pop_info_bg: {
                                content: {
                                  pop_info_bg_label: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: _v.screenWidth * 2.5,
                                      height: 130
                                    })
                                  }
                                }
                              },
                              pop_info_box: {
                                content: {
                                  pop_info_item_1: {
                                    content: {
                                      pop_info_item_title_power_current: {
                                        content: "0/h",
                                        styles: globalStyles.pop_info_item_title
                                      }
                                    },
                                    styles: globalStyles.pop_info_item_1
                                  },
                                  pop_info_item_arrow: {
                                    content: Sprite.from(this.m_getRes("element").textures["level-arrow.png"])
                                  },
                                  pop_info_item_2: {
                                    content: {
                                      pop_info_item_title_power_next: {
                                        content: "0/h",
                                        styles: {
                                          ...globalStyles.pop_info_item_title,
                                          ...globalStyles.pop_info_item_title_2
                                        }
                                      }
                                    },
                                    styles: globalStyles.pop_info_item_2
                                  }
                                }
                              }
                            },
                            styles: globalStyles.pop_info_main
                          }
                        },
                        styles: {
                          ...globalStyles.pop_info,
                          ...globalStyles.pop_info_2
                        }
                      },
                      pop_btns_upgrade: this.m_btn({
                        content: {
                          pop_btns_upgrade_con: {
                            content: {
                              pop_btns_upgrade_bg: {
                                content: this.sf_btnMainBg({
                                  width: 200 * 3,
                                  height: 220
                                }),
                                styles: globalStyles.pop_btns_upgrade_bg
                              },
                              pop_btns_upgrade_main: this.selectMinerInfo.level < this.gameInfo.maxLevel && {
                                content: {
                                  pop_btns_upgrade_label: {
                                    content: this.$lang("UPGRADE"),
                                    styles: globalStyles.pop_btns_upgrade_label
                                  },
                                  pop_btns_upgrade_value: {
                                    content: {
                                      pop_btns_upgrade_value_label: {
                                        content: `${this.toPre(this.selectMinerInfo.next_fee, 0)}`,
                                        styles: globalStyles.pop_btns_upgrade_value_label
                                      },
                                      pop_btns_upgrade_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-coin.png"]),
                                        styles: globalStyles.pop_btns_upgrade_icon
                                      }
                                    },
                                    styles: globalStyles.pop_btns_upgrade_value
                                  }
                                },
                                styles: globalStyles.pop_btns_upgrade_main
                              }
                            },
                            styles: globalStyles.pop_btns_upgrade_con
                          }
                        }
                      }, () => {
                        this.upgradeMinerFunc();
                      }),
                      pop_load: {
                        content: this.c_btnLoading(),
                        styles: globalStyles.pop_load
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setBtnLoading(bool) {
      this.c_setBtnLoading(this.$parent.popUpgradeMierDom, "pop_btns_upgrade_con", "pop_load", bool);
    },
    changeUpgradeMinerDom() {
      this.$nextTick(() => {
        const popDom = this.$parent.popUpgradeMierDom.content;
        const pop_info_item_title_current = popDom.getByID("pop_info_item_title_current");
        pop_info_item_title_current.children[0].text = `Lv.${this.selectMinerInfo.level}`;
        const pop_info_item_title_next = popDom.getByID("pop_info_item_title_next");
        pop_info_item_title_next.children[0].text = this.selectMinerInfo.level < this.gameInfo.maxLevel ? `Lv.${this.selectMinerInfo.level + 1}` : "Max";
        const pop_info_item_title_power_current = popDom.getByID("pop_info_item_title_power_current");
        pop_info_item_title_power_current.children[0].text = `${this.toPre(this.selectMinerInfo.power * this.powerTime, 0)}/h`;
        const pop_info_item_title_power_next = popDom.getByID("pop_info_item_title_power_next");
        pop_info_item_title_power_next.children[0].text = `${this.toPre(this.selectMinerInfo.next_power * this.powerTime, 0)}/h`;
        const pop_btns_upgrade_value_label = popDom?.getByID("pop_btns_upgrade_value_label");
        pop_btns_upgrade_value_label.children[0].text = `${this.toPre(this.selectMinerInfo.next_fee, 0)}`;
        const pop_btns_upgrade_value = popDom?.getByID("pop_btns_upgrade_value").layout;
        pop_btns_upgrade_value.updateParents();
        const pop_upgrade_star_info_star = popDom.getByID("pop_upgrade_star_info_star");
        const pop_upgrade_star_info = popDom.getByID("pop_upgrade_star_info");
        pop_upgrade_star_info_star.children.map((item, index) => {
          item.visible = index <= this.selectMinerInfo.star - 1;
        });
        pop_upgrade_star_info_star.width = this.selectMinerInfo.star * 100;
        this.m_setAlign(pop_upgrade_star_info, pop_upgrade_star_info_star, "x");
      });
    },
    async upgradeMinerFunc() {
      if (this.selectMinerInfo.level >= this.gameInfo.maxLevel) {
        return this.$toasted.error(this.$lang("The Miner is Max Level"));
      }
      this.isUpgrade = false;
      this.setBtnLoading(true);
      this.$toasted.clear();
      const data = await this.upgradeMiner(this.selectMinerInfo.id);
      if (!data) {
        this.setBtnLoading(false);
        this.isUpgrade = false;
        return;
      }
      await this.setSelectMinerInfo(data);
      this.changeUpgradeMinerDom();
      await this.getAccountInfo();
      this.$parent.$refs.assetsInfo1.renderDom({
        type: "refresh",
        coin: true
      });
      this.isUpgrade = true;
      this.setBtnLoading(false);
    }
  }
};
const globalStyles = {
  pop_upgradeWarehouse: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    position: "leftTop"
  },
  pop_mask: {
    position: "leftTop",
    width: "100%",
    height: "100%"
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: 640,
    overflow: "hidden"
  },
  pop_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50,
    marginTop: 60
  },
  pop_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_close: {
    position: "rightTop"
  },
  pop_con: {
    position: "centerTop",
    marginTop: 110,
    width: "100%",
    height: 400
  },
  pop_main_bg: {
    position: "centerTop",
    width: "100%",
    height: "100%"
  },
  pop_main_bg_label: {
    scale: 0.25,
    position: "center"
  },
  pop_miner_info: {
    position: "centerTop",
    marginTop: 20,
    height: 100
  },
  pop_upgrade_star_box_miner: {
    position: "centerTop",
    height: "100%"
  },
  pop_upgrade_star_box_miner_main: {
    position: "centerTop",
    background: "#2e3846",
    width: 90,
    height: 90,
    borderRadius: 12
  },
  pop_upgrade_star_box_miner_main_label: {
    scale: 0.1,
    position: "center"
  },
  pop_upgrade_star_box_miner_name: {
    position: "centerBottom",
    marginBottom: -15,
    fontSize: 15,
    color: "#fff",
    fontFamily: "pFont"
  },
  pop_upgrade_star_info: {
    position: "centerTop",
    scale: 0.22,
    marginTop: 120
  },
  pop_upgrade_star_info_bg: {},
  pop_upgrade_star_info_star: {
    position: "center",
    anchorX: 0.5
  },
  pop_upgrade_star_info_star_label: {
    scale: 1.3,
    width: 100,
    height: 100
  },
  pop_info: {
    position: "centerTop",
    marginTop: 190,
    width: _v.screenWidth - 145,
    height: 80
    // overflow: "hidden",
  },

  pop_info_2: {
    marginTop: 260
  },
  pop_info_title: {
    fontSize: 16,
    color: "#7f83a5",
    fontFamily: "pFontM"
  },
  pop_info_main: {
    position: "centerTop",
    marginTop: 10,
    height: 70
    // overflow: "hidden",
    // position: "center",
  },

  pop_info_item_1: {
    width: 120
  },
  // pop_info_item_2: {
  //   width: 120,
  // },
  pop_info_item_title_2: {
    color: "#fff"
  },
  pop_info_item_arrow: {
    scale: 0.25,
    width: 150,
    height: 90,
    position: "center"
  },
  pop_info_bg: {
    position: "center",
    width: "100%",
    height: 40
  },
  pop_info_bg_label: {
    scale: 0.25,
    position: "center"
  },
  pop_info_box: {
    marginTop: -3,
    position: "center"
  },
  pop_info_item_title: {
    fontSize: 14,
    fontFamily: "pFont",
    color: "#7f83a5",
    position: "left",
    marginLeft: 12,
    marginTop: 2
  },
  pop_info_item_value: {
    fontSize: 18,
    fontFamily: "pFont",
    color: "#fff",
    position: "right",
    marginRight: 15,
    marginTop: 2
  },
  pop_btns_upgrade: {
    position: "centerTop",
    width: "100%",
    height: 65,
    marginTop: 350
  },
  pop_btns_upgrade_con: {
    position: "centerTop",
    width: "100%",
    height: "100%"
  },
  pop_btns_upgrade_bg: {
    scale: 0.3,
    position: "center"
  },
  pop_btns_upgrade_main: {
    position: "center",
    textAlign: "center"
  },
  pop_btns_upgrade_label: {
    position: "center",
    marginTop: -12,
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  pop_btns_upgrade_value: {
    marginTop: 8,
    position: "center",
    height: 25,
    marginLeft: 50
  },
  pop_btns_upgrade_value_label: {
    ..._v.fs_fontStyle({
      fz: 21,
      st: 2,
      ds: 1,
      fill: "#eeea69"
    })
  },
  pop_btns_upgrade_icon: {
    scale: 0.22,
    marginTop: 2
  },
  pop_btns_upgradeStar: {
    position: "right",
    width: "100%",
    height: 65,
    overflow: "hidden"
  },
  pop_btns_upgradeStar_bg: {
    scale: 0.3,
    position: "center"
  },
  pop_btns_upgradeStar_label: {
    position: "center",
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  pop_load: {
    position: "centerTop",
    marginTop: 370,
    anchorX: 0,
    visible: false
  }
};