export default {
  fs_fontStyle: ({ fz, stroke = "#11131e", st = 2, ds = 1, fill= "#fff" }) => {
    return {
      fill,
      fontSize: fz,
      fontFamily: "pFont",
      stroke,
      strokeThickness: st,
      lineJoin: "round",
      miterLimit: 100,
      dropShadow: true,
      dropShadowAngle: 90,
      dropShadowDistance: ds,
    };
  },
};
