import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");

const scrollItemWidth = 180;
const scrollItemHeight = 80;
const isLoading = _v.c_listLoading(80);
export default {
  name: "popSelect",
  inject: ["changeMinerDom", "setPageRecruitShow", "closePage", "changeScreenDom"],
  props: {
    show: Boolean,
    position: {
      typeof: [String, Number],
      default: 0
    }
  },
  computed: {
    ...gameVuex.mapState(["gameApp", "myMineList", "myMineListPage", "myMineListTotal"]),
    ...userVuex.mapState(["token", "accountInfo"]),
    isRender() {
      return this.$parent.popSwitchMineShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          await this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      tabList: [{
        label: "MINE",
        value: 0
      }, {
        label: "MY MINE",
        value: 1
      }],
      selectMiner: null,
      selectPosition: 0,
      popSelectMinerPromptShow: false,
      isSelectMineFlag: true,
      popPromptSwitchTipShow: false,
      popPromptSwitchTipDom: null
    };
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["getMyMineList", "changeMinerDefault"]),
    ...gameVuex.mapMutations(["setMyMineListPage"]),
    async initPop() {
      if (!this.$parent.popSwitchMineDom) {
        this.$parent.popSwitchMineDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popSwitchMineDom);
      }
      this.$parent.popSwitchMineDom.visible = true;
      this.$parent.popSwitchMineDom.zIndex = this.gameApp.stage.children.length + 12;
      this.$parent.popSwitchMineDom.position.set(0, 0);
      this.setListLoading(true);
      this.anim_pop(this.$parent.popSwitchMineDom, () => {
        this.loadMineList();
      }, {
        y: _v.screenHeight - 170
      });
    },
    setListLoading(bool) {
      try {
        const scrollDom = this.$parent.popSwitchMineDom.content.getByID("mine_list_scroll");
        if (bool) {
          isLoading.show();
          scrollDom.visible = false;
        } else {
          isLoading.hide();
          scrollDom.visible = true;
        }
      } catch (err) {}
    },
    initMyMine(id) {
      const scrollDom = this.$parent.popSwitchMineDom.getChildByID("mine_list_scroll").children[0].children[1];
      this.myMineList.map((item, index) => {
        const mine_item_bg = scrollDom.children[index]?.getChildByID("mine_item_bg");
        const mine_item_info_check = scrollDom.children[index]?.getChildByID("mine_item_info_check");
        if (!mine_item_bg) {
          return;
        }
        if (this.myMineList[index].is_default) {
          mine_item_bg.children[0].texture = this.m_getRes("base").textures["mine-default-item-bg.png"];
          mine_item_info_check.visible = true;
        } else {
          mine_item_bg.children[0].texture = this.m_getRes("base").textures["task-item-bg.png"];
          mine_item_info_check.visible = false;
        }
      });
    },
    myMineItemDom(item, index) {
      return {
        id: "mine_item",
        content: {
          mine_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollItemWidth * 4,
              height: scrollItemHeight * 4
            }),
            styles: globalStyles.mine_item_bg
          },
          mine_item_head: {
            content: Sprite.from(this.setMine(item.type_id, "thumbnail")),
            styles: globalStyles.mine_item_head
          },
          mine_item_info: {
            content: {
              mine_item_info_id: {
                content: `Id: ${item.id}`,
                styles: globalStyles.mine_item_info_text
              },
              mine_item_info_cap: {
                content: `Cap: ${item.cap}`,
                styles: globalStyles.mine_item_info_text
              },
              mine_item_info_miner: {
                content: `Miner: ${item.miner}`,
                styles: globalStyles.mine_item_info_text
              },
              mine_item_info_check: {
                content: Sprite.from(_v.m_getRes("element").textures["icon-ok.png"]),
                styles: globalStyles.mine_item_info_check
              }
            },
            styles: globalStyles.mine_item_info
          }
        },
        styles: globalStyles.mine_item
      };
    },
    mineListDom() {
      return this.c_scrollBox(this.myMineList.map((it, idx) => {
        return this.m_btn(this.myMineItemDom(it, idx), () => {
          this.selectMine(it);
        }, {
          width: scrollItemWidth + 8,
          height: 100
        });
      }), {
        width: _v.screenWidth,
        height: 170,
        vertPadding: 40,
        horPadding: 8,
        type: "horizontal"
      });
    },
    async loadMineList() {
      this.setListLoading(true);
      const res = await this.getMyMineList({
        perPage: 20,
        page: this.myMineListPage.page,
        status: 1
      });
      if (!res) {
        this.setListLoading(false);
      }
      const popDom = this.$parent.popSwitchMineDom;
      const scrollDom = popDom.content.getByID("mine_list_scroll").content;
      scrollDom.createContent(this.mineListDom());
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      this.myMineList.map(it => {
        this.initMyMine(it.id);
      });
      this.setListLoading(false);
    },
    renderDom() {
      return {
        content: {
          pop_select: {
            content: {
              pop_select_mask: this.m_btn({
                content: this.c_createMask(0.2)
              }, () => {
                this.$parent.popSwitchMineDom.visible = false;
                this.$emit("update:show", false);
              }),
              pop_main: {
                content: {
                  pop_select_con: {
                    content: {
                      miner_list: {
                        content: {
                          common_loading: isLoading.render(),
                          mine_list_scroll: {
                            content: " "
                          }
                        }
                      }
                    }
                  },
                  pop_select_head: {
                    content: {
                      pop_select_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4,
                          height: 60 * 4
                        })
                      },
                      pop_select_head_text: {
                        content: "Manage Mines"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    async selectMine(it) {
      if (it.id === this.accountInfo.mine.id) {
        return;
      }
      this.popPromptSwitchTipShow = true;
      const data = await this.changeMinerDefault(it.id);
      if (!data) {
        this.popPromptSwitchTipDom.visible = false;
        this.popPromptSwitchTipShow = false;
        return;
      }
      const data1 = await this.getMyMineList({
        perPage: 20,
        page: this.myMineListPage.page,
        status: 1
      });
      if (!data1) {
        this.popPromptSwitchTipDom.visible = false;
        this.popPromptSwitchTipShow = false;
        return;
      }
      await this.getAccountInfo(data);
      this.changeScreenDom();
      this.changeMinerDom();
      this.initMyMine(it.id);
      this.$toasted.clear();
      this.$toasted.success(this.$lang("Switch successful"));
      this.popPromptSwitchTipDom.visible = false;
      this.popPromptSwitchTipShow = false;
    }
  }
};
const globalStyles = {
  pop_select: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_select_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "centerBottom",
    width: "100%",
    height: 170
  },
  pop_select_head: {
    position: "centerTop",
    width: "100%",
    height: 50,
    marginTop: -20
  },
  pop_select_head_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_select_close: {
    position: "right",
    marginTop: 12
  },
  pop_select_head_text: {
    position: "center",
    width: "100%",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 19
    }),
    marginTop: -5
  },
  pop_select_con: {
    position: "centerBottom",
    overflow: "hidden",
    width: "100%",
    background: "#364254"
  },
  miner_list: {
    position: "centerTop",
    height: "100%"
  },
  miner_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  mine_list_scroll: {
    position: "centerTop",
    height: 100,
    visible: false
  },
  mine_item_bg: {
    position: "center",
    scale: 0.25
  },
  mine_item_main: {},
  mine_item_head: {
    scale: 0.25,
    width: 50,
    height: 50,
    position: "left",
    marginLeft: 10
  },
  mine_item_info: {
    position: "centerRight",
    width: 65,
    height: 50,
    marginRight: 20
  },
  mine_item_info_text: {
    fontSize: 14,
    color: "#111",
    fontFamily: "pFont",
    width: 60,
    height: 15,
    textAlign: "left"
  },
  mine_item_info_check: {
    scale: 0.25,
    position: "rightTop",
    width: 35,
    height: 35,
    marginTop: -10,
    marginRight: 5,
    visible: false
  }
};