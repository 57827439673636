import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";
import { toNano } from "@ton/core";
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
import { resolve } from "path";
let colletHttpNum = 0;
export default {
  namespaced: true,
  state: {
    gameApp: null,
    diamondList: null,
    botInfo: null,
    mineList: null,
    myMineList: null,
    myMineListPage: {
      page: 1,
      perPage: 10,
    },
    myMineListTotal: 0,
    selectMinerInfo: null,
    minerList: null,
    minerListPage: {
      page: 1,
      perPage: 10,
    },
    minerListTotal: 0,
    rankList: null,

    marketList: null,
    marketListPage: {
      page: 1,
      perPage: 10,
    },
    marketListTotal: 0,
    myMarketList: null,
    myMarketListPage: {
      page: 1,
      perPage: 10,
    },
    myMarketListTotal: 0,
    guideInfo: {
      step0: [
        //大步骤
        {
          //小步骤
          is: false, //是否执行
          coordinate: 0, //全局坐标
        },
      ],
      step1: [
        //大步骤
        {
          //小步骤
          is: false, //是否执行
          coordinate: 0, //全局坐标
        },
        {
          is: false,
          coordinate: null,
        },
        {
          is: false,
          coordinate: null,
        },
      ],
      step2: [
        {
          is: false,
          coordinate: null,
        },
        {
          is: false,
          coordinate: null,
        },
        {
          is: false,
          coordinate: null,
        },
        {
          is: false,
          coordinate: null,
        },
      ],
      step3: [
        {
          is: false,
          coordinate: null,
        },
      ],
    },
    taskList: null,
    diamondInsufficient: {
      show: false,
      message: "",
    },
  },
  mutations: {
    setDiamondInsufficient: (state, payload) => {
      for (const key in payload) {
        state.diamondInsufficient[key] = payload[key];
      }
    },
    setTaskList: (state, payload) => {
      state.taskList = [];
      state.taskList.push(...payload);
    },
    setMarketList: (state, payload) => {
      state.marketList = [];
      state.marketList.push(...payload);
    },
    setMarketListPage: (state, payload) => {
      for (const key in payload) {
        state.marketListPage[key] = payload[key];
      }
    },
    setMarketListTotal: (state, payload) => {
      state.marketListTotal = payload;
    },
    setMyMarketList: (state, payload) => {
      state.myMarketList = [];
      state.myMarketList.push(...payload);
    },
    setMyMarketListPage: (state, payload) => {
      for (const key in payload) {
        state.myMarketListPage[key] = payload[key];
      }
    },
    setMyMarketListTotal: (state, payload) => {
      state.myMarketListTotal = payload;
    },
    setGuideInfo: (state, payload) => {
      state.guideInfo[payload.step][payload.stage][payload.key] = payload.value;
    },

    setRankList: (state, payload) => {
      state.rankList = [];
      state.rankList.push(...payload);
    },
    setMinerListTotal: (state, payload) => {
      state.minerListTotal = payload;
    },
    setMinerListPage: (state, payload) => {
      for (const key in payload) {
        state.minerListPage[key] = payload[key];
      }
    },
    setMinerList: (state, payload) => {
      state.minerList = [];
      state.minerList.push(...payload);
    },
    setSelectMinerInfo: (state, payload) => {
      state.selectMinerInfo = {};
      for (const key in payload) {
        state.selectMinerInfo[key] = payload[key];
      }
    },
    setGameApp: (state, payload) => {
      state.gameApp = payload;
    },
    setDiamondList: (state, payload) => {
      state.diamondList = [];
      state.diamondList.push(...payload);
    },
    setMineList: (state, payload) => {
      state.mineList = [];
      state.mineList.push(...payload);
    },
    setMyMineList: (state, payload) => {
      state.myMineList = [];
      state.myMineList.push(...payload);
    },
    setMyMineListPage: (state, payload) => {
      for (const key in payload) {
        state.myMineListPage[key] = payload[key];
      }
    },
    setMyMineListTotal: (state, payload) => {
      state.myMineListTotal = payload;
    },
    setBotInfo: (state, payload) => {
      state.botInfo = {};
      for (const key in payload) {
        state.botInfo[key] = payload[key];
      }
    },
  },
  actions: {
    bindWallet: async ({ state, commit }, address) => {
      return new Promise(async (resolve, reject) => {
        const _address = address ? address : _v.tonWallet.address;
        try {
          const res = await _v.$http.post("/account/bind_wallet", { wallet: _address });
          resolve(res);
        } catch (err) {
          resolve(null);
        }
      });
    },
    diamondPayFunc: ({ state, commit, rootState }, [index]) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/topup/pay", { id: index, token: rootState.user.token });
        console.log("resresres", res);
        vailcode(
          res,
          async () => {
            try {
              let order = res.data;
              const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                  {
                    address: res.data.contract, //支付的合约地址
                    amount: toNano(order.ton).toString(),
                    payload: order.row,
                  },
                ],
              };
              console.log("diamondPayFunc 2", transaction);
              const result = await _v.tonWallet.sendTransaction(transaction);
              console.log("diamondPayFunc 3", result);
              resolve(result);
            } catch (err) {
              resolve(null);
            }
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    getMyMineList: async ({ state, commit, rootState }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/mine/list", { token: rootState.tokn, ...state.myMineListPage, ...params });
        vailcode(
          res,
          () => {
            const myMine = res.data.items;
            myMine.map((item) => {
              item["id_dom"] = new Text(`ID: ${item.id}`);
              item["capacity_dom"] = new Text(`Capacity: ${item.cap}`);
              item["accelerate_dom"] = new Text(`Accelerate: ${_v.toPre((item.acce - 1) * 100, 2)}%`);
              item["name_dom"] = new Text(`${_v.setMine(item.type_id, "name")}`);
              item["head_dom"] = Sprite.from(_v.setMine(item.type_id, "thumbnail"));
              if (item.status == 0) {
                _v.$set(item, "isCheck", false);
              } else if (item.status == 1) {
                _v.$set(item, "isCheck", true);
              }
            });
            console.log("myMine", myMine);
            commit("setMyMineListTotal", res.data.total);
            commit("setMyMineList", myMine);
            resolve(res.data.items);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    buyMine: async ({ state, commit }, type_id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/mine/buy", { type_id });
          vailcode(
            res,
            () => {
              _v.$toasted.success(_v.$lang("Buy success!"));
              resolve(res);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {
          resolve(null);
        }
      });
    },
    switchMine: async ({ state, commit }, id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/mine/change", { id });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    recruitMinerFunc: ({ state, commit, dispatch }, asset) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/miner/recruit", { asset });
          console.log("recruitMiner info: ", res);
          vailcode(
            res,
            () => {
              resolve(res.data);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {
          resolve(null);
        }
      });
    },
    getMinerList: async ({ state, commit, dispatch }, params) => {
      try {
        const res = await _v.$http.post("/miner/list", { ...params, ...state.minerListPage, token: state.token });
        vailcode(res, () => {
          commit("setMinerList", res.data.items);
          commit("setMinerListTotal", res.data.total);
          // console.log("res.data.items", state.minerList);
        });
      } catch (err) {}
    },
    subMinerWork: ({ state, commit, dispatch, rootState }, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/miner/work", params);
          vailcode(
            res,
            async () => {
              _v.$toasted.success(_v.$lang("Set Success!"));
              resolve(res.data);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {}
      });
    },
    subMinerLeaveWork: async ({ state, commit, dispatch }, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/miner/free", { id });
          vailcode(
            res,
            async () => {
              await dispatch("user/getAccountInfo",res.data, { root: true });
              _v.$toasted.success(_v.$lang("Leave Success!"));
              resolve(res.data);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {
          console.log("xxxx", err);
        }
      });
    },
    getRankList: async ({ state, commit }, type) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/rank/list", { type });
          let data = res.data;
          vailcode(res, () => {
            commit("setRankList", data);
            resolve();
          });
        } catch (err) {}
      });
    },
    upgradeMiner: async ({ state, commit }, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/miner/upgrade", { id });
          vailcode(
            res,
            () => {
              _v.$toasted.success(_v.$lang("Upgrade success!"));
              resolve(res.data);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {
          resolve(null);
        }
      });
    },
    combineMiner: async ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/miner/combine", { a: params.a, b: params.b });
          vailcode(
            res,
            () => {
              resolve(res);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {}
      });
    },
    getGmeMintInfo: async ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/gme/info", {});
          console.log("reerereree", res);
          vailcode(
            res,
            () => {
              resolve(res.data);
            },
            () => {
              resolve();
            }
          );
        } catch (err) {}
      });
    },
    gmeMintFunc: async ({ state, commit, dispatch }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/account/mint", {});
          vailcode(
            res,
            async () => {
              resolve(res.data);
            },
            () => {
              resolve();
            }
          );
        } catch (err) {}
      });
    },
    // 弃用， 与getAccountInfo合并
    // collectCoinFunc: ({ dispatch }, amount) => {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       const res = await _v.$http.post("/account/collect", { amount });
    //       if (res.code === 0) {
    //         resolve(res.data);
    //       } else if (res.code === 100) {
    //         if (colletHttpNum <= 5) {
    //           let timer = setTimeout(async () => {
    //             clearTimeout(timer);
    //             timer = null;
    //             colletHttpNum++;
    //             await dispatch("collectCoinFunc", amount);
    //           }, 2000);
    //         }
    //       } else {
    //         resolve(null);
    //       }
    //     } catch (err) {
    //       resolve(null);
    //     }
    //   });
    // },
    storeUpgrade: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/account/store_upgrade", {});
          vailcode(
            res,
            async () => {
              _v.$toasted.clear();
              _v.$toasted.success(_v.$lang("Upgrade success!"));
              resolve(res.data);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {}
      });
    },
    marketAdd: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/market/add", params);
          vailcode(
            res,
            async () => {
              _v.$toasted.success(_v.$lang("Sell success!"));
              resolve(res);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {
          resolve(null);
        }
      });
    },
    getMarketList: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/market/list", { ...params, ...state.marketListPage });
          vailcode(res, () => {
            const items = res.data.items;
            console.log("itemsitemsitemsitems", items);
            commit("setMarketList", items);
            console.log("res.data====", state.marketList);
            commit("setMarketListTotal", res.data.total);
            resolve();
          });
        } catch (err) {
          console.log(err);
        }
      });
    },
    getMyMarketList: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/market/my_list", { ...params, ...state.myMarketListPage });
          vailcode(res, () => {
            const items = res.data.items;
            commit("setMyMarketList", items);
            console.log("res.data====", state.myMarketList);
            commit("setMyMarketListTotal", res.data.total);
            resolve();
          });
        } catch (err) {
          console.log(err);
        }
      });
    },
    marketBuy: ({ state, commit }, ids) => {
      return new Promise(async (resolve, reject) => {
        try {
          console.log("ids=======", ids);
          const res = await _v.$http.post("/market/buy", { ids });
          console.log("resresresres", res);
          vailcode(
            res,
            async () => {
              try {
                let order = res.data;
                const transaction = {
                  validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                  messages: [
                    {
                      address: order.contract, //支付的合约地址
                      amount: toNano(order.ton).toString(),
                      payload: order.row,
                    },
                  ],
                };
                const result = await _v.tonWallet.sendTransaction(transaction);
                _v.$toasted.success(_v.$lang("Buy successful!"));
                resolve(result);
              } catch (err) {
                resolve(null);
              }
            },
            (err) => {
              resolve(err);
            }
          );
        } catch (err) {
          resolve(null);
          console.log(err);
        }
      });
    },
    removeMarket: ({ state, commit }, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/market/remove", { id });
          vailcode(
            res,
            () => {
              _v.$toasted.success(_v.$lang("Delist successful!"));
              resolve(1);
            },
            () => {
              resolve(null);
            }
          );
        } catch (err) {
          console.log(err);
        }
      });
    },
    getTaskList: ({ state, commit, rootState }) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post("/task/list", { token: rootState.tokn });
          vailcode(res, () => {
            res.data.splice(3, 0, {
              id: -1,
              typeName: "Extra Task",
            });
            const data = res.data;
            console.log("data===========", data);
            commit("setTaskList", data);
            resolve();
          });
        } catch (err) {
          console.log(err);
        }
      });
    },
    taskSkip: ({ state, commit, rootState }, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await _v.$http.post(`/task/url`, { id, token: rootState.tokn });
          console.log("res", res);
          vailcode(res, () => {
            resolve(res.data);
          });
        } catch (err) {
          console.log(err);
        }
      });
    },
    simulateMiner: ({ state, commit }, [a, b]) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post(`/miner/simulate`, { a, b });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    calcMineStock: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post(`/mine/stock`, {});

        vailcode(
          res,
          () => {
            console.log("calcMineStock====", res.data);
            resolve(res.data);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    addMinePosition: ({ state, commit, dispatch }, id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post(`/mine/add_position`, { id });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    changeMinerDefault: ({ state, commit }, id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post(`/mine/set_default`, { id });
        vailcode(
          res,
          async () => {
            resolve(res.data);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
  },
  getters: {},
};
